import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { ITrainingPinnedVideoActionsTypes } from './types';
import { callApi } from '../../utils/api';
import { message } from 'antd';
import {
  getTrainingPinnedVideoError,
  getTrainingPinnedVideoSuccess,
  editTrainingPinnedVideo,
  editTrainingPinnedVideoError,
  editTrainingPinnedVideoSuccess
} from './actions';
import { AuthActionTypes } from '../auth/types';
import { getToken, isTokenRefreshing } from '../auth/selectors';
import { CONTENT_TYPES } from '../../utils/api';

function handleShowError(action: { [key: string]: any }) {
  const { code } = action.payload;
  const { message: errorMessage } = action.payload.error;
  message.error(`${code} ${errorMessage}`);
}

function* getAuthToken() {
  const isRefreshing = yield select(isTokenRefreshing);
  if (isRefreshing) {
    const { fail } = yield race({
      success: take(AuthActionTypes.TOKEN_REFRESH_SUCCESS),
      fail: take(AuthActionTypes.TOKEN_REFRESH_ERROR),
    });
    if (fail) {
      return null;
    }
  }
  return yield select(getToken);
}

function* handleGetMotivationsRequest() {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(callApi, 'get', 'admin/content/training_pinned_video', token);
  if (response.error) {
    yield put(getTrainingPinnedVideoError(response));
  } else {
    yield put(getTrainingPinnedVideoSuccess(response));
  }
}

function* handleEditMotivationRequest(
  action: ReturnType<typeof editTrainingPinnedVideo>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const values = action.payload;
  const response = yield call(
    callApi,
    'put',
    `admin/content/training_pinned_video`,
    token,
    action.payload,
  );
  
  let imageResponse: {[key: string]: any} = {};
  if (action.payload.videoPreview || action.payload.videoPreview_eng) {
    imageResponse = yield call(
      callApi,
      'post',
      `admin/content/training_pinned_video/video_preview`,
      token,
      { videoPreview: action.payload.videoPreview, videoPreview_eng: action.payload.videoPreview_eng },
      CONTENT_TYPES.MPFD,
    );
  }
  if (response.error || imageResponse.error) {
    yield put(editTrainingPinnedVideoError(response));
  } else {
    message.success('Сохранено');
    yield put(editTrainingPinnedVideoSuccess(response));
  }
}

function* watchGetTrainingPinnedVideoRequest() {
  yield takeLatest(
    ITrainingPinnedVideoActionsTypes.GET_TRAINING_PINNED_VIDEO_REQUEST,
    handleGetMotivationsRequest,
  );
}

function* watchEditTrainingPinnedVideoRequest() {
  yield takeLatest(
    ITrainingPinnedVideoActionsTypes.EDIT_TRAINING_PINNED_VIDEO_REQUEST,
    handleEditMotivationRequest,
  );
}

function* watchRequestError() {
  yield takeLatest(
    [
      ITrainingPinnedVideoActionsTypes.GET_TRAINING_PINNED_VIDEO_ERROR,
      ITrainingPinnedVideoActionsTypes.EDIT_TRAINING_PINNED_VIDEO_ERROR,
    ],
    handleShowError,
  );
}

function* TrainingPinnedVideoSaga() {
  yield all([
    fork(watchGetTrainingPinnedVideoRequest),
    fork(watchEditTrainingPinnedVideoRequest),
    fork(watchRequestError),
  ]);
}

export default TrainingPinnedVideoSaga;
