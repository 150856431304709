import * as React from 'react';
import { get } from 'lodash';
import { Form, Input, Upload, Icon, Modal, Checkbox, Tabs } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  required,
  NOT_BLANK,
  IS_PICTURE,
  maxLength,
} from '../../../constants/validationRules';
import Thumbnail from '../../../components/Thumbnail';
import RequiredLabel from '../../../components/RequiredLabel';
import { Exercise } from '../../../store/exercisies/types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface ExerciseFormState {
  visible: boolean;
  modalContent: JSX.Element | JSX.Element[] | string;
}

interface ExerciseFormProps extends FormComponentProps {
  ExerciseFormProcessing: boolean;
  ExerciseData?: Exercise;
}

class FormComponent extends React.Component<
  ExerciseFormProps,
  ExerciseFormState
> {
  constructor(props: ExerciseFormProps) {
    super(props);
    this.state = {
      visible: false,
      modalContent: '',
    };
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.handleChangeImageEng = this.handleChangeImageEng.bind(this);
    this.onRemoveImageEng = this.onRemoveImageEng.bind(this);
    this.createOnPreview = this.createOnPreview.bind(this);
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;

    const name = getFieldDecorator(`name`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(16)],
      initialValue: get(this.props.ExerciseData, 'name', ''),
    })(<Input placeholder="Название" />);

    const name_eng = getFieldDecorator(`name_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(16)],
      initialValue: get(this.props.ExerciseData, 'name_eng', ''),
    })(<Input placeholder="Title" />);

    const imageSrc = getFieldDecorator(`imageSrc`, {
      initialValue: get(this.props.ExerciseData, 'image'),
    })(<></>);

    const imageRules = this.props.form.getFieldValue('imageSrc')
      ? []
      : [IS_PICTURE];

    const image = getFieldDecorator(`image`, {
      rules: imageRules,
      initialValue: null,
    })(<></>);

    const imageSrcEng = getFieldDecorator(`imageSrcEng`, {
      initialValue: get(this.props.ExerciseData, 'image_eng'),
    })(<></>);

    const imageRulesEng = this.props.form.getFieldValue('imageSrcEng')
      ? []
      : [IS_PICTURE];

    const image_eng = getFieldDecorator(`image_eng`, {
      rules: imageRulesEng,
      initialValue: null,
    })(<></>);

    const id = getFieldDecorator(`id`, {
      initialValue: get(this.props.ExerciseData, 'id', null),
    })(<></>);

    const isRest = getFieldDecorator(`isRest`, {
      initialValue: this.props.ExerciseData && this.props.ExerciseData.type === 'rest',
      valuePropName: 'checked'
    })(
      <Checkbox
        onChange={this.handleChangeType}
        disabled={this.props.ExerciseData && !!this.props.ExerciseData.exercises.length}
        name="isRest"
      >
        Отдых
      </Checkbox>
    );
    
    const isGroup = getFieldDecorator(`isGroup`, {
      initialValue: this.props.ExerciseData && this.props.ExerciseData.type === 'group',
      valuePropName: 'checked'
    })(
      <Checkbox
        onChange={this.handleChangeType}
        disabled={this.props.ExerciseData && !!this.props.ExerciseData.exercises.length}
        name="isGroup"
      >
        Группа мышщ
      </Checkbox>
    );

    return {
      id,
      name,
      name_eng,
      image,
      image_eng,
      imageSrc,
      imageSrcEng,
      isRest,
      isGroup
    };
  }

  handleChangeType(event: CheckboxChangeEvent) {
    const { checked, name } = event.target;
    const otherName = name === 'isRest' ? 'isGroup' : 'isRest';
    this.props.form.setFieldsValue({ [name]: checked, [otherName]: false });
  }

  handleChangeImage(file: { [key: string]: any }) {
    this.props.form.setFieldsValue({ image: file });
    this.props.form.validateFields(['image']);
    return false;
  }
  handleChangeImageEng(file: { [key: string]: any }) {
    this.props.form.setFieldsValue({ image_eng: file });
    this.props.form.validateFields(['image_eng']);
    return false;
  }

  onRemoveImage() {
    this.props.form.setFieldsValue({ image: null });
    this.props.form.validateFields(['image']);
    return true;
  }
  onRemoveImageEng() {
    this.props.form.setFieldsValue({ image_eng: null });
    this.props.form.validateFields(['image_eng']);
    return true;
  }

  createOnPreview(fieldName: string) {
    return (file: { [key: string]: any }) => {
      const { form } = this.props;
      const errorMessages = form.getFieldError(fieldName);
      const formatErrorMessage:string = 'Файл должен быть картинкой';
      if(errorMessages && errorMessages.length && errorMessages[0] === formatErrorMessage) {
        return false;
      }
      const modalContent = <img src={file.thumbUrl} />;
      this.setState({ modalContent, visible: true });
    }
  }

  render() {
    const createUploadButton = (fieldName: string) => {
      return this.props.form.getFieldValue(fieldName) ? null : (
        <>
          <Icon type={'plus'} />
          <div className="ant-upload-text">Загрузить</div>
        </>
      );
    }
    const uploadButton = createUploadButton('image');
    const uploadButtonEng = createUploadButton('image_eng');

    const imageHref = this.fields.imageSrc.props.value;
    const imageHrefEng = this.fields.imageSrcEng.props.value;

    const onRemoveThumbnail = () =>
      this.props.form.setFieldsValue({ imageSrc: null });
    const onRemoveThumbnailEng = () =>
      this.props.form.setFieldsValue({ imageSrcEng: null });

    const onPreviewThumbnail = () => {
      const modalContent = <img src={imageHref} />;
      this.setState({ modalContent, visible: true });
    };
    const onPreviewThumbnailEng = () => {
      const modalContent = <img src={imageHrefEng} />;
      this.setState({ modalContent, visible: true });
    };

    const onPreview = this.createOnPreview('image');
    const onPreviewEng = this.createOnPreview('image_eng');

    const thumbnail = imageHref ? (
      <Thumbnail
        src={imageHref}
        onRemove={onRemoveThumbnail}
        onPreview={onPreviewThumbnail}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={this.handleChangeImage}
          onRemove={this.onRemoveImage}
          onPreview={onPreview}
        >
          {uploadButton}
        </Upload>
        {this.fields.image}
      </>
    );
    const thumbnailEng = imageHrefEng ? (
      <Thumbnail
        src={imageHrefEng}
        onRemove={onRemoveThumbnailEng}
        onPreview={onPreviewThumbnailEng}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={this.handleChangeImageEng}
          onRemove={this.onRemoveImageEng}
          onPreview={onPreviewEng}
        >
          {uploadButtonEng}
        </Upload>
        {this.fields.image_eng}
      </>
    );

    return (
      <Form>
        <Tabs defaultActiveKey="1" centered>
          <Tabs.TabPane tab="Общие настройки" key="1">
            <Form.Item label="Название">{this.fields.name}</Form.Item>
            <Form.Item label={<RequiredLabel text="Изображение" />}>
              {thumbnail}
            </Form.Item>
            <Form.Item>{this.fields.isRest}</Form.Item>
            <Form.Item>{this.fields.isGroup}</Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Английский вариант" key="2">
            <Form.Item label="Title">{this.fields.name_eng}</Form.Item>
            <Form.Item label={<RequiredLabel text="Image" />}>
              {thumbnailEng}
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>

        <Modal
          footer={null}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
        >
          {this.state.modalContent}
        </Modal>
      </Form>
    );
  }
}

export default Form.create()(FormComponent);
