import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { IMotivationActionsTypes } from './types';
import { callApi } from '../../utils/api';
import { message } from 'antd';
import {
  getMotivations,
  getMotivationsError,
  getMotivationsSuccess,
  editMotivation,
  editMotivationError,
  editMotivationSuccess,
  deleteMotivation,
  deleteMotivationError,
  deleteMotivationSuccess,
  editMotivationCategory,
  editMotivationCategoryError,
  editMotivationCategorySuccess,
} from './actions';
import { AuthActionTypes } from '../auth/types';
import { getToken, isTokenRefreshing } from '../auth/selectors';
import { CONTENT_TYPES } from '../../utils/api';

function handleShowError(action: { [key: string]: any }) {
  const { code } = action.payload;
  const { message: errorMessage } = action.payload.error;
  message.error(`${code} ${errorMessage}`);
}

function* getAuthToken() {
  const isRefreshing = yield select(isTokenRefreshing);
  if (isRefreshing) {
    const { fail } = yield race({
      success: take(AuthActionTypes.TOKEN_REFRESH_SUCCESS),
      fail: take(AuthActionTypes.TOKEN_REFRESH_ERROR),
    });
    if (fail) {
      return null;
    }
  }
  return yield select(getToken);
}

function* handleGetMotivationsRequest() {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(callApi, 'get', 'admin/motivations', token);
  if (response.error) {
    yield put(getMotivationsError(response));
  } else {
    yield put(getMotivationsSuccess(response));
  }
}

function* handleUploadMotivationImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { motivationId, id } = data;
  const response = yield call(
    callApi,
    'post',
    `admin/motivationVideos/${motivationId || id}/videoPreview`,
    token,
    { videoPreview: data.videoPreview },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* createMotivation(token: string, values: { [key: string]: any }) {
  const { motivationCategoryId } = values;
  const response = yield call(
    callApi,
    'post',
    `admin/motivations/${motivationCategoryId}/videos`,
    token,
    { ...values },
  );

  if (response.error) {
    yield put(editMotivationError(response));
  } else {
    const motivationId = response.data.id;
    yield handleUploadMotivationImage({
      token,
      data: { ...values, motivationId },
    });
    yield put(editMotivationSuccess(response));
    yield put(getMotivations());
    values.callback();
  }
}

function* updateMotivation(token: string, values: { [key: string]: any }) {
  const { id } = values;
  const response = yield call(
    callApi,
    'put',
    `admin/motivationVideos/${id}`,
    token,
    { ...values },
  );

  if (response.error) {
    yield put(editMotivationError(response));
  } else {
    if (values.videoPreview) {
      yield handleUploadMotivationImage({
        token,
        data: { ...values },
      });
    }
    yield put(editMotivationSuccess(response));
    yield put(getMotivations());
    values.callback();
  }
}

function* handleEditMotivationRequest(
  action: ReturnType<typeof editMotivation>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield updateMotivation(token, action.payload);
  } else {
    yield createMotivation(token, action.payload);
  }
}

function* handleDeleteMotivationRequest(
  action: ReturnType<typeof deleteMotivation>,
) {
  const { motivationId } = action.payload;
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'delete',
    `admin/motivationVideos/${motivationId}`,
    token,
  );

  if (response.error) {
    yield put(deleteMotivationError(response, motivationId));
  } else {
    yield put(deleteMotivationSuccess({ motivationId }));
    yield put(getMotivations());
  }
}

function* handleUploadMotivationCategoryImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { id } = data;
  const response = yield call(
    callApi,
    'post',
    `admin/motivations/${id}/videoPreview`,
    token,
    { videoPreview: data.videoPreview },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* updateMotivationCategory(
  token: string,
  values: { [key: string]: any },
) {
  const { id } = values;
  const response = yield call(
    callApi,
    'put',
    `admin/motivations/${id}`,
    token,
    { ...values },
  );

  if (response.error) {
    yield put(editMotivationCategoryError(response));
  } else {
    if (values.videoPreview) {
      yield handleUploadMotivationCategoryImage({
        token,
        data: { ...values },
      });
    }
    yield put(editMotivationCategorySuccess(response));
    yield put(getMotivations());
    values.callback();
  }
}

function* handleEditMotivationCategoryRequest(
  action: ReturnType<typeof editMotivationCategory>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield updateMotivationCategory(token, action.payload);
  }
}

function* watchEditMotivationCategoryRequest() {
  yield takeLatest(
    IMotivationActionsTypes.EDIT_MOTIVATION_CATEGORY_REQUEST,
    handleEditMotivationCategoryRequest,
  );
}

function* watchDeleteMotivationRequest() {
  yield takeLatest(
    IMotivationActionsTypes.DELETE_MOTIVATION_REQUEST,
    handleDeleteMotivationRequest,
  );
}

function* watchGetMotivationsRequest() {
  yield takeLatest(
    IMotivationActionsTypes.GET_MOTIVATIONS_REQUEST,
    handleGetMotivationsRequest,
  );
}

function* watchEditWorkoutsRequest() {
  yield takeLatest(
    IMotivationActionsTypes.EDIT_MOTIVATION_REQUEST,
    handleEditMotivationRequest,
  );
}

function* watchRequestError() {
  yield takeLatest(
    [
      IMotivationActionsTypes.GET_MOTIVATIONS_ERROR,
      IMotivationActionsTypes.EDIT_MOTIVATION_ERROR,
      IMotivationActionsTypes.DELETE_MOTIVATION_ERROR,
      IMotivationActionsTypes.EDIT_MOTIVATION_CATEGORY_ERROR,
    ],
    handleShowError,
  );
}

function* MotivationSaga() {
  yield all([
    fork(watchGetMotivationsRequest),
    fork(watchEditWorkoutsRequest),
    fork(watchEditMotivationCategoryRequest),
    fork(watchDeleteMotivationRequest),
    fork(watchRequestError),
  ]);
}

export default MotivationSaga;
