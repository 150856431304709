import React, { Component } from 'react';
import { Table, Button, Modal, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormComponentProps } from 'antd/lib/form';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ContentWrapper } from '../../components/layout/Content';
import {
  ExercisiesPageWrapper,
  Title,
  ButtonGroup,
} from '../../constants/styles';
import {
  getMotivations,
  editMotivation,
  deleteMotivation,
} from '../../store/motivation/actions';
import EditForm from './components/EditForm';
import { TableColumns } from './columns';
import { ApplicationState } from '../../store';
import { goToPath } from '../../services/navigator.service';
import routeMap from '../../constants/routeMap';

interface IMotivationProps extends FormComponentProps {
  getMotivations: () => void;
  editMotivation: (values: { [key: string]: any }) => void;
  deleteMotivation: ({ motivationId }: { motivationId: number }) => void;
  motivations: { [key: string]: any };
  match: { [key: string]: any };
}

interface IMotivationState {
  modalVisible: boolean;
  modalData: { [key: string]: any };
}

class Motivation extends Component<IMotivationProps, IMotivationState> {
  constructor(props: IMotivationProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {},
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getMotivations();
  }

  get motivation() {
    const { list } = this.props.motivations;
    const { motivationCategoryId } = this.props.match.params;
    const motivation: { [key: string]: any } = list.length
      ? list.filter(
          (item: { [key: string]: any }) => item.id === +motivationCategoryId,
        )[0]
      : {};
    return motivation;
  }

  closeModal() {
    this.setState({ modalVisible: false });
  }

  openModal(data?: { [key: string]: any }) {
    this.setState({ modalVisible: true, modalData: data ? data : {} });
  }

  onSubmit() {
    const { form } = this.props;
    const { list } = this.props.motivations;

    const fieldNames = [
      'name',
      'sort',
      'video',
      'videoPreview',
    ]

    form!.validateFields(fieldNames, { firstFields: fieldNames }, (err: any, values: any) => {
      if (!err) {
        const data = { ...values, id: this.state.modalData.id }

        this.props.editMotivation({
          ...data,
          motivationCategoryId: list[0].id,
          callback: this.closeModal,
        });
      }
    });
  }

  render() {
    const { loaders } = this.props.motivations;
    const tableData = this.motivation.motivations || [];

    const MotivationTableColumns: ColumnProps<any>[] = [
      ...TableColumns,
      {
        title: (
          <Button
            className="action-button"
            icon="plus-circle"
            htmlType="button"
            title="Добавить видео"
            onClick={this.openModal}
          />
        ),
        dataIndex: '',
        key: 'x',
        render: (_: string, data: { [key: string]: any }) => {
          const openModalEdit = () => this.openModal(data);

          const onDeleteMotivation = () =>
            this.props.deleteMotivation({
              motivationId: data.id,
            });

          return (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="edit"
                htmlType="button"
                title="Редактировать видео"
                onClick={openModalEdit}
              />
              <Popconfirm
                title="Вы уверены, что хотите удалить видео?"
                onConfirm={onDeleteMotivation}
                placement="rightBottom"
              >
                <Button
                  className="action-button"
                  icon="delete"
                  htmlType="button"
                  title="Удалить видео"
                  loading={loaders[`delete/${data.id}`]}
                />
              </Popconfirm>
            </ButtonGroup>
          );
        },
      },
    ];

    const modalTitle = this.state.modalData.id
      ? 'Редактирование видео'
      : 'Добавление видео';

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Title>
            {' '}
            <span
              onClick={goToPath.bind(null, routeMap.motivationCategory.path)}
            >
              Категории мотивации
            </span>{' '}
            - {this.motivation.name}
          </Title>
          <Table
            rowKey="id"
            columns={MotivationTableColumns}
            loading={loaders.getMotivations}
            dataSource={tableData}
          />
          <Modal
            title={modalTitle}
            destroyOnClose={true}
            visible={this.state.modalVisible}
            maskClosable={false}
            confirmLoading={loaders.editMotivation}
            onCancel={this.closeModal}
            onOk={this.onSubmit}
          >
            <EditForm form={this.props.form} data={this.state.modalData} />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  motivations: {
    list: state.motivations.list,
    loaders: state.motivations.loaders,
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getMotivations: () => dispatch(getMotivations()),
  editMotivation: (values: { [key: string]: any }) =>
    dispatch(editMotivation(values)),
  deleteMotivation: ({ motivationId }: { motivationId: number }) =>
    dispatch(deleteMotivation({ motivationId })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Motivation);
