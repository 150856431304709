import React, { Component } from 'react';
import {
  Input,
  InputNumber,
  Form,
  Row,
  Col,
  Select,
  Button,
  message,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { get, forEach } from 'lodash';
import {
  required,
  NOT_BLANK,
  rangeValue,
  maxLength,
  IS_PICTURE_NO_REQUIRED
} from '../../../../constants/validationRules';
import ImageUpload from '../../../../components/ImageUpload'
import shortid from 'shortid';
import styled from '../../../../utils/styled';

const Option = Select.Option;

interface IModalDayFormState {
  trainingCategories: any[];
  trainings: any[];
  idAddCategory: number | null;
  addedCategoryIds: any[];
  repeatsCount: { [key: number]: number };
  setsCount: { [key: number]: number };
  restTime: { [key: number]: number };
  sort: { [key: number]: number };
  selectedCategory: number | null;
  type: string;
}

interface IModalDayFormProps extends FormComponentProps {
  data: { [key: string]: any };
  exercises: any[];
  workoutDayIndex: number | null;
  workoutWeekId: number | null;
  maskClosable: boolean
}

class ModalDayForm extends Component<IModalDayFormProps, IModalDayFormState> {
  constructor(props: IModalDayFormProps) {
    super(props);
    this.state = {
      trainingCategories: [],
      trainings: [],
      repeatsCount: {},
      setsCount: {},
      restTime: {},
      sort: {},
      idAddCategory: null,
      addedCategoryIds: [],
      selectedCategory: null,
      type: null,
    };
  }

  componentDidMount() {
    const trainingCategories: any[] = [];
    const trainings: any[] = [];
    const repeatsCount: { [key: number]: number } = {};
    const setsCount: { [key: number]: number } = {};
    const restTime: { [key: number]: number } = {};
    const sort: { [key: number]: number } = {};
    const addedCategoryIds: any[] = [];
    this.props.data.trainingCategories.forEach(
      (item: { [key: string]: any }) => {
        addedCategoryIds.push(item.exerciseCategoryId);
        trainingCategories.push({ id: item.exerciseCategoryId });
        sort[item.exerciseCategoryId] = item.sort;
        item.trainings.forEach((training: { [key: string]: any }) => {
          const trainingId = `old-${training.id}`;
          trainings.push({
            id: trainingId,
            categoryId: item.exerciseCategoryId,
            defaultValue: training.exercise ? training.exercise.id : null,
          });
          repeatsCount[trainingId] = training.repeatsCount;
          setsCount[trainingId] = training.setsCount;
          restTime[trainingId] = training.restTime;
        });
      },
    );
    this.setState({
      addedCategoryIds,
      trainingCategories,
      trainings,
      repeatsCount,
      setsCount,
      restTime,
      sort,
    });
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  get exercisesCategory() {
    const filteredExercises = () => {
      if (this.state.trainingCategories.length) {
        const isSelectedCategoriesContainRest = this.state.trainingCategories.filter(item => item.type !== 'rest').length
        if (isSelectedCategoriesContainRest) {
          return this.props.exercises.filter(item => item.type !== 'rest')
        }
        return this.props.exercises
      }
      return this.props.exercises
    }
    const exercises = filteredExercises().map(
      (exercise: { [key: string]: any }) => (
        <Option key={exercise.id} value={exercise.id}>
          {exercise.name}
        </Option>
      ),
    );
    return exercises;
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;
    const { repeatsCount, setsCount, restTime, sort } = this.state;

    const convertDecimalToInt = (value: any, name: string) => {
      const convertedValue = Number(value);
      if (isNaN(convertedValue)) {
        return Number(this.props.form.getFieldValue(name));
      } else {
        return convertedValue;
      }
    }

    const name = getFieldDecorator(`name`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(10)],
      initialValue: get(data, 'name', ''),
    })(<Input placeholder="Название" style={{ width: '97%' }} />);

    const name_eng = getFieldDecorator(`name_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(10)],
      initialValue: get(data, 'name_eng', ''),
    })(<Input placeholder="Title" style={{ width: '97%' }} />);

    const warmUp = getFieldDecorator(`warmUp`, {
      validateTrigger: 'onSubmit',
      rules: [],
      initialValue: get(data, 'warmUp', ''),
    })(<Input placeholder="Ссылка на видео разминка" style={{ width: '97%' }} />);

    const warmUp_eng = getFieldDecorator(`warmUp_eng`, {
      validateTrigger: 'onSubmit',
      rules: [],
      initialValue: get(data, 'warmUp_eng', ''),
    })(<Input placeholder="Link to the warm-up video" style={{ width: '97%' }} />);

    const videoIntro = getFieldDecorator(`videoIntro`, {
      validateTrigger: 'onSubmit',
      rules: [],
      initialValue: get(data, 'videoIntro', ''),
    })(<Input placeholder="Ссылка на видео вступление" style={{ width: '97%' }} />);

    const videoIntro_eng = getFieldDecorator(`videoIntro_eng`, {
      validateTrigger: 'onSubmit',
      rules: [],
      initialValue: get(data, 'videoIntro_eng', ''),
    })(<Input placeholder="Link to the video introduction" style={{ width: '97%' }} />);

    const imageSrc = getFieldDecorator(`videoIntroImageSrc`, {
      initialValue: get(this.props.data, 'videoIntroImage'),
    })(<></>);

    const image_engSrc = getFieldDecorator(`videoIntroImage_engSrc`, {
      initialValue: get(this.props.data, 'videoIntroImage_eng'),
    })(<></>);

    const imageRules = this.props.form.getFieldValue('videoIntroImageSrc')
      ? []
      : [IS_PICTURE_NO_REQUIRED];

    const imageRules_eng = this.props.form.getFieldValue('videoIntroImage_engSrc')
      ? []
      : [IS_PICTURE_NO_REQUIRED];

    const image = getFieldDecorator(`videoIntroImage`, {
      rules: imageRules,
      initialValue: null,
    })(<></>);

    const image_eng = getFieldDecorator(`videoIntroImage_eng`, {
      rules: imageRules_eng,
      initialValue: null,
    })(<></>);

    const trainingCategories = this.state.trainingCategories.map(
      trainingCategory => {
        const category = this.props.exercises.find(
          (exercise: { [key: string]: any }) =>
            exercise.id === trainingCategory.id,
            );
            
        const isExercise = !this.selectedCategoryHasRestType && !this.selectedCategoryHasGroupType;

        const trainings = this.state.trainings.map(training => {
          return trainingCategory.id === training.categoryId
            ? {
              id: getFieldDecorator(
                `trainingId.${trainingCategory.id}.${training.id}`,
                {
                  rules: [required, NOT_BLANK],
                  initialValue: training.defaultValue,
                },
              )(
                <Select placeholder={this.selectedCategoryHasRestType ? "Отдых" : "Упражнение"}>
                  {this.getExercises(trainingCategory.id)}
                </Select>,
              ),

              repeatsCount: getFieldDecorator(
                `repeatsCount.${trainingCategory.id}.${training.id}`,
                {
                  initialValue: repeatsCount[training.id],
                  getValueFromEvent: value => convertDecimalToInt(value, `repeatsCount.${trainingCategory.id}.${training.id}`),
                  rules: isExercise ? [required, NOT_BLANK, rangeValue(1, 99)] : null,
                },
              )(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Количество повторений"
                  type="number"
                />,
              ),

              setsCount: getFieldDecorator(
                `setsCount.${trainingCategory.id}.${training.id}`,
                {
                  initialValue: setsCount[training.id],
                  getValueFromEvent: value => convertDecimalToInt(value, `setsCount.${trainingCategory.id}.${training.id}`),
                  rules: isExercise ? [required, NOT_BLANK, rangeValue(1, 99)] : null,
                },
              )(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Количество подходов"
                  type="number"
                />,
              ),

              restTime: getFieldDecorator(
                `restTime.${trainingCategory.id}.${training.id}`,
                {
                  getValueFromEvent: value => convertDecimalToInt(value, `restTime.${trainingCategory.id}.${training.id}`),
                  initialValue: restTime[training.id],
                  rules: isExercise ? [required, NOT_BLANK, rangeValue(0, 999)] : null,
                },
              )(
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Время отдыха"
                  type="number"
                />,
              ),
            }
            : null;
        });

        return {
          trainingCategories: getFieldDecorator(
            `trainingCategories.${trainingCategory.id}`,
            {
              initialValue: category.id,
            },
          )(
            <Select disabled style={{ width: '95%' }}>
              <Option key={category.id} value={category.id}>
                {category.name}
              </Option>
            </Select>,
          ),
          sort: getFieldDecorator(`sort.${trainingCategory.id}`, {
            getValueFromEvent: value => convertDecimalToInt(value, `sort.${trainingCategory.id}`),
            validateTrigger: 'onSubmit',
            rules: !this.selectedCategoryHasRestType && !this.selectedCategoryHasGroupType ? [required, NOT_BLANK, rangeValue(1, 99)] : null,
            initialValue: sort[trainingCategory.id],
          })(<InputNumber style={{ width: '95%' }} placeholder="Приоритет" type="number" />),
          trainings,
          trainingCategoryId: trainingCategory.id,
          categoryType: category.type
        };
      },
    );

    return {
      name,
      name_eng,
      warmUp,
      warmUp_eng,
      videoIntro,
      videoIntro_eng,
      trainingCategories,
      imageSrc,
      image_engSrc,
      image,
      image_eng,
    };
  }

  getExercises(id: number) {
    const exerciseCategory = this.props.exercises.find(
      (item: { [key: string]: any }) => item.id === id,
    );
    const trainingFields = this.props.form.getFieldValue('trainingId');
    const selectedTraining: any[] = [];
    forEach(trainingFields[id], (value, key) => {
      if (value) {
        selectedTraining.push(value);
      }
    });
    const exercises = exerciseCategory.exercises.map(
      (exercise: { [key: string]: any }) =>
        // @ts-ignore
        selectedTraining.includes(exercise.id) ? (
          <Option key={exercise.id} value={exercise.id} disabled>
            {exercise.name} - {exercise.level} уровень
          </Option>
        ) : (
          <Option key={exercise.id} value={exercise.id}>
            {exercise.name} - {exercise.level} уровень
          </Option>
        ),
    );
    return exercises;
  }

  onChangeExerciseAddCategory = (id: number) => {
    this.setState({
      idAddCategory: id,
      selectedCategory: id
    });
  };

  addCategory = () => {
    const { isPlanPublished } = this.props.data
    const { idAddCategory } = this.state;

    if (isPlanPublished) {
      this.showErrorWhenPlanIsPublished()
      return
    }

    if (idAddCategory) {
      // @ts-ignore
      if (this.state.addedCategoryIds.includes(idAddCategory)) {
        message.error('Такая категория упражнения создана');
      } else {
        this.setState({
          trainingCategories: [
            ...this.state.trainingCategories,
            { id: idAddCategory },
          ],
          addedCategoryIds: [...this.state.addedCategoryIds, idAddCategory],
          selectedCategory: null
        });
      }
    } else {
      message.error('Выберите категорию упражнения');
    }

    this.setState({ idAddCategory: null })
  };

  addExercise = (categoryId: number) => {
    const { isPlanPublished } = this.props.data

    if (isPlanPublished) {
      this.showErrorWhenPlanIsPublished()
      return
    }

    if (this.selectedCategoryHasRestType && this.state.trainings.length >= 1) {
      message.warning('В дне может быть только 1 отдых')
      return
    }

    this.setState({
      trainings: [
        ...this.state.trainings,
        { categoryId, id: shortid.generate() },
      ],
    });
  };

  showErrorWhenPlanIsPublished = () => message.warning('Редактирование запрещено, т.к. курс опубликован')

  deleteExercise = (exerciseId: string | number) => {
    const { isPlanPublished } = this.props.data
    const trainings = this.state.trainings.filter(
      (item: { [key: string]: any }) => item.id !== exerciseId,
    );
    if (isPlanPublished) {
      this.showErrorWhenPlanIsPublished()
      return
    }

    this.setState({ trainings });
  };

  deleteTrainingCategory = (categoryId: number) => {
    const { isPlanPublished } = this.props.data
    if (isPlanPublished) {
      this.showErrorWhenPlanIsPublished()
      return
    }

    const { trainingCategories, addedCategoryIds, trainings } = this.state;
    const filterCategories = trainingCategories.filter(
      (item: { [key: string]: any }) => item.id !== categoryId,
    );
    const filterAddedCategoryIds = addedCategoryIds.filter(
      item => item !== categoryId,
    );
    const filterTrainings = trainings.filter(
      item => item.categoryId !== categoryId,
    );

    this.setState({
      trainingCategories: filterCategories,
      addedCategoryIds: filterAddedCategoryIds,
      trainings: filterTrainings,
    });
  };

  get selectedCategoryHasRestType() {
    if (this.state.trainingCategories.length) {
      const currentCategory = this.props.exercises.find(item => item.id === this.state.trainingCategories[0].id)
      return currentCategory.type === 'rest'
    }

    return false
  }

  get selectedCategoryHasGroupType() {
    if (this.state.trainingCategories.length) {
      const currentCategory = this.props.exercises.find(item => item.id === this.state.trainingCategories[0].id)
      return currentCategory.type === 'group'
    }

    return false
  }

  get typeText() {
    if (this.selectedCategoryHasRestType) return 'Отдых';
    if (this.selectedCategoryHasGroupType) return 'Группу мышц';
    return 'Упражнение';
  }

  render() {
    const isNotExercise = this.selectedCategoryHasRestType || this.selectedCategoryHasGroupType;

    const fields = this.fields.trainingCategories.map((item, i) => {
      const isExercise = item.categoryType === 'exercise';

      const trainings = item.trainings.map((training, j) => {
        // @ts-ignore
        const trainingId = training ? training.id.props.id.split('.')[2] : null;
        const onDeleteExercise = () => this.deleteExercise(trainingId);
        return training ? (
          <TrainingItem key={`training-${i}-${j}`}>
            <Form.Item label={this.typeText}>{training.id}</Form.Item>
            {!isNotExercise && <Form.Item label="Количество подходов">
              {training.setsCount}
            </Form.Item>}
            {!isNotExercise && <Form.Item label="Количество повторений">
              {training.repeatsCount}
            </Form.Item>}
            {!isNotExercise && <Form.Item label="Время отдыха">{training.restTime}</Form.Item>}
            <Button onClick={onDeleteExercise}>{`Удалить ${this.typeText.toLowerCase()}`}</Button>
          </TrainingItem>
        ) : null;
      });

      return (
        <FormGroup key={`form-group-${i}`}>
          <Row>
            <Col span={8}>
              <Form.Item label="Категория упражнения">
                {item.trainingCategories}
              </Form.Item>
              {isExercise && <Form.Item label="Приоритет">{item.sort}</Form.Item>}
            </Col>
          </Row>
          <TrainingList>{trainings}</TrainingList>
          <Row className="dpw-dayform-row">
            {
              (
                (this.selectedCategoryHasRestType && !this.state.trainings.length)
                || !this.selectedCategoryHasRestType
                || this.selectedCategoryHasGroupType
              )
              && (
                <Button
                  type="primary"
                  onClick={() => this.addExercise(item.trainingCategoryId)}
                  style={{ lineHeight: 1, whiteSpace: 'normal' }}
                >
                  {`Добавить ${this.typeText.toLowerCase()}`}
                </Button>
              )
            }
            <Button
              onClick={() => this.deleteTrainingCategory(item.trainingCategoryId)}
              style={{ lineHeight: 1, whiteSpace: 'normal' }}
            >
              Удалить категорию
            </Button>
          </Row>
        </FormGroup>
      );
    });

    // @ts-ignore
    const imageHref = this.fields.imageSrc.props.value;
    // @ts-ignore
    const imageHref_eng = this.fields.image_engSrc.props.value;

    return (
      <Form>
        <Row>
          <Col span={8}>
            <Form.Item label="Название">{this.fields.name}</Form.Item>
            <Form.Item label="Ссылка на видео (разминка)">{this.fields.warmUp}</Form.Item>
            <Form.Item label="Ссылка на видео (вступление)">{this.fields.videoIntro}</Form.Item>
            <Form.Item label="Превью для видео (вступление)">
              <ImageUpload 
                setFields={this.props.form.setFieldsValue}
                validateFields={this.props.form.validateFields}
                field={this.fields.image}
                imageSrc={imageHref}
                errors={this.props.form.getFieldError('videoIntroImage')}
                fieldName="videoIntroImage"
              />
              {this.fields.image}
            </Form.Item>
          </Col>
          <Col span={1}>
          </Col>
          <Col span={8}>
            <Form.Item label="Title">{this.fields.name_eng}</Form.Item>
            <Form.Item label="Link to the video (warm-up)">{this.fields.warmUp_eng}</Form.Item>
            <Form.Item label="Link to the video (intro)">{this.fields.videoIntro_eng}</Form.Item>
            <Form.Item label="Preview for the video (intro)">
              <ImageUpload
                setFields={this.props.form.setFieldsValue}
                validateFields={this.props.form.validateFields}
                field={this.fields.image_eng}
                imageSrc={imageHref_eng}
                errors={this.props.form.getFieldError('videoIntroImage_eng')}
                fieldName="videoIntroImage_eng"
              />
              {this.fields.image_eng}
            </Form.Item>
          </Col>

        </Row>
        {fields}
        {((!this.selectedCategoryHasRestType && !this.state.trainingCategories.length) || !this.selectedCategoryHasRestType) && <Row gutter={24}>
          <Col span={8}>
            <Select
              placeholder="Категория упражнения"
              onChange={this.onChangeExerciseAddCategory}
              value={this.state.selectedCategory ? this.state.selectedCategory : undefined}
            >
              {this.exercisesCategory}
            </Select>
          </Col>
          <Col span={8}>
            <Button type="primary" onClick={this.addCategory}>
              Добавить категорию упражнений
            </Button>
          </Col>
        </Row>}
      </Form>
    );
  }
}

export const FormGroup = styled.div`
  border: 1px solid #adadad;
  padding: 5px 15px 15px 15px;
  border-radius: 5px;
  margin-bottom: 25px;

  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-btn {
    margin-top: 10px;
  }
`;

export const TrainingList = styled.div`
  display: flex;
  align-item: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const TrainingItem = styled.div`
  flex: 0 0 calc(33.333% - 15px);
  margin-right: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: whitesmoke;
  padding: 10px 15px 15px 15px;
`;

export default ModalDayForm;
