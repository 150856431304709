import React, { Component } from 'react';
import { Form, Input, Upload, Icon, Modal, InputNumber } from 'antd';
import { get } from 'lodash';
import {
  required,
  NOT_BLANK,
  IS_PICTURE,
  IS_VIMEO_URL,
  rangeValue,
  maxLength,
} from '../../../../constants/validationRules';
import { FormComponentProps } from 'antd/lib/form';
import Thumbnail from '../../../../components/Thumbnail';
import RequiredLabel from '../../../../components/RequiredLabel';

interface IEditFormState {
  visible: boolean;
  modalContent: JSX.Element | JSX.Element[] | string;
}

interface IEditFormProps extends FormComponentProps {
  data?: { [key: string]: any };
}

class EditForm extends Component<IEditFormProps, IEditFormState> {
  constructor(props: IEditFormProps) {
    super(props);
    this.state = {
      visible: false,
      modalContent: '',
    };
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.onPreview = this.onPreview.bind(this);
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;

    const id = getFieldDecorator(`id`, {
      initialValue: get(this.props.data, 'id', ''),
    });

    const name = getFieldDecorator(`name`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(16)],
      initialValue: get(this.props.data, 'name', ''),
    })(<Input placeholder="Название" />);

    const sort = getFieldDecorator(`sort`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, rangeValue(1, 99)],
      initialValue: get(this.props.data, 'sort', ''),
    })(<InputNumber style={{ width: '100%' }} placeholder="Приоритет" />);

    const video = getFieldDecorator(`video`, {
      validateTrigger: 'onSubmit',
      rules: [required, IS_VIMEO_URL],
      initialValue: get(this.props.data, 'video', ''),
    })(<Input placeholder="Ссылка на видео" />);

    const imageSrc = getFieldDecorator(`imageSrc`, {
      validateTrigger: 'onSubmit',
      initialValue: get(this.props.data, 'videoPreview'),
    })(<></>);

    const imageRules = this.props.form.getFieldValue('imageSrc')
      ? []
      : [IS_PICTURE];

    const videoPreview = getFieldDecorator(`videoPreview`, {
      validateTrigger: 'onSubmit',
      rules: imageRules,
      initialValue: null,
    })(<></>);

    return {
      id,
      name,
      sort,
      video,
      videoPreview,
      imageSrc,
    };
  }

  handleChangeImage(file: { [key: string]: any }) {
    this.props.form.setFieldsValue({ videoPreview: file });
    this.props.form.validateFields(['videoPreview']);
    return false;
  }

  onRemoveImage() {
    this.props.form.setFieldsValue({ videoPreview: null });
    this.props.form.validateFields(['videoPreview']);
    return true;
  }

  onPreview(file: { [key: string]: any }) {
    const { form } = this.props;
    const errorMessages = form.getFieldError('videoPreview');
    const formatErrorMessage:string = 'Файл должен быть картинкой';

    if(errorMessages && errorMessages.length && errorMessages[0] === formatErrorMessage) {
      return false;
    }

    const modalContent = <img src={file.thumbUrl} />;
    this.setState({ modalContent, visible: true });
  }

  render() {
    const uploadButton = this.props.form.getFieldValue(
      'videoPreview',
    ) ? null : (
      <>
        <Icon type={'plus'} />
        <div className="ant-upload-text">Загрузить</div>
      </>
    );

    // @ts-ignore
    const imageHref = this.fields.imageSrc.props.value;

    const onRemoveThumbnail = () =>
      this.props.form.setFieldsValue({ imageSrc: null });

    const onPreviewThumbnail = () => {
      const modalContent = <img src={imageHref} />;
      this.setState({ modalContent, visible: true });
    };

    const thumbnail = imageHref ? (
      <Thumbnail
        src={imageHref}
        onRemove={onRemoveThumbnail}
        onPreview={onPreviewThumbnail}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={this.handleChangeImage}
          onRemove={this.onRemoveImage}
          onPreview={this.onPreview}
        >
          {uploadButton}
        </Upload>
        {this.fields.videoPreview}
      </>
    );

    return (
      <Form>
        <Form.Item label="Название">{this.fields.name}</Form.Item>
        <Form.Item label="Приоритет">{this.fields.sort}</Form.Item>
        <Form.Item label={<RequiredLabel text="Превью видео" />}>
          {thumbnail}
        </Form.Item>
        <Form.Item label="Ссылка на видео">{this.fields.video}</Form.Item>
        <Modal
          footer={null}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
        >
          {this.state.modalContent}
        </Modal>
      </Form>
    );
  }
}

export default EditForm;
