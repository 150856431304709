import React from 'react';

interface IRequiredLabelProps {
  text: string;
}

const ActionIcon = ({ text }: IRequiredLabelProps) => {
  return (
    <>
      <span
        style={{
          // stylelint-disable
          fontFamily: 'SimSun',
          color: '#f5222d',
        }}
      >
        *
      </span>{' '}
      {text}
    </>
  );
};

export default ActionIcon;
