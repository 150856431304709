import { ColumnProps } from 'antd/lib/table';
import * as React from 'react';
import { Icon } from 'antd';
import { Exercise } from '../../store/exercisies/types';
import { FilterText } from '../../components/filters/Text';

export const getColumnSearchProps = (dataIndex: string) => ({
  filterDropdown: (props: any) => <FilterText {...props} />,
  width: '100%',
  filterIcon: (filtered: boolean) => (
    <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value: string, record: Exercise) => {
    return record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase());
  },
});

export const ExercisiesTableColumns: ColumnProps<any>[] = [
  {
    dataIndex: 'name',
    title: 'Название',
    sorter: (a: Exercise, b: Exercise) => a.name.localeCompare(b.name),
    ...getColumnSearchProps('name'),
  },
];
