import React, { Component } from 'react';
import { Form, Input } from 'antd';
import { get } from 'lodash';
import {
  required,
  NOT_BLANK,
  maxLength
} from '../../../../constants/validationRules';
import { FormComponentProps } from 'antd/lib/form';

interface IEditFormState {
  visible: boolean;
}

interface IEditFormProps extends FormComponentProps {
  data?: { [key: string]: any };
}

class EditForm extends Component<IEditFormProps, IEditFormState> {
  constructor(props: IEditFormProps) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;

    const id = getFieldDecorator(`id`, {
      initialValue: get(this.props.data, 'id', null),
    });

    const name = getFieldDecorator(`name`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(10)],
      initialValue: get(this.props.data, 'name', ''),
    })(<Input placeholder="Название" />);

    const name_eng = getFieldDecorator(`name_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(10)],
      initialValue: get(this.props.data, 'name_eng', ''),
    })(<Input placeholder="Title" />);

    return {
      id,
      name,
      name_eng,
    };
  }

  render() {
    return (
      <Form>
        <Form.Item label="Название">{this.fields.name}</Form.Item>
        <Form.Item label="Title">{this.fields.name_eng}</Form.Item>
      </Form>
    );
  }
}

export default EditForm;
