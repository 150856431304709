import React from 'react';
import { Table, Button, Modal, Popconfirm, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormComponentProps } from 'antd/lib/form';
import { getFreeCoursesWeeks, deleteFreeCoursesWeek, editFreeCoursesWeek } from '../../store/free-courses/actions';
import { ApplicationState } from '../../store';
import { IFreeCoursesState } from '../../store/free-courses/types';
import { ContentWrapper } from '../../components/layout/Content';
import Breadcrumbs from '../../components/FreeBreadcrumbs';
import {
  ExercisiesPageWrapper,
  ButtonGroup,
} from '../../constants/styles';
import { TableColumns } from './columns';
import EditForm from './components/EditForm';
import { goTo } from '../../services/navigator.service';

interface IFreeCoursesWeekProps extends FormComponentProps {
  getFreeCoursesWeeks: (freePlanId: number) => void;
  deleteFreeCoursesWeek: (freeCoursesWeekId: number, freePlanId: number) => void;
  editFreeCoursesWeek: (values: {[key: string]: any}) => void;
  free_courses: IFreeCoursesState;
  match: { [key: string]: any };
}

interface IFreeCoursesWeekComponentState {
  modalVisible: boolean;
  modalData: { [key: string]: any };
}

class FreeCoursesWeek extends React.Component<IFreeCoursesWeekProps, IFreeCoursesWeekComponentState> {
  constructor(props: IFreeCoursesWeekProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {},
    }
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getFreeCoursesWeeks(this.props.match.params.freePlanId);
  }

  closeModal() {
    this.setState({ modalVisible: false, modalData: {} });
  }

  openModal() {
    this.setState({ modalVisible: true });
  }

  onSubmit() {
    const { form } = this.props;
    form!.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.editFreeCoursesWeek({
          ...values,
          freePlanId: this.props.match.params.freePlanId,
          callback: this.closeModal
        });
      };
      if (err) {
        message.error('Правильно заполните поля');
      };
    });
  }

  render() {
    const { loaders, data } = this.props.free_courses;
    const tableData = data || [];

    const ModifyTableColumns: ColumnProps<any>[] = [
      ...TableColumns,
      {
        title: (
          <Button
            className="action-button"
            icon="plus-circle"
            htmlType="button"
            title="Добавить неделю тренировки"
            onClick={this.openModal}
            style={{}}
          />
        ),
        dataIndex: '',
        key: 'x',
        width: '160px',
        render: (_: string, data: { [key: string]: any }) => {
          const openEditModal = () => {
            this.setState({ modalData: data });
            this.setState({ modalVisible: true });
          };

          return (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="read"
                htmlType="button"
                title="Просмотреть неделю тренировок"
                onClick={goTo.bind(null, 'freeCoursesDays', {
                  freeWeekId: `${data.id}`,
                })}
              />
              <Button
                className="action-button"
                icon="edit"
                htmlType="button"
                title="Редактировать неделю тренировок"
                onClick={ openEditModal }
              />
              <Popconfirm
                title="Вы уверены, что хотите удалить неделю тренировок?"
                onConfirm={() => this.props.deleteFreeCoursesWeek(data.id, this.props.match.params.freePlanId)}
                placement="rightBottom"
              >
                <Button
                  className="action-button"
                  icon="delete"
                  htmlType="button"
                  title="Удалить неделю тренировки"
                  loading={loaders[`delete/${data.id}`]}
                />
              </Popconfirm>
            </ButtonGroup>
          );
        },
      },
    ];

    const modalTitle = this.state.modalData.id
      ? 'Редактирование недели'
      : 'Добавление недели';

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Breadcrumbs type="weeks" id={this.props.match.params.freePlanId} />
          <Table
            rowKey="id"
            columns={ModifyTableColumns}
            loading={loaders.getFreeCoursesWeek}
            dataSource={tableData}
          />
          <Modal
            // title="Редактировать неделю"
            title={modalTitle}
            destroyOnClose={true}
            maskClosable={false}
            visible={this.state.modalVisible}
            confirmLoading={loaders.editFreeCoursesWeek}
            onCancel={this.closeModal}
            onOk={this.onSubmit}
          >
            <EditForm
              form={this.props.form}
              data={this.state.modalData}
            />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  free_courses: state.free_courses
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFreeCoursesWeeks: (freePlanId: number) => dispatch(getFreeCoursesWeeks(freePlanId)),
  deleteFreeCoursesWeek: (freeCoursesWeekId: number, freePlanId: number) => dispatch(deleteFreeCoursesWeek({ freePlanId, freeCoursesWeekId })),
  editFreeCoursesWeek: (values: { [key: string]: any }) =>
    dispatch(editFreeCoursesWeek(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreeCoursesWeek);