import React, { Component } from 'react';
import { Form, Modal, InputNumber, Input } from 'antd';
import { get } from 'lodash';
import {
  required,
  NOT_BLANK,
  rangeValue,
  maxLength,
} from '../../../../constants/validationRules';
import { FormComponentProps } from 'antd/lib/form';

interface IEditFormState {
  visible: boolean;
  modalContent: JSX.Element | JSX.Element[] | string;
}

interface IEditFormProps extends FormComponentProps {
  data?: { [key: string]: any };
}

class EditForm extends Component<IEditFormProps, IEditFormState> {
  constructor(props: IEditFormProps) {
    super(props);
    this.state = {
      visible: false,
      modalContent: '',
    };
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;

    const id = getFieldDecorator(`id`, {
      initialValue: get(this.props.data, 'id', null),
    });

    const sort = getFieldDecorator(`sort`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, rangeValue(1, 99)],
      initialValue: get(this.props.data, 'sort', ''),
    })(<InputNumber placeholder="Приоритет" style={{ width: '100%' }} />);

    const name = getFieldDecorator(`name`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(20)],
      initialValue: get(this.props.data, 'name', ''),
    })(<Input placeholder="Название" />);

    const name_eng = getFieldDecorator(`name_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(20)],
      initialValue: get(this.props.data, 'name_eng', ''),
    })(<Input placeholder="Title" />);

    return {
      id,
      sort,
      name,
      name_eng,
    };
  }

  render() {
    return (
      <Form>
        <Form.Item label="Сортировка">{this.fields.sort}</Form.Item>
        <Form.Item label="Название недели">{this.fields.name}</Form.Item>
        <Form.Item label="Name of the week">{this.fields.name_eng}</Form.Item>
        <Modal
          footer={null}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
        >
          {this.state.modalContent}
        </Modal>
      </Form>
    );
  }
}

export default EditForm;
