import React, { Component } from 'react';
import { Upload, Icon, Modal } from 'antd';
import Thumbnail from '../../components/Thumbnail';

interface IImageUploadState {
  visible: boolean;
  modalContent: JSX.Element | JSX.Element[] | string;
}

interface IImageUploadProps {
  setFields: (fields: any) => void;
  validateFields: (fields: string[]) => void;
  imageSrc: string;
  errors: string[];
  field: any;
  fieldName: string;
}

class ImageUpload extends Component<IImageUploadProps, IImageUploadState> {
  constructor(props: IImageUploadProps) {
    super(props);
    this.state = {
      visible: false,
      modalContent: '',
    };
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.onPreview = this.onPreview.bind(this);
  }

  handleChangeImage(file: { [key: string]: any }) {
    this.props.setFields({ [this.props.fieldName]: file });
    this.props.validateFields([this.props.fieldName]);
    return false;
  }

  onRemoveImage() {
    this.props.setFields({ [this.props.fieldName]: null });
    this.props.validateFields([this.props.fieldName]);
    return true;
  }

  onPreview(file: { [key: string]: any }) {
    const { errors } = this.props;
    const formatErrorMessage: string = 'Файл должен быть картинкой';

    if (errors && errors.length && errors[0] === formatErrorMessage) {
      return false;
    }

    const modalContent = <img src={file.thumbUrl} />;
    this.setState({ modalContent, visible: true });
  }

  render() {
    const uploadButton = this.props.field.props.value ? null : (
      <>
        <Icon type={'plus'} />
        <div className="ant-upload-text">Загрузить</div>
      </>
    );

    const imageHref = this.props.imageSrc;

    const onPreviewThumbnail = () => {
      const modalContent = <img src={imageHref} />;
      this.setState({ modalContent, visible: true });
    };

    const onRemoveThumbnail = () => {
      this.props.setFields({ [`${this.props.fieldName}Src`]: null });
    }

    const thumbnail = imageHref ? (
      <Thumbnail
        src={imageHref}
        onRemove={onRemoveThumbnail}
        onPreview={onPreviewThumbnail}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={this.handleChangeImage}
          onRemove={this.onRemoveImage}
          onPreview={this.onPreview}
        >
          {uploadButton}
        </Upload>
      </>
    );

    return (
      <>
        {thumbnail}
        <Modal
          footer={null}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
        >
          {this.state.modalContent}
        </Modal>
      </>
    );
  }
}

export default ImageUpload;
