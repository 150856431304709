import { IFoodActionsTypes } from './types';
import { action } from 'typesafe-actions';
import { ApiResponse } from '../../utils/api';

// get rations

export const getRations = () => action(IFoodActionsTypes.GET_RATIONS_REQUEST);

export const getRationsSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFoodActionsTypes.GET_RATIONS_SUCCESS, response.data, response.code);

export const getRationsError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFoodActionsTypes.GET_RATIONS_ERROR, {
    error: response.error,
    code: response.code,
  });

// get ration item
export const getRationItem = ({ rationId }: { rationId: number }) =>
  action(IFoodActionsTypes.GET_RATION_ITEM_REQUEST, { rationId });

export const getRationItemSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IFoodActionsTypes.GET_RATION_ITEM_SUCCESS,
    response.data,
    response.code,
  );

export const getRationItemError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFoodActionsTypes.GET_RATION_ITEM_ERROR, {
    error: response.error,
    code: response.code,
  });

// edit rations
export const editRations = (values: { [key: string]: any }) =>
  action(IFoodActionsTypes.EDIT_RATIONS_REQUEST, values);

export const editRationsSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFoodActionsTypes.EDIT_RATIONS_SUCCESS, response.data, response.code);

export const editRationsError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFoodActionsTypes.EDIT_RATIONS_ERROR, {
    error: response.error,
    code: response.code,
  });

// delete rations

export const deleteRation = ({ rationId }: { rationId: number }) =>
  action(IFoodActionsTypes.DELETE_RATION_REQUEST, {
    rationId,
  });

export const deleteRationSuccess = ({ rationId }: { rationId: number }) =>
  action(IFoodActionsTypes.DELETE_RATION_SUCCESS, { rationId });

export const deleteRationError = (
  response: ApiResponse<{ [key: string]: any }>,
  rationId: number,
) =>
  action(IFoodActionsTypes.DELETE_RATION_ERROR, {
    rationId,
    error: response.error,
    code: response.code,
  });

// get portions
export const getPortions = ({ rationId }: { rationId: number }) =>
  action(IFoodActionsTypes.GET_PORTIONS_REQUEST, { rationId });

export const getPortionsSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFoodActionsTypes.GET_PORTIONS_SUCCESS, response.data, response.code);

export const getPortionsError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFoodActionsTypes.GET_PORTIONS_ERROR, {
    error: response.error,
    code: response.code,
  });

// get portion item
export const getPortionItem = ({ portionId }: { portionId: number }) =>
  action(IFoodActionsTypes.GET_PORTION_ITEM_REQUEST, { portionId });

export const getPortionItemSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IFoodActionsTypes.GET_PORTION_ITEM_SUCCESS,
    response.data,
    response.code,
  );

export const getPortionItemError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFoodActionsTypes.GET_PORTION_ITEM_ERROR, {
    error: response.error,
    code: response.code,
  });

// edit portions
export const editPortions = (values: { [key: string]: any }) =>
  action(IFoodActionsTypes.EDIT_PORTIONS_REQUEST, values);

export const editPortionsSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFoodActionsTypes.EDIT_PORTIONS_SUCCESS, response.data, response.code);

export const editPortionsError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFoodActionsTypes.EDIT_PORTIONS_ERROR, {
    error: response.error,
    code: response.code,
  });

// delete portion

export const deletePortion = ({
  portionId,
  rationId,
}: {
  portionId: number;
  rationId: number;
}) =>
  action(IFoodActionsTypes.DELETE_PORTION_REQUEST, {
    portionId,
    rationId,
  });

export const deletePortionSuccess = ({ portionId }: { portionId: number }) =>
  action(IFoodActionsTypes.DELETE_PORTION_SUCCESS, { portionId });

export const deletePortionError = (
  response: ApiResponse<{ [key: string]: any }>,
  portionId: number,
) =>
  action(IFoodActionsTypes.DELETE_PORTION_ERROR, {
    portionId,
    error: response.error,
    code: response.code,
  });

// get meals
export const getMeals = ({ portionId }: { portionId: number }) =>
  action(IFoodActionsTypes.GET_MEALS_REQUEST, { portionId });

export const getMealsSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) => action(IFoodActionsTypes.GET_MEALS_SUCCESS, response.data, response.code);

export const getMealsError = (response: ApiResponse<{ [key: string]: any }>) =>
  action(IFoodActionsTypes.GET_MEALS_ERROR, {
    error: response.error,
    code: response.code,
  });

// edit meals
export const editMeals = (values: { [key: string]: any }) =>
  action(IFoodActionsTypes.EDIT_MEALS_REQUEST, values);

export const editMealsSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) => action(IFoodActionsTypes.EDIT_MEALS_SUCCESS, response.data, response.code);

export const editMealsError = (response: ApiResponse<{ [key: string]: any }>) =>
  action(IFoodActionsTypes.EDIT_MEALS_ERROR, {
    error: response.error,
    code: response.code,
  });

// delete portion

export const deleteMeals = ({
  portionId,
  mealId,
}: {
  portionId: number;
  mealId: number;
}) =>
  action(IFoodActionsTypes.DELETE_MEALS_REQUEST, {
    portionId,
    mealId,
  });

export const deleteMealsSuccess = ({ mealId }: { mealId: number }) =>
  action(IFoodActionsTypes.DELETE_MEALS_SUCCESS, { mealId });

export const deleteMealsError = (
  response: ApiResponse<{ [key: string]: any }>,
  mealId: number,
) =>
  action(IFoodActionsTypes.DELETE_MEALS_ERROR, {
    mealId,
    error: response.error,
    code: response.code,
  });
