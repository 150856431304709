import { IMotivationActionsTypes } from './types';
import { action } from 'typesafe-actions';
import { ApiResponse } from '../../utils/api';

// get motivations

export const getMotivations = () =>
  action(IMotivationActionsTypes.GET_MOTIVATIONS_REQUEST);

export const getMotivationsSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IMotivationActionsTypes.GET_MOTIVATIONS_SUCCESS,
    response.data,
    response.code,
  );

export const getMotivationsError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IMotivationActionsTypes.GET_MOTIVATIONS_ERROR, {
    error: response.error,
    code: response.code,
  });

// edit motivations video
export const editMotivation = (values: { [key: string]: any }) =>
  action(IMotivationActionsTypes.EDIT_MOTIVATION_REQUEST, values);

export const editMotivationSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IMotivationActionsTypes.EDIT_MOTIVATION_SUCCESS,
    response.data,
    response.code,
  );

export const editMotivationError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IMotivationActionsTypes.EDIT_MOTIVATION_REQUEST, {
    error: response.error,
    code: response.code,
  });

// delete motivation

export const deleteMotivation = ({ motivationId }: { motivationId: number }) =>
  action(IMotivationActionsTypes.DELETE_MOTIVATION_REQUEST, {
    motivationId,
  });

export const deleteMotivationSuccess = ({
  motivationId,
}: {
  motivationId: number;
}) =>
  action(IMotivationActionsTypes.DELETE_MOTIVATION_SUCCESS, { motivationId });

export const deleteMotivationError = (
  response: ApiResponse<{ [key: string]: any }>,
  motivationId: number,
) =>
  action(IMotivationActionsTypes.DELETE_MOTIVATION_ERROR, {
    motivationId,
    error: response.error,
    code: response.code,
  });

// edit motivations category
export const editMotivationCategory = (values: { [key: string]: any }) =>
  action(IMotivationActionsTypes.EDIT_MOTIVATION_CATEGORY_REQUEST, values);

export const editMotivationCategorySuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IMotivationActionsTypes.EDIT_MOTIVATION_CATEGORY_SUCCESS,
    response.data,
    response.code,
  );

export const editMotivationCategoryError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IMotivationActionsTypes.EDIT_MOTIVATION_CATEGORY_ERROR, {
    error: response.error,
    code: response.code,
  });
