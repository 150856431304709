import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { ExercisiesActionsTypes } from './types';
import { callApi } from '../../utils/api';
import { message, Modal } from 'antd';
import {
  closeExerciseForm,
  createExerciseError,
  createExerciseRequest,
  createExerciseSuccess,
  getExercisies,
  getExercisiesError,
  getExercisiesSuccess,
  updateExerciseError,
  updateExerciseSuccess,
  deleteExerciseError,
  deleteExerciseSuccess,
  deleteExerciseRequest,
  createExerciseLevelsError,
  createExerciseLevelsSuccess,
  deleteExerciseLevelsRequest,
  deleteExerciseLevelsError,
  deleteExerciseLevelsSuccess,
} from './actions';
import { AuthActionTypes } from '../auth/types';
import { getToken, isTokenRefreshing } from '../auth/selectors';
import { CONTENT_TYPES } from '../../utils/api';

function* handleDeleteExercise(
  action: ReturnType<typeof deleteExerciseRequest>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const { id, force } = action.payload;
  const response = yield call(
    callApi,
    'delete',
    !force
    ? `admin/dictionaries/exercises/${action.payload.id}`
    : `admin/dictionaries/exercises/${action.payload.id}?force=1`,
    token,
  );
  if (response.error) {
    yield put(deleteExerciseError(response, id));
  } else {
    yield put(deleteExerciseSuccess({ id: action.payload.id }));
    yield put(getExercisies());
  }
}

function* handleExercisiesRequest() {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'get',
    'admin/dictionaries/exercises',
    token,
  );
  if (response.error) {
    yield put(getExercisiesError(response));
  } else {
    yield put(getExercisiesSuccess(response));
  }
}

function* handleCreateExercise(
  action: ReturnType<typeof createExerciseRequest>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'post',
    'admin/dictionaries/exercises',
    token,
    { ...action.payload },
  );
  if (response.error) {
    yield put(createExerciseError(response));
  } else {
    // @ts-ignore
    const { id } = response.data;
    const data = { ...action.payload, id };
    yield handleUploadExerciseImage({ token, data });
    yield put(closeExerciseForm());
    yield put(createExerciseSuccess());
    yield put(getExercisies());
  }
}

function* handleUploadExerciseImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const response = yield call(
    callApi,
    'post',
    `admin/dictionaries/exercises/${data.id}/image`,
    token,
    { image: data.image, image_eng: data.image_eng },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* handleUpdateExercise(
  action: ReturnType<typeof createExerciseRequest>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'put',
    `admin/dictionaries/exercises/${action.payload.id}`,
    token,
    { ...action.payload },
  );

  if (response.error) {
    yield put(updateExerciseError(response));
  } else {
    if (action.payload.image || action.payload.image_eng) {
      const data = { ...action.payload };
      yield handleUploadExerciseImage({ token, data });
    }
    yield put(closeExerciseForm());
    yield put(updateExerciseSuccess());
    yield put(getExercisies());
  }
}

function* getAuthToken() {
  const isRefreshing = yield select(isTokenRefreshing);
  if (isRefreshing) {
    const { fail } = yield race({
      success: take(AuthActionTypes.TOKEN_REFRESH_SUCCESS),
      fail: take(AuthActionTypes.TOKEN_REFRESH_ERROR),
    });
    if (fail) {
      return null;
    }
  }
  return yield select(getToken);
}

function *handleExerciseError(action: ReturnType<typeof updateExerciseError>) {
  const error = action.payload.error
  if (error.error === 4006) {
    try {
      const payload = yield confirmForceDeleteExersizePromise(action.payload)
      const { id } = payload
      yield put(deleteExerciseRequest({ id, force: true }))
    } catch(error) {
      return false
    }
  } else {
    message.error(action.payload.error!.message);
  }
}

function* handleUploadExerciseLevelsImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const response = yield call(
    callApi,
    'post',
    `admin/dictionaries/exercises/${data.exerciseId}/levels/${
      data.exerciseLevelId
    }/videoPreview`,
    token,
    { videoPreview: data.videoPreview, videoPreview_eng: data.videoPreview_eng },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* createExerciseLevel(token: string, values: { [key: string]: any }) {
  const { exerciseId, callback } = values;
  const response = yield call(
    callApi,
    'post',
    `admin/dictionaries/exercises/${exerciseId}/levels`,
    token,
    values,
  );
  if (response.error) {
    yield put(createExerciseLevelsError(response));
  } else {
    callback()
    const exerciseLevelId = response.data.id;
    if (exerciseLevelId) {
      yield handleUploadExerciseLevelsImage({
        token,
        data: { ...values, exerciseLevelId },
      });
    }
  }
}

function* editExerciseLevel(token: string, values: { [key: string]: any }) {
  const { exerciseId, id, callback } = values;
  const response = yield call(
    callApi,
    'put',
    `admin/dictionaries/exercises/${exerciseId}/levels/${id}`,
    token,
    values,
  );
  if (response.error) {
    yield put(createExerciseLevelsError(response));
  } else {
    callback()
    if (values.videoPreview || values.videoPreview_eng) {
      yield handleUploadExerciseLevelsImage({
        token,
        data: { ...values, exerciseLevelId: id },
      });
    }
  }
}

function* handleCreateExerciseLevels(action: { [key: string]: any }) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield editExerciseLevel(token, action.payload);
  } else {
    yield createExerciseLevel(token, action.payload);
  }
  yield put(createExerciseLevelsSuccess());
  yield put(getExercisies());
}

function* handleDeleteLevelsExercise(
  action: ReturnType<typeof deleteExerciseLevelsRequest>,
) {
  const token = yield getAuthToken();
  const { exerciseId, exerciseLevelId, force } = action.payload;
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'delete',
    !force
    ? `admin/dictionaries/exercises/${exerciseId}/levels/${exerciseLevelId}`
    : `admin/dictionaries/exercises/${exerciseId}/levels/${exerciseLevelId}?force=1`,
    token,
  );
  if (response.error) {
    yield put(deleteExerciseLevelsError(response, exerciseId, exerciseLevelId));
  } else {
    yield put(deleteExerciseLevelsSuccess({ exerciseLevelId }));
    yield put(getExercisies());
  }
}

function* watchExerciseDeleteRequest() {
  yield takeLatest(
    ExercisiesActionsTypes.DELETE_EXERCISE_REQUEST,
    handleDeleteExercise,
  );
}

function* watchExerciseLevelsDeleteRequest() {
  yield takeLatest(
    ExercisiesActionsTypes.DELETE_EXERCISE_LEVELS_REQUEST,
    handleDeleteLevelsExercise,
  );
}

function* watchExercisiesRequest() {
  yield takeLatest(
    ExercisiesActionsTypes.GET_EXERCISIES_REQUEST,
    handleExercisiesRequest,
  );
}

function* watchExerciseCreateRequest() {
  yield takeLatest(
    ExercisiesActionsTypes.CREATE_EXERCISE_REQUEST,
    handleCreateExercise,
  );
}

function* watchExerciseLevelsCreateRequest() {
  yield takeLatest(
    ExercisiesActionsTypes.CREATE_EXERCISE_LEVELS_REQUEST,
    handleCreateExerciseLevels,
  );
}

function confirmForceDeleteExersizePromise(payload: { [key: string]: any }) {
  const confirm = Modal.confirm;
  const error = payload.error
  return new Promise((resolve, reject) => {
    confirm({
      title: `${error.message}, хотите удалить?`,
      onOk() {
        resolve({...payload, force: true})
      },
      onCancel() {
        reject({ ...payload })
      }
    })
  })
}

function* handleExerciseLevelsUpdateError(action: { [key: string]: any }) {
  const error = action.payload.error

  if (error.error === 4109) {
    try {
      const payload = yield confirmForceDeleteExersizePromise(action.payload)
      const { exerciseId, exerciseLevelId, force } = payload
      yield put(deleteExerciseLevelsRequest({ exerciseId, exerciseLevelId, force }))
    } catch(error) {
      return false
    }
  } else {
    message.error(error!.message)
  }
}

function* watchUpdateExerciseLevelsError() {
  yield takeLatest(
    [
      ExercisiesActionsTypes.CREATE_EXERCISE_LEVELS_ERROR,
      ExercisiesActionsTypes.DELETE_EXERCISE_LEVELS_ERROR,
    ],
    handleExerciseLevelsUpdateError,
  );
}

function* watchUpdateExerciseRequest() {
  yield takeLatest(
    ExercisiesActionsTypes.UPDATE_EXERCISE_REQUEST,
    handleUpdateExercise,
  );
}

function* watchUpdateExerciseError() {
  yield takeLatest(
    [
      ExercisiesActionsTypes.CREATE_EXERCISE_ERROR,
      ExercisiesActionsTypes.UPDATE_EXERCISE_ERROR,
      ExercisiesActionsTypes.DELETE_EXERCISE_ERROR,
    ],
    handleExerciseError,
  );
}

function* ExercisiesSaga() {
  yield all([
    fork(watchExercisiesRequest),
    fork(watchExerciseCreateRequest),
    fork(watchExerciseLevelsCreateRequest),
    fork(watchExerciseLevelsDeleteRequest),
    fork(watchExerciseDeleteRequest),
    fork(watchUpdateExerciseRequest),
    fork(watchUpdateExerciseError),
    fork(watchUpdateExerciseLevelsError),
  ]);
}

export default ExercisiesSaga;
