export enum IFoodActionsTypes {
  GET_RATIONS_REQUEST = '@@Workouts/GET_RATIONS_REQUEST',
  GET_RATIONS_SUCCESS = '@@Workouts/GET_RATIONS_SUCCESS',
  GET_RATIONS_ERROR = '@@Workouts/GET_RATIONS_ERROR',
  EDIT_RATIONS_REQUEST = '@@Workouts/EDIT_RATIONS_REQUEST',
  EDIT_RATIONS_SUCCESS = '@@Workouts/EDIT_RATIONS_SUCCESS',
  EDIT_RATIONS_ERROR = '@@Workouts/EDIT_RATIONS_ERROR',
  DELETE_RATION_REQUEST = '@@Workouts/DELETE_RATION_REQUEST',
  DELETE_RATION_SUCCESS = '@@Workouts/DELETE_RATION_SUCCESS',
  DELETE_RATION_ERROR = '@@Workouts/DELETE_RATION_ERROR',
  GET_RATION_ITEM_REQUEST = '@@Workouts/GET_RATION_ITEM_REQUEST',
  GET_RATION_ITEM_SUCCESS = '@@Workouts/GET_RATION_ITEM_SUCCESS',
  GET_RATION_ITEM_ERROR = '@@Workouts/GET_RATION_ITEM_ERROR',
  GET_PORTIONS_REQUEST = '@@Workouts/GET_PORTIONS_REQUEST',
  GET_PORTIONS_SUCCESS = '@@Workouts/GET_PORTIONS_SUCCESS',
  GET_PORTIONS_ERROR = '@@Workouts/GET_PORTIONS_ERROR',
  EDIT_PORTIONS_REQUEST = '@@Workouts/EDIT_PORTIONS_REQUEST',
  EDIT_PORTIONS_SUCCESS = '@@Workouts/EDIT_PORTIONS_SUCCESS',
  EDIT_PORTIONS_ERROR = '@@Workouts/EDIT_PORTIONS_ERROR',
  DELETE_PORTION_REQUEST = '@@Workouts/DELETE_PORTION_REQUEST',
  DELETE_PORTION_SUCCESS = '@@Workouts/DELETE_PORTION_SUCCESS',
  DELETE_PORTION_ERROR = '@@Workouts/DELETE_PORTION_ERROR',
  GET_PORTION_ITEM_REQUEST = '@@Workouts/GET_PORTION_ITEM_REQUEST',
  GET_PORTION_ITEM_SUCCESS = '@@Workouts/GET_PORTION_ITEM_SUCCESS',
  GET_PORTION_ITEM_ERROR = '@@Workouts/GET_PORTION_ITEM_ERROR',
  GET_MEALS_REQUEST = '@@Workouts/GET_MEALS_REQUEST',
  GET_MEALS_SUCCESS = '@@Workouts/GET_MEALS_SUCCESS',
  GET_MEALS_ERROR = '@@Workouts/GET_MEALS_ERROR',
  EDIT_MEALS_REQUEST = '@@Workouts/EDIT_MEALS_REQUEST',
  EDIT_MEALS_SUCCESS = '@@Workouts/EDIT_MEALS_SUCCESS',
  EDIT_MEALS_ERROR = '@@Workouts/EDIT_MEALS_ERROR',
  DELETE_MEALS_REQUEST = '@@Workouts/DELETE_MEALS_REQUEST',
  DELETE_MEALS_SUCCESS = '@@Workouts/DELETE_MEALS_SUCCESS',
  DELETE_MEALS_ERROR = '@@Workouts/DELETE_MEALS_ERROR',
}

export interface IFoodState {
  list: any[];
  loaders: { [key: string]: boolean };
  rationItem: { [key: string]: any };
  portionItem: { [key: string]: any };
}
