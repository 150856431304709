import React, { Component } from 'react';
import { Table, Button, Modal } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormComponentProps } from 'antd/lib/form';
import { ContentWrapper } from '../../components/layout/Content';
import {
  ExercisiesPageWrapper,
  Title,
  ButtonGroup,
} from '../../constants/styles';
import {
  getMotivations,
  editMotivationCategory,
} from '../../store/motivation/actions';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { TableColumns } from './columns';
import { goTo } from '../../services/navigator.service';
import EditForm from './components/EditForm';

interface IMotivationCategoryProps extends FormComponentProps {
  editMotivationCategory: (values: { [key: string]: any }) => void;
  getMotivations: () => void;
  motivations: { [key: string]: any };
}

interface IMotivationCategoryState {
  modalData: { [key: string]: any };
  modalVisible: boolean;
}

class MotivationCategory extends Component<
  IMotivationCategoryProps,
  IMotivationCategoryState
> {
  constructor(props: IMotivationCategoryProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {},
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getMotivations();
  }

  closeModal() {
    this.setState({ modalVisible: false });
  }

  openModal(data?: { [key: string]: any }) {
    this.setState({ modalVisible: true, modalData: data ? data : {} });
  }

  onSubmit() {
    const { form } = this.props;

    form!.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.editMotivationCategory({
          ...values,
          callback: this.closeModal,
        });
      }
    });
  }

  render() {
    const { loaders } = this.props.motivations;

    const MotivationTableColumns: ColumnProps<any>[] = [
      ...TableColumns,
      {
        dataIndex: '',
        render: (_: string, data: { [key: string]: any }) => {
          const openModalEdit = () => this.openModal(data);

          return (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="read"
                title="Просмотреть категорию"
                htmlType="button"
                onClick={goTo.bind(null, 'motivation', {
                  motivationCategoryId: `${data.id}`,
                })}
              />
              <Button
                className="action-button"
                icon="edit"
                title="Редактировать категорию"
                htmlType="button"
                onClick={openModalEdit}
              />
            </ButtonGroup>
          );
        },
      },
    ];

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Title>Категории мотивации</Title>
          <Table
            rowKey="id"
            columns={MotivationTableColumns}
            loading={loaders.getMotivations}
            dataSource={this.props.motivations.list}
          />
          <Modal
            title="Редактирование категории видео"
            destroyOnClose={true}
            visible={this.state.modalVisible}
            maskClosable={false}
            confirmLoading={loaders.editMotivationCategory}
            onCancel={this.closeModal}
            onOk={this.onSubmit}
          >
            <EditForm form={this.props.form} data={this.state.modalData} />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  motivations: {
    list: state.motivations.list,
    loaders: state.motivations.loaders,
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getMotivations: () => dispatch(getMotivations()),
  editMotivationCategory: (values: { [key: string]: any }) =>
    dispatch(editMotivationCategory(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MotivationCategory);
