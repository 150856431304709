import * as React from 'react';
import Page from '../components/layout/Page';
import Container from '../components/layout/Container';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import ExercisiesListPage from './exercise';
import Workouts from './Workouts';
import WorkoutsPlan from './WorkoutsPlan';
import WorkoutsWeeks from './WorkoutsWeeks';
import ExerciseDetail from './ExerciseDetail';
import Motivation from './Motivation';
import MotivationCategory from './MotivationCategory';
import Rations from './Rations';
import Portions from './Portions';
import Meals from './Meals';
import FreeCourses from './FreeCourses';
import FreeCoursesPlan from './FreeCoursesPlan';
import FreeCoursesWeek from './FreeCoursesWeek';
import FreeCoursesDay from './FreeCoursesDay';
import FreeTrainings from './FreeTrainings';
import FreeExercises from './FreeExercise';
import TrainingPinnedVideo from './TrainingPinnedVideo';
import { Dispatch } from 'redux';
import { logout } from '../store/auth/actions';
import { connect } from 'react-redux';
import routeMap from '../constants/routeMap';

interface PropsFromDispatch {
  logout: typeof logout;
}

class IndexPage extends React.Component<
  RouteComponentProps<{}> & PropsFromDispatch
> {
  public render() {
    const { logout } = this.props;
    return (
      <Page>
        <Container onLogout={logout}>
          <Switch>
            <Route exact path={routeMap.root.path}>
              <Redirect to={routeMap.exercises.path} />
            </Route>
            <Route
              exact
              path={routeMap.exercises.path}
              component={ExercisiesListPage}
            />
            <Route
              exact
              path={routeMap.exercisesDetail.path}
              component={ExerciseDetail}
            />
            <Route exact path={routeMap.workouts.path} component={Workouts} />
            <Route
              exact
              path={routeMap.workoutsPlan.path}
              component={WorkoutsPlan}
            />
            <Route
              exact
              path={routeMap.workoutsWeeks.path}
              component={WorkoutsWeeks}
            />
            <Route
              exact
              path={routeMap.motivation.path}
              component={Motivation}
            />
            <Route
              exact
              path={routeMap.motivationCategory.path}
              component={MotivationCategory}
            />
            <Route exact path={routeMap.portions.path} component={Portions} />
            <Route exact path={routeMap.rations.path} component={Rations} />
            <Route exact path={routeMap.meals.path} component={Meals} />
            <Route exact path={routeMap.freeCourses.path} component={FreeCourses} />
            <Route exact path={routeMap.freeCoursesPlan.path} component={FreeCoursesPlan} />
            <Route exact path={routeMap.freeCoursesWeeks.path} component={FreeCoursesWeek} />
            <Route exact path={routeMap.freeCoursesDays.path} component={FreeCoursesDay} />
            <Route exact path={routeMap.freeTrainings.path} component={FreeTrainings} />
            <Route exact path={routeMap.freeExercises.path} component={FreeExercises} />
            <Route exact path={routeMap.trainingPinnedVideo.path} component={TrainingPinnedVideo} />
            <Route component={() => <div>Not Found</div>} />
          </Switch>
        </Container>
      </Page>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(
  null,
  mapDispatchToProps,
)(IndexPage);
