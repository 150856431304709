import * as React from 'react';
import { Col, Icon, Layout, Menu, Row } from 'antd';
import { map } from 'lodash';
import styled from 'react-emotion';
import history from '../../utils/history';
import routeMap from '../../constants/routeMap';
import { goToPath } from '../../services/navigator.service';

const { Header, Sider, Content } = Layout;

export interface ILayoutProps {
  onLogout: () => void;
}

export class Container extends React.Component<ILayoutProps> {
  state = {
    collapsed: true,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const links = map(routeMap, (item: { [key: string]: any }) => {
      if (item.includeInMenu) {
        return (
          <Menu.Item key={item.name} onClick={goToPath.bind(null, item.path)}>
            <Icon type={item.icon} />
            <span>{item.name}</span>
          </Menu.Item>
        );
      }
    });
    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[history.location.pathname]}
          >
            {links}
          </Menu>
        </Sider>
        <Layout>
          <HeaderWrapper>
            <Header className="layout__header">
              <Row type="flex" justify="space-between">
                <Col>
                  <Icon
                    className="trigger"
                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={this.toggle}
                  />
                </Col>
                <Col>
                  <Icon
                    className="trigger"
                    type="logout"
                    title="Выход"
                    onClick={this.props.onLogout}
                  />
                </Col>
              </Row>
            </Header>
          </HeaderWrapper>
          <ContentWrapper>
            <Content>{React.Children.only(this.props.children)}</Content>
          </ContentWrapper>
        </Layout>
      </Layout>
    );
  }
}

export default Container;

const HeaderWrapper = styled.div`
  .layout__header {
    background: #fff;
    padding: 0;
  }
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    :hover {
      color: #1890ff;
    }
  }
`;

const ContentWrapper = styled.div`
  max-height: calc(100vh - 65px);
`;
