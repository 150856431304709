import { ExercisiesActionsTypes, ExerciseForm, Exercise } from './types';
import { action } from 'typesafe-actions';
import { ApiResponse } from '../../utils/api';

export const showExerciseForm = (ExercisiesForm: ExerciseForm) =>
  action(ExercisiesActionsTypes.OPEN_EXERCISE_FORM, ExercisiesForm);
export const closeExerciseForm = () =>
  action(ExercisiesActionsTypes.CLOSE_EXERCISE_FORM);
export const getExercisies = () =>
  action(ExercisiesActionsTypes.GET_EXERCISIES_REQUEST);
export const getExercisiesSuccess = (response: ApiResponse<Exercise[]>) =>
  action(
    ExercisiesActionsTypes.GET_EXERCISIES_SUCCESS,
    response.data,
    response.code,
  );
export const getExercisiesError = (response: ApiResponse<Exercise[]>) =>
  action(
    ExercisiesActionsTypes.GET_EXERCISIES_ERROR,
    response.error,
    response.code,
  );
export const createExerciseRequest = (Exercisies: Exercise) =>
  action(ExercisiesActionsTypes.CREATE_EXERCISE_REQUEST, Exercisies);
export const createExerciseSuccess = () =>
  action(ExercisiesActionsTypes.CREATE_EXERCISE_SUCCESS);
export const createExerciseError = (response: ApiResponse<Exercise[]>) =>
  action(ExercisiesActionsTypes.CREATE_EXERCISE_ERROR, response);

export const updateExerciseRequest = (Exercisies: Exercise) =>
  action(ExercisiesActionsTypes.UPDATE_EXERCISE_REQUEST, Exercisies);
export const updateExerciseSuccess = () =>
  action(ExercisiesActionsTypes.UPDATE_EXERCISE_SUCCESS);
export const updateExerciseError = (response: ApiResponse<Exercise[]>) =>
  action(ExercisiesActionsTypes.UPDATE_EXERCISE_ERROR, response);

export const deleteExerciseRequest = ({ id, force = false }: { id: number, force?: boolean }) =>
  action(ExercisiesActionsTypes.DELETE_EXERCISE_REQUEST, { id, force });
export const deleteExerciseSuccess = ({ id }: { id: number }) =>
  action(ExercisiesActionsTypes.DELETE_EXERCISE_SUCCESS, { id });
export const deleteExerciseError = (
  response: ApiResponse<Exercise[]>,
  id: number,
) => action(ExercisiesActionsTypes.DELETE_EXERCISE_ERROR, { ...response, id });

export const createExerciseLevelsRequest = (values: { [key: string]: any }) =>
  action(ExercisiesActionsTypes.CREATE_EXERCISE_LEVELS_REQUEST, values);
export const createExerciseLevelsSuccess = () =>
  action(ExercisiesActionsTypes.CREATE_EXERCISE_LEVELS_SUCCESS);
export const createExerciseLevelsError = (
  response: ApiResponse<{ [key: string]: any }>,
) => action(ExercisiesActionsTypes.CREATE_EXERCISE_LEVELS_ERROR, response);

export const deleteExerciseLevelsRequest = ({
  exerciseId,
  exerciseLevelId,
  force = false
}: {
  exerciseId: number;
  exerciseLevelId: number;
  force?: boolean;
}) =>
  action(ExercisiesActionsTypes.DELETE_EXERCISE_LEVELS_REQUEST, {
    exerciseId,
    exerciseLevelId,
    force
  });
export const deleteExerciseLevelsSuccess = ({
  exerciseLevelId,
}: {
  exerciseLevelId: number;
}) =>
  action(ExercisiesActionsTypes.DELETE_EXERCISE_LEVELS_SUCCESS, {
    exerciseLevelId,
  });
export const deleteExerciseLevelsError = (
  response: ApiResponse<{ [key: string]: any }>,
  exerciseId: number,
  exerciseLevelId: number,
) =>
  action(ExercisiesActionsTypes.DELETE_EXERCISE_LEVELS_ERROR, {
    ...response,
    exerciseId,
    exerciseLevelId,
  });
