import { Button, Input } from 'antd';
import * as React from 'react';
import styled from '../../utils/styled';

export const FilterText = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: any) => (
  <FilterDropDown>
    <Input
      placeholder="Введите значение"
      value={selectedKeys[0]}
      onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={confirm}
      className="filter__input"
    />
    <Button
      type="primary"
      onClick={confirm}
      icon="search"
      size="small"
      title="Поиск"
      className="filter__confirm"
    >
      Поиск
    </Button>
    <Button
      onClick={clearFilters}
      size="small"
      title="Сбросить"
      className="filter__clear"
    >
      Сбросить
    </Button>
  </FilterDropDown>
);

export const FilterDropDown = styled.div`
  padding: 8px;
  .filter__input {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }
  .filter__confirm {
    width: 90px;
    margin-right: 8px;
  }
  .filter__clear {
    width: 90px;
  }
`;
