import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { IFreeCoursesActionsTypes } from '../types';
import { callApi } from '../../../utils/api';
import {
  deleteFreeCoursesWeek,
  deleteFreeCoursesWeekError,
  deleteFreeCoursesWeekSuccess,
  getFreeCoursesWeeks,
  getFreeCoursesWeeksError,
  getFreeCoursesWeeksSuccess,
  editFreeCourses,
  editFreeCoursesWeekError,
  editFreeCoursesWeekSuccess
} from '../actions';
import { getAuthToken } from '../../utils';

function* handleGetFreeCoursesWeeksRequest(action: ReturnType<typeof getFreeCoursesWeeks>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const freePlanId = action.payload;
  const response = yield call(callApi, 'get', `admin/free_plans/${freePlanId}/weeks`, token);
  if (response.error) {
    yield put(getFreeCoursesWeeksError(response));
  } else {
    yield put(getFreeCoursesWeeksSuccess(response));
  }
}

function* handleDeleteFreeCoursesWeekRequest(
  action: ReturnType<typeof deleteFreeCoursesWeek>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const { freeCoursesWeekId, freePlanId } = action.payload;
  const response = yield call(
    callApi,
    'delete',
    `admin/free_weeks/${freeCoursesWeekId}`,
    token,
  );
  if (response.error) {
    yield put(deleteFreeCoursesWeekError(response, freeCoursesWeekId));
  } else {
    yield put(deleteFreeCoursesWeekSuccess({ freeCoursesWeekId }));
    yield put(getFreeCoursesWeeks(freePlanId));
  }
}

function* createFreeWeek(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'post',
    `admin/free_plans/${values.freePlanId}/weeks`,
    token,
    values
  );

  if (response.error) {
    yield put(editFreeCoursesWeekError(response));
  } else {
    yield put(editFreeCoursesWeekSuccess());
    yield put(getFreeCoursesWeeks(values.freePlanId));
    values.callback();
  }
}

function* editFreeWeekRequest(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'put',
    `admin/free_weeks/${values.id}`,
    token,
    values,
  );

  if (response.error) {
    yield put(editFreeCoursesWeekError(response));
  } else {
    yield put(editFreeCoursesWeekSuccess());
    yield put(getFreeCoursesWeeks(values.freePlanId));
    if (values.callback) {
      values.callback();
    }
  }
}

function* handleEditFreeCoursesWeekRequest(
  action: ReturnType<typeof editFreeCourses>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield editFreeWeekRequest(token, action.payload);
  } else {
    yield createFreeWeek(token, action.payload);
  }
}

export function* watchDeleteFreeCoursesWeekRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.DELETE_FREE_COURSES_WEEK_REQUEST,
    handleDeleteFreeCoursesWeekRequest,
  );
}

export function* watchGetFreeCoursesWeekRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.GET_FREE_COURSES_WEEKS_REQUEST,
    handleGetFreeCoursesWeeksRequest,
  );
}

export function* watchEditFreeCoursesWeekRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.EDIT_FREE_COURSES_WEEK_REQUEST,
    handleEditFreeCoursesWeekRequest,
  );
}