import React from 'react';
import { Table, Button, Modal, Popconfirm, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormComponentProps } from 'antd/lib/form';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getFreeTrainings, editFreeTraining, deleteFreeTraining } from '../../store/free-courses/actions';
import { getFreeExercises } from '../../store/free-exercise/actions';
import { ApplicationState } from '../../store';
import { IFreeCoursesState } from '../../store/free-courses/types';
import { IFreeExercisesState } from '../../store/free-exercise/types';
import { ContentWrapper } from '../../components/layout/Content';
import Breadcrumbs from '../../components/FreeBreadcrumbs';
import {
  ExercisiesPageWrapper,
  ButtonGroup
} from '../../constants/styles';
import { TableColumns } from './columns';
import EditForm from './components/EditForm';

interface IFreeTrainingsProps extends FormComponentProps {
  getFreeTrainings: (freeDayId: number) => void;
  deleteFreeTraining: (FreeTrainingId: number, freeDayId: number) => void;
  editFreeTraining: (values: {[key: string]: any}) => void;
  getFreeExercises: () => void;
  free_courses: IFreeCoursesState;
  free_exercises: IFreeExercisesState;
  match: { [key: string]: any };
}

interface IFreeTrainingsComponentState {
  modalVisible: boolean;
  modalData: { [key: string]: any };
}

class FreeTrainings extends React.Component<IFreeTrainingsProps, IFreeTrainingsComponentState> {
  constructor(props: IFreeTrainingsProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {},
    }
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getFreeTrainings(this.props.match.params.freeDayId);
    this.props.getFreeExercises();
  }

  closeModal() {
    this.setState({ modalVisible: false, modalData: {} });
  }

  openModal() {
    this.setState({ modalVisible: true });
  }

  onSubmit() {
    const { form } = this.props;
    form!.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.editFreeTraining({
          ...values,
          freeDayId: this.props.match.params.freeDayId,
          callback: this.closeModal
        });
      };
      if (err) {
        message.error('Правильно заполните поля');
      };
    });
  }

  render() {
    const { loaders, data } = this.props.free_courses;
    const tableData = data || [];

    const ModifyTableColumns: ColumnProps<any>[] = [
      ...TableColumns,
      {
        title: (
          <Button
            className="action-button"
            icon="plus-circle"
            htmlType="button"
            title="Добавить тренировку"
            onClick={this.openModal}
          />
        ),
        dataIndex: '',
        key: 'x',
        render: (_: string, data: { [key: string]: any }) => {
          const openEditModal = () => {
            this.setState({ modalData: data });
            this.setState({ modalVisible: true });
          };

          return (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="edit"
                htmlType="button"
                title="Редактировать тренировку"
                onClick={openEditModal}
              />
              <Popconfirm
                title="Вы уверены, что хотите удалить день тренировки?"
                onConfirm={() => this.props.deleteFreeTraining(data.id, this.props.match.params.freeDayId)}
                placement="rightBottom"
              >
                <Button
                  className="action-button"
                  icon="delete"
                  htmlType="button"
                  title="Удалить тренировку"
                  loading={loaders[`delete/${data.id}`]}
                />
              </Popconfirm>
            </ButtonGroup>
          );
        },
      },
    ];

    const modalTitle = this.state.modalData.id
      ? 'Редактирование тренировки'
      : 'Добавление тренировки';

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Breadcrumbs type="trainings" id={this.props.match.params.freeDayId} />
          <Table
            rowKey="id"
            columns={ModifyTableColumns}
            loading={loaders.getFreeTrainings}
            dataSource={tableData}
          />
          <Modal
            title={modalTitle}
            destroyOnClose={true}
            maskClosable={false}
            visible={this.state.modalVisible}
            confirmLoading={loaders.editFreeTraining}
            onCancel={this.closeModal}
            onOk={this.onSubmit}
          >
            <EditForm
              form={this.props.form}
              data={this.state.modalData}
              free_exercises={this.props.free_exercises}
            />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  free_courses: state.free_courses,
  free_exercises: state.free_exercise
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFreeExercises: () => dispatch(getFreeExercises()),
  getFreeTrainings: (freeDayId: number) => dispatch(getFreeTrainings(freeDayId)),
  deleteFreeTraining: (freeTrainingId: number, freeDayId: number) => dispatch(deleteFreeTraining({ freeDayId, freeTrainingId })),
  editFreeTraining: (values: { [key: string]: any }) => dispatch(editFreeTraining(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreeTrainings);