import * as React from 'react';
import { FormEvent } from 'react';
import { Button, Card, Form, Icon, Input } from 'antd';
import styled from 'react-emotion';
import Page from '../../components/layout/Page';
import { FormComponentProps } from 'antd/lib/form/Form';
import { ApplicationState, ConnectedReduxProps } from '../../store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { loginPerform } from '../../store/auth/actions';
import { AuthRequest, AuthState } from '../../store/auth/types';

interface LoginFormStateFromProps extends AuthState {
}

interface PropsFromDispatch {
  loginPerform: typeof loginPerform
}

type ALLProps = PropsFromDispatch & LoginFormStateFromProps & FormComponentProps & ConnectedReduxProps;

class LoginForm extends React.Component<ALLProps> {
  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.loginPerform(values)
      }
    });
  }

  render() {
    const {getFieldDecorator } = this.props.form;
    const { errors, loading } = this.props;
    return (
      <Page>
        <LoginFormWrapper>
          <Form onSubmit={this.handleSubmit}>
            <Card
              title="Вход в панель администрирования"
              actions={[<Button loading={loading} type="primary" htmlType="submit">Войти</Button>]}>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [{required: true, message: 'Введите имя пользователя!'}],
                })(
                  <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                         placeholder="Имя пользователя"/>,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{required: true, message: 'Введите пароль!'}],
                })(
                  <Input.Password prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"
                         placeholder="Пароль"/>,
                )}
              </Form.Item>
              {errors ? <ErrorMessage>Проверьте правильность логина или пароля</ErrorMessage> : null}
            </Card>
          </Form>
        </LoginFormWrapper>
      </Page>
    )
  }
}

const LoginFormPage = Form.create<LoginFormStateFromProps>({})(LoginForm);

const mapStateToProps = ({ auth }: ApplicationState) => ({
  loading: auth.loading,
  errors: auth.errors,
  data: auth.data
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginPerform: (data: AuthRequest) => dispatch(loginPerform(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormPage)

const LoginFormWrapper = styled.div`
  margin: auto;
  width: 400px;
`

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error}
`
