import React from 'react';
import { Table, Button, Modal, Checkbox, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormComponentProps } from 'antd/lib/form';
import { getFreeCourses, editFreeCourses } from '../../store/free-courses/actions';
import { ApplicationState } from '../../store';
import { IFreeCoursesState } from '../../store/free-courses/types';
import { ContentWrapper } from '../../components/layout/Content';
import {
  ExercisiesPageWrapper,
  Title,
  ButtonGroup,
} from '../../constants/styles';
import { TableColumns } from './columns';
import EditForm from './components/EditForm';
import { goTo } from '../../services/navigator.service';

interface IFreeCoursesProps extends FormComponentProps {
  getFreeCourses: () => void;
  editFreeCourses: (values: {[key: string]: any}) => void;
  free_courses: IFreeCoursesState;
}

interface IFreeCoursesComponentState {
  modalVisible: boolean;
  modalData: { [key: string]: any };
}

class FreeCourses extends React.Component<IFreeCoursesProps, IFreeCoursesComponentState> {
  constructor(props: IFreeCoursesProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {},
    }
    this.isPublishOnChangeHandler = this.isPublishOnChangeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getFreeCourses();
  }

  isPublishOnChangeHandler(
    data: { [key: string]: any },
    event: CheckboxChangeEvent,
  ) {
    let isChecked = event.target.checked;

    this.props.editFreeCourses({
      ...data,
      isPublish: isChecked,
    });
  };

  closeModal() {
    this.setState({ modalVisible: false, modalData: {} });
  }

  onSubmit() {
    const { form } = this.props;
    form!.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.editFreeCourses({ ...values, callback: this.closeModal });
      };
      if (err) {
        message.error('Правильно заполните поля во всех вкладках');
      };
    });
  }

  render() {
    const { loaders, data } = this.props.free_courses;
    const tableData = data || [];

    const ModifyTableColumns: ColumnProps<any>[] = [
      ...TableColumns,
      {
        title: 'Опубликован',
        dataIndex: 'isPublished',
        align: 'center',
        render: (_: string, data: { [key: string]: any }) => {
          return (
            <Checkbox
              checked={data.isPublish}
              onChange={e => this.isPublishOnChangeHandler(data, e)}
              disabled={loaders.editFreeCourses}
            />
          );
        },
      },
      {
        dataIndex: '',
        key: 'x',
        render: (_: string, data: { [key: string]: any }) => {
          const openEditModal = () => {
            this.setState({ modalData: data });
            this.setState({ modalVisible: true });
          };

          return (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="read"
                htmlType="button"
                title="Просмотреть бесплатный курс"
                onClick={goTo.bind(null, 'freeCoursesPlan', {
                  freeCoursesId: `${data.id}`,
                })}
              />
              <Button
                className="action-button"
                icon="edit"
                htmlType="button"
                title="Редактировать бесплатный курс"
                onClick={ openEditModal }
              />
            </ButtonGroup>
          );
        },
      },
    ];

    const modalTitle = this.state.modalData.id
      ? 'Редактирование бесплатного курса'
      : 'Добавление бесплатного курса';

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Title>
            Бесплатные курсы
          </Title>
          <Table
            rowKey="id"
            columns={ModifyTableColumns}
            loading={loaders.getFreeCourses}
            dataSource={tableData}
          />
          <Modal
            title={modalTitle}
            destroyOnClose={true}
            maskClosable={false}
            visible={this.state.modalVisible}
            confirmLoading={loaders.editWorkoutsPlan}
            onCancel={this.closeModal}
            onOk={this.onSubmit}
          >
            <EditForm
              form={this.props.form}
              data={this.state.modalData}
            />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  free_courses: state.free_courses
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFreeCourses: () => dispatch(getFreeCourses()),
  editFreeCourses: (values: { [key: string]: any }) =>
    dispatch(editFreeCourses(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreeCourses);