import React from 'react';
import { Form, Input, Upload, Icon, Modal, Button, Spin, Tabs, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FormComponentProps } from 'antd/lib/form';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ContentWrapper } from '../../components/layout/Content';
import Thumbnail from '../../components/Thumbnail';
import RequiredLabel from '../../components/RequiredLabel';
import {
  ExercisiesPageWrapper,
  Title,
} from '../../constants/styles';
import {
  getTrainingPinnedVideo,
  editTrainingPinnedVideo
} from '../../store/training_pinned_video/actions';
import {
  required,
  NOT_BLANK,
  IS_PICTURE,
  IS_VIMEO_URL,
  maxLength,
} from '../../constants/validationRules';
import { ApplicationState } from '../../store';

interface ITrainingPinnedVideoState {
  visible: boolean;
  modalContent: JSX.Element | JSX.Element[] | string;
}

interface ITrainingPinnedVideoProps extends FormComponentProps  {
  editTrainingPinnedVideo: (values: { [key: string]: any }) => void;
  getTrainingPinnedVideo: () => void;
  training_pinned_video: { [key: string]: any };
}

class TrainingPinnedVideo extends React.Component<ITrainingPinnedVideoProps, ITrainingPinnedVideoState> {
  constructor(props: ITrainingPinnedVideoProps) {
    super(props);
    this.state = {
      visible: false,
      modalContent: '',
    };
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.handleChangeImageEng = this.handleChangeImageEng.bind(this);
    this.onRemoveImageEng = this.onRemoveImageEng.bind(this);
    this.createOnPreview = this.createOnPreview.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount() {
    this.props.getTrainingPinnedVideo();
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;

    const name = getFieldDecorator(`name`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(16)],
      initialValue: get(this.props.training_pinned_video.data, 'name', ''),
    })(<Input placeholder="Название" />);

    const name_eng = getFieldDecorator(`name_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(16)],
      initialValue: get(this.props.training_pinned_video.data, 'name_eng', ''),
    })(<Input placeholder="Title" />);

    const subName = getFieldDecorator(`subName`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(38)],
      initialValue: get(this.props.training_pinned_video.data, 'subName', ''),
    })(<Input placeholder="Название" />);

    const subName_eng = getFieldDecorator(`subName_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(38)],
      initialValue: get(this.props.training_pinned_video.data, 'subName_eng', ''),
    })(<Input placeholder="Subtitle" />);

    const description = getFieldDecorator(`description`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(354)],
      initialValue: get(this.props.training_pinned_video.data, 'description', ''),
    })(<TextArea rows={4} placeholder="Описание" />);

    const description_eng = getFieldDecorator(`description_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(354)],
      initialValue: get(this.props.training_pinned_video.data, 'description_eng', ''),
    })(<TextArea rows={4} placeholder="Description" />);

    const video = getFieldDecorator(`video`, {
      validateTrigger: 'onSubmit',
      rules: [required, IS_VIMEO_URL],
      initialValue: get(this.props.training_pinned_video.data, 'video', ''),
    })(<Input placeholder="Ссылка на видео" />);

    const video_eng = getFieldDecorator(`video_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, IS_VIMEO_URL],
      initialValue: get(this.props.training_pinned_video.data, 'video_eng', ''),
    })(<Input placeholder="Link to the video" />);

    const imageSrc = getFieldDecorator(`imageSrc`, {
      validateTrigger: 'onSubmit',
      initialValue: get(this.props.training_pinned_video.data, 'videoPreview'),
    })(<></>);

    const imageRules = this.props.form.getFieldValue('imageSrc')
      ? []
      : [IS_PICTURE];

    const videoPreview = getFieldDecorator(`videoPreview`, {
      validateTrigger: 'onSubmit',
      rules: imageRules,
      initialValue: null,
    })(<></>);

    const imageSrcEng = getFieldDecorator(`imageSrcEng`, {
      validateTrigger: 'onSubmit',
      initialValue: get(this.props.training_pinned_video.data, 'videoPreview_eng'),
    })(<></>);

    const imageRulesEng = this.props.form.getFieldValue('imageSrcEng')
      ? []
      : [IS_PICTURE];

    const videoPreview_eng = getFieldDecorator(`videoPreview_eng`, {
      validateTrigger: 'onSubmit',
      rules: imageRulesEng,
      initialValue: null,
    })(<></>);

    return {
      name,
      name_eng,
      video,
      video_eng,
      videoPreview,
      videoPreview_eng,
      imageSrc,
      imageSrcEng,
      subName,
      subName_eng,
      description,
      description_eng,
    };
  }

  onSubmit() {
    const { form } = this.props;
    form!.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.editTrainingPinnedVideo(values);
      };
      if (err) {
        message.error('Правильно заполните поля во всех вкладках');
      };
    });
  }

  handleChangeImage(file: { [key: string]: any }) {
    this.props.form.setFieldsValue({ videoPreview: file });
    this.props.form.validateFields(['videoPreview']);
    return false;
  }
  handleChangeImageEng(file: { [key: string]: any }) {
    this.props.form.setFieldsValue({ videoPreview_eng: file });
    this.props.form.validateFields(['videoPreview_eng']);
    return false;
  }

  onRemoveImage() {
    this.props.form.setFieldsValue({ videoPreview: null });
    this.props.form.validateFields(['videoPreview']);
    return true;
  }
  onRemoveImageEng() {
    this.props.form.setFieldsValue({ videoPreview_eng: null });
    this.props.form.validateFields(['videoPreview_eng']);
    return true;
  }

  createOnPreview(fieldName: string) {
    return (file: { [key: string]: any }) => {
      const { form } = this.props;
      const errorMessages = form.getFieldError(fieldName);
      const formatErrorMessage:string = 'Файл должен быть картинкой';
      if(errorMessages && errorMessages.length && errorMessages[0] === formatErrorMessage) {
        return false;
      }
      const modalContent = <img src={file.thumbUrl} />;
      this.setState({ modalContent, visible: true });
    }
  }

  render() {
    const createUploadButton = (fieldName: string) => {
      return this.props.form.getFieldValue(fieldName) ? null : (
        <>
          <Icon type={'plus'} />
          <div className="ant-upload-text">Загрузить</div>
        </>
      );
    }
    const uploadButton = createUploadButton('videoPreview');
    const uploadButtonEng = createUploadButton('videoPreview_eng');

    const imageHref = this.fields.imageSrc.props.value;
    const imageHrefEng = this.fields.imageSrcEng.props.value;

    const onRemoveThumbnail = () =>
      this.props.form.setFieldsValue({ imageSrc: null });
    const onRemoveThumbnailEng = () =>
      this.props.form.setFieldsValue({ imageSrcEng: null });

    const onPreviewThumbnail = () => {
      const modalContent = <img src={imageHref} />;
      this.setState({ modalContent, visible: true });
    };
    const onPreviewThumbnailEng = () => {
      const modalContent = <img src={imageHrefEng} />;
      this.setState({ modalContent, visible: true });
    };

    const onPreview = this.createOnPreview('videoPreview');
    const onPreviewEng = this.createOnPreview('videoPreview_eng');

    const thumbnail = imageHref ? (
      <Thumbnail
        src={imageHref}
        onRemove={onRemoveThumbnail}
        onPreview={onPreviewThumbnail}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={this.handleChangeImage}
          onRemove={this.onRemoveImage}
          onPreview={onPreview}
        >
          {uploadButton}
        </Upload>
        {this.fields.videoPreview}
      </>
    );
    const thumbnailEng = imageHrefEng ? (
      <Thumbnail
        src={imageHrefEng}
        onRemove={onRemoveThumbnailEng}
        onPreview={onPreviewThumbnailEng}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={this.handleChangeImageEng}
          onRemove={this.onRemoveImageEng}
          onPreview={onPreviewEng}
        >
          {uploadButtonEng}
        </Upload>
        {this.fields.videoPreview_eng}
      </>
    );

    if (this.props.training_pinned_video.loaders.getTrainingPinnedVideo) {
      return (
        <ContentWrapper>
          <ExercisiesPageWrapper>
            <Title>Видео для всех категорий</Title>
            <Spin spinning={true} size="large" />
          </ExercisiesPageWrapper>
        </ContentWrapper>
      )
    }

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Title>Видео для всех категорий</Title>
          <Form style={ { maxWidth: '450px' } }>
            <Tabs defaultActiveKey="1" centered>
              <Tabs.TabPane tab="Общие настройки" key="1">
                <Form.Item label="Название">{this.fields.name}</Form.Item>
                <Form.Item label="Подзаголовок">{this.fields.subName}</Form.Item>
                <Form.Item label="Описание">{this.fields.description}</Form.Item>
                <Form.Item label={<RequiredLabel text="Превью видео" />}>
                  {thumbnail}
                </Form.Item>
                <Form.Item label="Ссылка на видео">{this.fields.video}</Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Английский вариант" key="2">
                <Form.Item label="Title">{this.fields.name_eng}</Form.Item>
                <Form.Item label="Subtitle">{this.fields.subName_eng}</Form.Item>
                <Form.Item label="Description">{this.fields.description_eng}</Form.Item>
                <Form.Item label={<RequiredLabel text="Video Preview" />}>
                  {thumbnailEng}
                </Form.Item>
                <Form.Item label="Link to the video">{this.fields.video_eng}</Form.Item>
              </Tabs.TabPane>
            </Tabs>

            <Modal
              footer={null}
              visible={this.state.visible}
              onCancel={() => this.setState({ visible: false })}
            >
              {this.state.modalContent}
            </Modal>
            <Button
              type="primary"
              loading={this.props.training_pinned_video.loaders.editTrainingPinnedVideo}
              onClick={this.onSubmit}
            >
              Сохранить
            </Button>
          </Form>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  training_pinned_video: {
    data: state.training_pinned_video.data,
    loaders: state.training_pinned_video.loaders,
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getTrainingPinnedVideo: () => dispatch(getTrainingPinnedVideo()),
  editTrainingPinnedVideo: (values: {[key: string]: any}) => dispatch(editTrainingPinnedVideo(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrainingPinnedVideo);
