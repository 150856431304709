import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { message } from 'antd';
import { IFreeCoursesActionsTypes } from '../types';
import { callApi } from '../../../utils/api';
import {
  getFreePlans,
  getFreePlansError,
  getFreePlansSuccess,
  editFreePlan,
  editFreePlanError,
  editFreePlanSuccess,
  deleteFreePlan,
  deleteFreePlanError,
  deleteFreePlanSuccess,
} from '../actions';
import { getAuthToken } from '../../utils';
import { CONTENT_TYPES } from '../../../utils/api';

function* handleGetFreePlansRequest(action: ReturnType<typeof getFreePlans>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const freeCoursesId = action.payload;
  const response = yield call(callApi, 'get', `admin/free_courses/${freeCoursesId}/plans`, token);
  if (response.error) {
    yield put(getFreePlansError(response));
  } else {
    yield put(getFreePlansSuccess(response));
  }
}

function* handleUploadFreePlanImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { freePlanId } = data;
  const response = yield call(
    callApi,
    'post',
    `admin/free_plans/${freePlanId}/image`,
    token,
    { image: data.image, image_eng: data.image_eng },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}


function* createFreePlan(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'post',
    `admin/free_courses/${values.freeCoursesId}/plans`,
    token,
    { ...values },
  );

  if (response.error) {
    yield put(editFreePlanError(response));
  } else {
    const freePlanId = response.data.id;
    yield handleUploadFreePlanImage({
      token,
      data: { ...values, freePlanId },
    });
    yield put(editFreePlanSuccess());
    yield put(getFreePlans(values.freeCoursesId));
    values.callback();
  }
}

function* editFreePlanRequest(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'put',
    `admin/free_plans/${values.id}`,
    token,
    { ...values },
  );

  if (response.error) {
    yield put(editFreePlanError(response));
  } else {
    if ((values.image && typeof values.image !== 'string') || (values.image_eng && typeof values.image_eng !== 'string')) {
      yield handleUploadFreePlanImage({
        token,
        data: { ...values, freePlanId: values.id },
      });
    }
    yield put(editFreePlanSuccess());
    yield put(getFreePlans(values.freeCoursesId));
    if (values.callback) {
      values.callback();
    }
  }
}

function* handleEditFreePlanRequest(
  action: ReturnType<typeof editFreePlan>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield editFreePlanRequest(token, action.payload);
  } else {
    yield createFreePlan(token, action.payload);
  }
}

function* handleDeleteFreePlanRequest(
  action: ReturnType<typeof deleteFreePlan>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const { freeCoursesId, freePlanId } = action.payload;
  const response = yield call(
    callApi,
    'delete',
    `admin/free_plans/${freePlanId}`,
    token,
  );
  if (response.error) {
    yield put(deleteFreePlanError(response, freePlanId));
  } else {
    yield put(deleteFreePlanSuccess({ freePlanId }));
    yield put(getFreePlans(freeCoursesId));
  }
}

export function* watchGetFreePlansRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.GET_FREE_PLANS_REQUEST,
    handleGetFreePlansRequest,
  );
}


export function* watchEditFreePlanRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.EDIT_FREE_PLAN_REQUEST,
    handleEditFreePlanRequest,
  );
}

export function* watchDeleteFreePlanRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.DELETE_FREE_PLAN_REQUEST,
    handleDeleteFreePlanRequest,
  );
}