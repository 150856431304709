import { ITrainingPinnedVideoActionsTypes, ITrainingPinnedVideoState } from './types';
import { Reducer } from 'redux';

const InitialState: ITrainingPinnedVideoState = {
  data: {},
  loaders: {},
};

const reducer: Reducer = (state = InitialState, action) => {
  switch (action.type) {
    case ITrainingPinnedVideoActionsTypes.GET_TRAINING_PINNED_VIDEO_REQUEST:
      return { ...state, loaders: { ...state.loaders, getTrainingPinnedVideo: true } };
    case ITrainingPinnedVideoActionsTypes.GET_TRAINING_PINNED_VIDEO_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getTrainingPinnedVideo: false },
        data: action.payload,
      };
    case ITrainingPinnedVideoActionsTypes.GET_TRAINING_PINNED_VIDEO_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getTrainingPinnedVideo: false },
      };

    case ITrainingPinnedVideoActionsTypes.EDIT_TRAINING_PINNED_VIDEO_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editTrainingPinnedVideo: true },
      };
    case ITrainingPinnedVideoActionsTypes.EDIT_TRAINING_PINNED_VIDEO_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, editTrainingPinnedVideo: false },
      };
    case ITrainingPinnedVideoActionsTypes.EDIT_TRAINING_PINNED_VIDEO_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editTrainingPinnedVideo: false },
      };

    default:
      return state;
  }
};

export { reducer as TrainingPinnedVideoReducer };
