import { IWorkoutsActionsTypes, IWorkoutsState } from './types';
import { Reducer } from 'redux';

const InitialState: IWorkoutsState = {
  list: [],
  plans: [],
  weeks: [],
  day: {},
  loaders: {},
};

const reducer: Reducer = (state = InitialState, action) => {
  switch (action.type) {

    // get workoutDay
    case IWorkoutsActionsTypes.GET_WORKOUTS_DAY_REQUEST:
      return { ...state, loaders: { ...state.loaders, getWorkoutsDay: true } };
    case IWorkoutsActionsTypes.GET_WORKOUTS_DAY_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getWorkoutsDay: false },
        day: action.payload,
      };
    case IWorkoutsActionsTypes.GET_WORKOUTS_DAY_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getWorkoutsDay: false },
      };

    // get workoutWeek
    case IWorkoutsActionsTypes.GET_WORKOUTS_WEEK_REQUEST:
      return { ...state, loaders: { ...state.loaders, getWorkoutsWeek: true } };
    case IWorkoutsActionsTypes.GET_WORKOUTS_WEEK_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getWorkoutsWeek: false },
        weeks: action.payload,
      };
    case IWorkoutsActionsTypes.GET_WORKOUTS_WEEK_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getWorkoutsWeek: false },
      };

    // get workoutPlan
    case IWorkoutsActionsTypes.GET_WORKOUTS_PLAN_REQUEST:
      return { ...state, loaders: { ...state.loaders, getWorkoutsPlan: true } };
    case IWorkoutsActionsTypes.GET_WORKOUTS_PLAN_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getWorkoutsPlan: false },
        plans: action.payload,
      };
    case IWorkoutsActionsTypes.GET_WORKOUTS_PLAN_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getWorkoutsPlan: false },
      };

    // get workout
    case IWorkoutsActionsTypes.GET_WORKOUTS_REQUEST:
      return { ...state, loaders: { ...state.loaders, getWorkouts: true } };
    case IWorkoutsActionsTypes.GET_WORKOUTS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getWorkouts: false },
        list: action.payload,
      };
    case IWorkoutsActionsTypes.GET_WORKOUTS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getWorkouts: false },
      };

    // edit workouts
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkouts: true },
      };
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkouts: false },
      };
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkouts: false },
      };

    // edit workouts plan
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_PLAN_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkoutsPlan: true },
      };
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_PLAN_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkoutsPlan: false },
      };
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_PLAN_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkoutsPlan: false },
      };

    // delete workouts plan
    case IWorkoutsActionsTypes.DELETE_WORKOUTS_PLAN_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.workoutPlanId}`]: true,
        },
      };
    case IWorkoutsActionsTypes.DELETE_WORKOUTS_PLAN_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.workoutPlanId}`]: false,
        },
      };
    case IWorkoutsActionsTypes.DELETE_WORKOUTS_PLAN_ERROR:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.workoutPlanId}`]: false,
        },
      };

    // add/update workouts week
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_WEEK_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkoutsWeeks: true },
      };
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_WEEK_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkoutsWeeks: false },
      };
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_WEEK_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkoutsWeeks: false },
      };

    // delete workouts plan
    case IWorkoutsActionsTypes.DELETE_WORKOUTS_WEEK_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.workoutWeekId}`]: true,
        },
      };
    case IWorkoutsActionsTypes.DELETE_WORKOUTS_WEEK_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.workoutWeekId}`]: false,
        },
      };
    case IWorkoutsActionsTypes.DELETE_WORKOUTS_WEEK_ERROR:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.workoutWeekId}`]: false,
        },
      };

    // copy workouts plan
    case IWorkoutsActionsTypes.COPY_WORKOUTS_WEEK_REQUEST:
      const weekCopyRequestLoader = {};
      weekCopyRequestLoader[`copy/${action.payload.workoutWeekId}`] = true;

      return {
        ...state,
        loaders: {
          ...state.loaders,
          ...weekCopyRequestLoader,
        },
      };
    case IWorkoutsActionsTypes.COPY_WORKOUTS_WEEK_SUCCESS:
      const weekCopySuccessLoader = {};
      weekCopySuccessLoader[`copy/${action.payload.workoutWeekId}`] = false;
      return {
        ...state,
        loaders: { ...state.loaders, ...weekCopySuccessLoader },
      };
    case IWorkoutsActionsTypes.COPY_WORKOUTS_WEEK_ERROR:
      const weekCopyErrorLoader = {};
      weekCopyErrorLoader[`copy/${action.payload.workoutWeekId}`] = false;
      return {
        ...state,
        loaders: { ...state.loaders, ...weekCopyErrorLoader },
      };

    // edit workouts day
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_DAY_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkoutsDay: true },
      };
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_DAY_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkoutsDay: false },
      };
    case IWorkoutsActionsTypes.EDIT_WORKOUTS_DAY_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editWorkoutsDay: false },
      };

    default:
      return state;
  }
};

export { reducer as WorkoutsReducer };
