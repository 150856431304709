export const required = {
  required: true,
  message: 'Поле не может быть пустым.',
};

export const NOT_BLANK = {
  validator: (
    rule: { [key: string]: any },
    value: string,
    callback: (message?: string) => void,
  ) => {
    const regSpace = /^(\s)|(\s)$/;
    if (regSpace.test(value)) {
      callback('Значение не может начинаться или заканчиваться пробелом.');
    } else {
      callback();
    }
  },
};

export const NOT_INCLUDE = (arr: any[]) => ({
  validator: (
    rule: { [key: string]: any },
    value: string,
    callback: (message?: string) => void,
  ) => {
    // @ts-ignore
    if (arr.includes(value)) {
      callback('Такой номер недели уже есть.');
    } else {
      callback();
    }
  },
});

export const IS_VIMEO_URL = {
  validator: (
    rule: { [key: string]: any },
    value: string,
    callback: (message?: string) => void,
  ) => {
    const vimeoReg = /(http|https)?:\/\/(player\.)?vimeo.com\/([\w\./]+)([\?].*)?$/; // tslint:disable-line
    if (!vimeoReg.test(value)) {
      callback('Введите корректную ссылку на Vimeo.');
    } else {
      callback();
    }
  },
};

export const IS_VIMEO_URL_NOT_REQUIRED = {
  validator: (
    rule: { [key: string]: any },
    value: string,
    callback: (message?: string) => void,
  ) => {
    if (!value) return callback();
    const vimeoReg = /(http|https)?:\/\/(player\.)?vimeo.com\/([\w\./]+)([\?].*)?$/; // tslint:disable-line
    if (!vimeoReg.test(value)) {
      callback('Введите корректную ссылку на Vimeo.');
    } else {
      callback();
    }
  },
};

const availableFormats = ['jpeg', 'jpg', 'png'];

const checkImgType = (image: any) => {
  const format = image.type.split('/')[1];
  // @ts-ignore
  return availableFormats.includes(format);
};

export const IS_PICTURE = {
  validator: (
    rule: { [key: string]: any },
    value: { [key: string]: any },
    callback: (message?: string) => void,
  ) => {
    if (!value) {
      callback('Поле не может быть пустым');
    } else if (!checkImgType(value)) {
      callback('Недопустимый формат файла. Допустимые форматы jpeg,jpg,png.');
    } else if (value.size > 102400) {
      callback('Размер изображения должен быть менее 100 Кб');
    } else {
      callback();
    }
  },
};

export const IS_PICTURE_NO_REQUIRED = {
  validator: (
    rule: { [key: string]: any },
    value: { [key: string]: any },
    callback: (message?: string) => void,
  ) => {
    if (value) {
      if (!checkImgType(value)) {
        callback('Недопустимый формат файла. Допустимые форматы jpeg,jpg,png.');
      }
      if (value.size > 102400) {
        callback('Размер изображения должен быть менее 100 Кб');
      }
      callback();
    }
    callback();
  },
};

export const rangeValue = (from: number, to: number) => ({
  type: 'number',
  min: from,
  max: to,
  message: `Число должно быть в диапазоне от ${from} до ${to}.`,
});

export const maxLength = (length: number) => ({
  max: length,
  message: `Длина не должна превышать ${length} знаков.`,
});

export const minLength = (length: number) => ({
  min: length,
  message: `Длина не должна быть меньше ${length} знаков.`,
});
