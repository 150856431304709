import React, { Component } from 'react';
import { Table, Button, Modal, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormComponentProps } from 'antd/lib/form';
import { ContentWrapper } from '../../components/layout/Content';
import { goTo } from '../../services/navigator.service';
import {
  getPortions,
  editPortions,
  deletePortion,
  getRationItem,
} from '../../store/food/actions';
import {
  ExercisiesPageWrapper,
  Title,
  ButtonGroup,
} from '../../constants/styles';
import { ApplicationState } from '../../store';
import { TableColumns } from './columns';
import { goToPath } from '../../services/navigator.service';
import routeMap from '../../constants/routeMap';
import EditForm from './components/EditForm';

interface IPortionsProps extends FormComponentProps {
  getPortions: ({ rationId }: { rationId: number }) => void;
  getRationItem: ({ rationId }: { rationId: number }) => void;
  editPortions: (values: { [key: string]: any }) => void;
  deletePortion: ({
    rationId,
    portionId,
  }: {
    rationId: number;
    portionId: number;
  }) => void;
  portions: { [key: string]: any };
  match: { [key: string]: any };
}

interface IPortionsState {
  modalVisible: boolean;
  modalData: { [key: string]: any };
}

class Portions extends Component<IPortionsProps, IPortionsState> {
  constructor(props: IPortionsProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {},
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { rationId } = this.props.match.params;
    this.props.getPortions({ rationId });
    this.props.getRationItem({ rationId });
  }

  closeModal() {
    this.setState({ modalVisible: false });
  }

  openModal(data?: { [key: string]: any }) {
    this.setState({ modalVisible: true, modalData: data ? data : {} });
  }

  onSubmit() {
    const { form } = this.props;

    const fieldNames = [
      'name',
      'sort',
      'image',
      'imageSrc',
      'isPublish'
    ]

    form!.validateFields(fieldNames, { firstFields: fieldNames }, (err: any, values: any) => {
      const data = { ...values, id: this.state.modalData.id }

      if (!err) {
        const { rationId } = this.props.match.params;
        this.props.editPortions({
          ...data,
          rationId,
          callback: this.closeModal,
        });
      }
    });
  }

  render() {
    const { loaders, rationItem } = this.props.portions;
    const PortionsTableColumns: ColumnProps<any>[] = [
      ...TableColumns,
      {
        title: (
          <Button
            className="action-button"
            icon="plus-circle"
            htmlType="button"
            title="Добавить прием пищи"
            onClick={this.openModal}
          />
        ),
        dataIndex: '',
        key: 'x',
        render: (_: string, data: { [key: string]: any }) => {
          const { rationId } = this.props.match.params;
          const openModalEdit = () => this.openModal(data);
          const onDeletePortion = () =>
            this.props.deletePortion({ portionId: data.id, rationId });

          return (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="read"
                htmlType="button"
                title="Просмотреть прием пищи"
                onClick={goTo.bind(null, 'meals', {
                  rationId,
                  portionId: `${data.id}`,
                })}
              />
              <Button
                className="action-button"
                icon="edit"
                htmlType="button"
                title="Редактировать прием пищи"
                onClick={openModalEdit}
              />
              <Popconfirm
                title="Вы уверены, что хотите удалить прием пищи?"
                onConfirm={onDeletePortion}
                placement="rightBottom"
              >
                <Button
                  className="action-button"
                  icon="delete"
                  htmlType="button"
                  title="Удалить прием пищи"
                  loading={loaders[`delete/${data.id}`]}
                />
              </Popconfirm>
            </ButtonGroup>
          );
        },
      },
    ];

    const modalTitle = this.state.modalData.id
      ? 'Редактирование приема пищи'
      : 'Добавление приема пищи';

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Title>
            <span onClick={goToPath.bind(null, routeMap.rations.path)}>
              Питание
            </span>{' '}
            - {rationItem.name}
          </Title>
          <Table
            rowKey="id"
            columns={PortionsTableColumns}
            loading={loaders.getPortions}
            dataSource={this.props.portions.list}
          />
          <Modal
            maskClosable={false}
            title={modalTitle}
            destroyOnClose={true}
            visible={this.state.modalVisible}
            confirmLoading={loaders.editPortions}
            onCancel={this.closeModal}
            onOk={this.onSubmit}
          >
            <EditForm form={this.props.form} data={this.state.modalData} />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  portions: {
    list: state.food.list,
    loaders: state.food.loaders,
    rationItem: state.food.rationItem,
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPortions: ({ rationId }: { rationId: number }) =>
    dispatch(getPortions({ rationId })),
  getRationItem: ({ rationId }: { rationId: number }) =>
    dispatch(getRationItem({ rationId })),
  editPortions: (values: { [key: string]: any }) =>
    dispatch(editPortions(values)),
  deletePortion: ({
    rationId,
    portionId,
  }: {
    rationId: number;
    portionId: number;
  }) => dispatch(deletePortion({ rationId, portionId })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Portions);
