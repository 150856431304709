import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { IFreeExercisesActionsTypes } from './types';
import { callApi } from '../../utils/api';
import { message } from 'antd';
import {
  getFreeExercises,
  getFreeExercisesError,
  getFreeExercisesSuccess,
  editFreeExercise,
  editFreeExerciseError,
  editFreeExerciseSuccess,
  deleteFreeExercise,
  deleteFreeExerciseError,
  deleteFreeExerciseSuccess
} from './actions';
import { AuthActionTypes } from '../auth/types';
import { getToken, isTokenRefreshing } from '../auth/selectors';
import { CONTENT_TYPES } from '../../utils/api';

function handleShowError(action: { [key: string]: any }) {
  const { code } = action.payload;
  const { message: errorMessage } = action.payload.error;
  message.error(`${code} ${errorMessage}`);
}

function* getAuthToken() {
  const isRefreshing = yield select(isTokenRefreshing);
  if (isRefreshing) {
    const { fail } = yield race({
      success: take(AuthActionTypes.TOKEN_REFRESH_SUCCESS),
      fail: take(AuthActionTypes.TOKEN_REFRESH_ERROR),
    });
    if (fail) {
      return null;
    }
  }
  return yield select(getToken);
}


function* handleGetFreeExercisesRequest() {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(callApi, 'get', `admin/dictionaries/free_exercises`, token);
  if (response.error) {
    yield put(getFreeExercisesError(response));
  } else {
    yield put(getFreeExercisesSuccess(response));
  }
}

function* handleUploadFreeExerciseImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { freeExerciseId } = data;
  const response = yield call(
    callApi,
    'post',
    `admin/dictionaries/free_exercises/${freeExerciseId}/videoPreview`,
    token,
    { videoPreview: data.videoPreview, videoPreview_eng: data.videoPreview_eng },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}


function* createFreeExerciseRequest(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'post',
    `admin/dictionaries/free_exercises`,
    token,
    { ...values },
  );

  if (response.error) {
    yield put(editFreeExerciseError(response));
  } else {
    const freeExerciseId = response.data.id;
    yield handleUploadFreeExerciseImage({
      token,
      data: { ...values, freeExerciseId },
    });
    yield put(editFreeExerciseSuccess());
    yield put(getFreeExercises());
    values.callback();
  }
}

function* editFreeExerciseRequest(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'put',
    `admin/dictionaries/free_exercises/${values.id}`,
    token,
    { ...values },
  );

  if (response.error) {
    yield put(editFreeExerciseError(response));
  } else {
    if ((values.videoPreview && typeof values.videoPreview !== 'string') || (values.videoPreview_eng && typeof values.videoPreview_eng !== 'string')) {
      yield handleUploadFreeExerciseImage({
        token,
        data: { ...values, freeExerciseId: values.id },
      });
    }
    yield put(editFreeExerciseSuccess());
    yield put(getFreeExercises());
    if (values.callback) {
      values.callback();
    }
  }
}

function* handleEditFreePlanRequest(
  action: ReturnType<typeof editFreeExercise>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield editFreeExerciseRequest(token, action.payload);
  } else {
    yield createFreeExerciseRequest(token, action.payload);
  }
}

function* handleDeleteFreeExerciseRequest(
  action: ReturnType<typeof deleteFreeExercise>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const { freeExerciseId } = action.payload;
  const response = yield call(
    callApi,
    'delete',
    `admin/dictionaries/free_exercises/${freeExerciseId}`,
    token,
  );
  if (response.error) {
    yield put(deleteFreeExerciseError(response, freeExerciseId));
  } else {
    yield put(deleteFreeExerciseSuccess({ freeExerciseId }));
    yield put(getFreeExercises());
  }
}

function* watchGetFreePlansRequest() {
  yield takeLatest(
    IFreeExercisesActionsTypes.GET_FREE_EXERCISE_REQUEST,
    handleGetFreeExercisesRequest,
  );
}

function* watchEditFreePlanRequest() {
  yield takeLatest(
    IFreeExercisesActionsTypes.EDIT_FREE_EXERCISE_REQUEST,
    handleEditFreePlanRequest,
  );
}

function* watchDeleteFreePlanRequest() {
  yield takeLatest(
    IFreeExercisesActionsTypes.DELETE_FREE_EXERCISE_REQUEST,
    handleDeleteFreeExerciseRequest,
  );
}


function* watchRequestError() {
  yield takeLatest(
    [
      IFreeExercisesActionsTypes.GET_FREE_EXERCISE_ERROR,
      IFreeExercisesActionsTypes.EDIT_FREE_EXERCISE_ERROR,
      IFreeExercisesActionsTypes.DELETE_FREE_EXERCISE_ERROR,
    ],
    handleShowError,
  );
}

function* FreeExercisesSaga() {
  yield all([
    fork(watchGetFreePlansRequest),
    fork(watchEditFreePlanRequest),
    fork(watchDeleteFreePlanRequest),
    fork(watchRequestError),
  ]);
}

export default FreeExercisesSaga;
