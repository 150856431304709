import * as React from 'react'
import * as ReactDOM from 'react-dom'
import history from './utils/history'
import Main from './main'
import * as serviceWorker from './serviceWorker'
import configureStore from './configureStore'

import './styles'


const initialState = window.initialReduxState
const store = configureStore(history, initialState)

ReactDOM.render(<Main store={store} history={history} />, document.getElementById('root'))

serviceWorker.unregister()
