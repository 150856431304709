import React, { Component } from 'react';
import { Form, Input, Upload, Icon, Modal, Checkbox, InputNumber, Tabs } from 'antd';
import { get } from 'lodash';
import {
  required,
  NOT_BLANK,
  IS_PICTURE,
  rangeValue,
  maxLength,
} from '../../../../constants/validationRules';
import { FormComponentProps } from 'antd/lib/form';
import Thumbnail from '../../../../components/Thumbnail';
import RequiredLabel from '../../../../components/RequiredLabel';

const { TextArea } = Input;

interface IEditFormState {
  visible: boolean;
  modalContent: JSX.Element | JSX.Element[] | string;
}

interface IEditFormProps extends FormComponentProps {
  data?: { [key: string]: any };
}

class EditForm extends Component<IEditFormProps, IEditFormState> {
  constructor(props: IEditFormProps) {
    super(props);
    this.state = {
      visible: false,
      modalContent: '',
    };
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.handleChangeImageEng = this.handleChangeImageEng.bind(this);
    this.onRemoveImageEng = this.onRemoveImageEng.bind(this);
    this.createOnPreview = this.createOnPreview.bind(this);
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;

    const id = getFieldDecorator(`id`, {
      initialValue: get(this.props.data, 'id', ''),
    });

    const name = getFieldDecorator(`name`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(20)],
      initialValue: get(this.props.data, 'name', ''),
    })(<Input placeholder="Название" />);

    const name_eng = getFieldDecorator(`name_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(20)],
      initialValue: get(this.props.data, 'name_eng', ''),
    })(<Input placeholder="Title" />);

    const isPublish = getFieldDecorator(`isPublish`, {
      initialValue: get(this.props.data, 'isPublish', true),
      valuePropName: 'checked',
    })(<Checkbox>Опубликовать</Checkbox>);

    const sort = getFieldDecorator(`sort`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, rangeValue(1, 99)],
      initialValue: get(this.props.data, 'sort', ''),
    })(<InputNumber placeholder="Приоритет" style={{ width: '100%' }} />);

    const imageSrc = getFieldDecorator(`imageSrc`, {
      initialValue: get(this.props.data, 'image'),
    })(<></>);

    const imageSrcEng = getFieldDecorator(`imageSrcEng`, {
      initialValue: get(this.props.data, 'image_eng'),
    })(<></>);

    const imageRules = this.props.form.getFieldValue('imageSrc')
      ? []
      : [IS_PICTURE];

    const imageRulesEng = this.props.form.getFieldValue('imageSrcEng')
      ? []
      : [IS_PICTURE];

    const image = getFieldDecorator(`image`, {
      rules: imageRules,
      initialValue: null,
    })(<></>);

    const image_eng = getFieldDecorator(`image_eng`, {
      rules: imageRulesEng,
      initialValue: null,
    })(<></>);

    const description = getFieldDecorator(`description`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK],
      initialValue: get(this.props.data, 'description', ''),
    })(<TextArea rows={4} placeholder="Описание" />);

    const description_eng = getFieldDecorator(`description_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK],
      initialValue: get(this.props.data, 'description_eng', ''),
    })(<TextArea rows={4} placeholder="Description" />);

    return {
      id,
      name,
      name_eng,
      sort,
      isPublish,
      image,
      image_eng,
      imageSrc,
      imageSrcEng,
      description,
      description_eng,
    };
  }

  handleChangeImage(file: { [key: string]: any }) {
    this.props.form.setFieldsValue({ image: file });
    this.props.form.validateFields(['image']);
    return false;
  }
  handleChangeImageEng(file: { [key: string]: any }) {
    this.props.form.setFieldsValue({ image_eng: file });
    this.props.form.validateFields(['image_eng']);
    return false;
  }

  onRemoveImage() {
    this.props.form.setFieldsValue({ image: null });
    this.props.form.validateFields(['image']);
    return true;
  }
  onRemoveImageEng() {
    this.props.form.setFieldsValue({ image_eng: null });
    this.props.form.validateFields(['image_eng']);
    return true;
  }

  createOnPreview(fieldName: string) {
    return (file: { [key: string]: any }) => {
      const { form } = this.props;
      const errorMessages = form.getFieldError(fieldName);
      const formatErrorMessage:string = 'Файл должен быть картинкой';
      if(errorMessages && errorMessages.length && errorMessages[0] === formatErrorMessage) {
        return false;
      }
      const modalContent = <img src={file.thumbUrl} />;
      this.setState({ modalContent, visible: true });
    }
  }

  render() {
    const createUploadButton = (fieldName: string) => {
      return this.props.form.getFieldValue(fieldName) ? null : (
        <>
          <Icon type={'plus'} />
          <div className="ant-upload-text">Загрузить</div>
        </>
      );
    }
    const uploadButton = createUploadButton('image');
    const uploadButtonEng = createUploadButton('image_eng');

    const imageHref = this.fields.imageSrc.props.value;
    const imageHrefEng = this.fields.imageSrcEng.props.value;

    const onRemoveThumbnail = () =>
      this.props.form.setFieldsValue({ imageSrc: null });
    const onRemoveThumbnailEng = () =>
      this.props.form.setFieldsValue({ imageSrcEng: null });

    const onPreviewThumbnail = () => {
      const modalContent = <img src={imageHref} />;
      this.setState({ modalContent, visible: true });
    };
    const onPreviewThumbnailEng = () => {
      const modalContent = <img src={imageHrefEng} />;
      this.setState({ modalContent, visible: true });
    };

    const onPreview = this.createOnPreview('image');
    const onPreviewEng = this.createOnPreview('image_eng');

    const thumbnail = imageHref ? (
      <Thumbnail
        src={imageHref}
        onRemove={onRemoveThumbnail}
        onPreview={onPreviewThumbnail}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={this.handleChangeImage}
          onRemove={this.onRemoveImage}
          onPreview={onPreview}
        >
          {uploadButton}
        </Upload>
        {this.fields.image}
      </>
    );

    const thumbnailEng = imageHrefEng ? (
      <Thumbnail
        src={imageHrefEng}
        onRemove={onRemoveThumbnailEng}
        onPreview={onPreviewThumbnailEng}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={this.handleChangeImageEng}
          onRemove={this.onRemoveImageEng}
          onPreview={onPreviewEng}
        >
          {uploadButtonEng}
        </Upload>
        {this.fields.image_eng}
      </>
    );

    return (
      <Form>
        <Tabs defaultActiveKey="1" centered>
          <Tabs.TabPane tab="Общие настройки" key="1">
            <Form.Item label="Название">{this.fields.name}</Form.Item>
            <Form.Item label="Описание">{this.fields.description}</Form.Item>
            <Form.Item label="Приоритет">{this.fields.sort}</Form.Item>
            <Form.Item>{this.fields.isPublish}</Form.Item>
            <Form.Item label={<RequiredLabel text="Изображение" />}>
              {thumbnail}
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Английский вариант" key="2">
            <Form.Item label="Title">{this.fields.name_eng}</Form.Item>
            <Form.Item label="Description">{this.fields.description_eng}</Form.Item>
            <Form.Item label={<RequiredLabel text="Image" />}>
              {thumbnailEng}
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
        <Modal
          footer={null}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
        >
          {this.state.modalContent}
        </Modal>
      </Form>
    );
  }
}

export default EditForm;
