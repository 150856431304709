import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { IFoodActionsTypes } from './types';
import { callApi } from '../../utils/api';
import { message } from 'antd';
import {
  editPortions,
  editPortionsError,
  editPortionsSuccess,
  getPortions,
  getPortionsError,
  getPortionsSuccess,
  deletePortion,
  deletePortionError,
  deletePortionSuccess,
  getPortionItem,
  getPortionItemError,
  getPortionItemSuccess,
} from './actions';
import { pick } from 'lodash';
import { AuthActionTypes } from '../auth/types';
import { getToken, isTokenRefreshing } from '../auth/selectors';
import { CONTENT_TYPES } from '../../utils/api';

function* getAuthToken() {
  const isRefreshing = yield select(isTokenRefreshing);
  if (isRefreshing) {
    const { fail } = yield race({
      success: take(AuthActionTypes.TOKEN_REFRESH_SUCCESS),
      fail: take(AuthActionTypes.TOKEN_REFRESH_ERROR),
    });
    if (fail) {
      return null;
    }
  }
  return yield select(getToken);
}

function* handleGetPortionsRequest(action: ReturnType<typeof getPortions>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const { rationId } = action.payload;
  const response = yield call(
    callApi,
    'get',
    `admin/rations/${rationId}/portions`,
    token,
  );
  if (response.error) {
    yield put(getPortionsError(response));
  } else {
    yield put(getPortionsSuccess(response));
  }
}

function* handleGetPortionItemRequest(
  action: ReturnType<typeof getPortionItem>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const { portionId } = action.payload;
  const response = yield call(
    callApi,
    'get',
    `admin/portions/${portionId}`,
    token,
  );
  if (response.error) {
    yield put(getPortionItemError(response));
  } else {
    yield put(getPortionItemSuccess(response));
  }
}

function* handleUploadPortionsImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { id } = data;
  const response = yield call(
    callApi,
    'post',
    `admin/portions/${id}/image`,
    token,
    { image: data.image },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* createPortions(token: string, values: { [key: string]: any }) {
  const updValues = pick(values, ['name', 'isPublish', 'sort']);

  const response = yield call(
    callApi,
    'post',
    `admin/rations/${values.rationId}/portions`,
    token,
    updValues,
  );

  if (response.error) {
    yield put(editPortionsError(response));
  } else {
    const id = response.data.id;
    const { rationId } = values;
    yield handleUploadPortionsImage({
      token,
      data: { ...values, id },
    });
    yield put(editPortionsSuccess(response));
    yield put(getPortions({ rationId }));
    values.callback();
  }
}

function* updatePortions(token: string, values: { [key: string]: any }) {
  const { id, rationId } = values;
  const updValues = pick(values, ['name', 'isPublish', 'sort']);

  const response = yield call(
    callApi,
    'put',
    `admin/portions/${id}`,
    token,
    updValues,
  );

  if (response.error) {
    yield put(editPortionsError(response));
  } else {
    if (values.image) {
      yield handleUploadPortionsImage({
        token,
        data: { ...values },
      });
    }
    yield put(editPortionsSuccess(response));
    yield put(getPortions({ rationId }));
    values.callback();
  }
}

function* handleEditPortionsRequest(action: ReturnType<typeof editPortions>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }

  if (action.payload.id) {
    yield updatePortions(token, action.payload);
  } else {
    yield createPortions(token, action.payload);
  }
}

function* handleDeletePortionRequest(action: ReturnType<typeof deletePortion>) {
  const { portionId, rationId } = action.payload;
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'delete',
    `admin/portions/${portionId}`,
    token,
  );

  if (response.error) {
    yield put(deletePortionError(response, portionId));
  } else {
    yield put(deletePortionSuccess({ portionId }));
    yield put(getPortions({ rationId }));
  }
}

export function* watchEditPortionsRequest() {
  yield takeLatest(
    IFoodActionsTypes.EDIT_PORTIONS_REQUEST,
    handleEditPortionsRequest,
  );
}

export function* watchGetPortionsRequest() {
  yield takeLatest(
    IFoodActionsTypes.GET_PORTIONS_REQUEST,
    handleGetPortionsRequest,
  );
}

export function* watchGetPortionItemRequest() {
  yield takeLatest(
    IFoodActionsTypes.GET_PORTION_ITEM_REQUEST,
    handleGetPortionItemRequest,
  );
}

export function* watchDeletePortionRequest() {
  yield takeLatest(
    IFoodActionsTypes.DELETE_PORTION_REQUEST,
    handleDeletePortionRequest,
  );
}
