import history from '../utils/history';

export interface ApiResponse<T> {
  error?: {
    code: number;
    error: string | number;
    message: string;
  };
  data?: T;
  code: number;
}

export enum CONTENT_TYPES {
  JSON = 'application/json',
  MPFD = 'multipart/form-data',
}

const getBody = (data: any, type: CONTENT_TYPES) => {
  if (!data) return data;
  switch (type) {
    case CONTENT_TYPES.JSON:
      return JSON.stringify(data);
    case CONTENT_TYPES.MPFD:
      const formData = new FormData();

      for (let name in data) {
        if (typeof data[name] !== 'undefined') {
          switch (true) {
            case Array.isArray(data[name]):
              formData.append(name, JSON.stringify(data[name]));
              break;
            default:
              formData.append(name, data[name]);
          }
        }
      }
      return formData;
    default:
      return data;
  }
};

export async function callApi<T>(
  method: string,
  path: string,
  token: string | null,
  data?: any,
  type = CONTENT_TYPES.JSON,
): Promise<ApiResponse<T>> {
  const res = await fetch(`/api/${path}`, {
    method,
    headers: {
      Accept: 'application/json',
      ...(type !== CONTENT_TYPES.MPFD && { 'Content-Type': type }),
      ...(token && { Authorization: `bearer ${token}` }),
    },
    body: getBody(data, type),
  });

  try {
    switch (true) {
      case res.status >= 200 && res.status < 300:
        try {
          return { data: await res.json(), code: res.status };
        } catch (error) {
          return { code: res.status };
        }

      case res.status === 401:
        localStorage.removeItem('@@user/token');
        history.push('/login');

      default:
        return { error: await res.json(), code: res.status };
    }
  } catch (error) {
    return { error: error, code: res.status };
  }
}
