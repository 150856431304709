import React from 'react';
import { Spin } from 'antd';
import { Title } from '../../constants/styles';
import { goToPath } from '../../services/navigator.service';
import { callApi } from '../../utils/api';

interface IFreeBreadcrumbsProps {
  type: 'plans' | 'weeks' | 'days' | 'trainings' | 'courses';
  id: number;
}

interface IFreeBreadcrumbsState {
  breadcrumbs: any[];
  loading: boolean;
  error: boolean;
}

class FreeBreadcrumbs extends React.Component<IFreeBreadcrumbsProps, IFreeBreadcrumbsState> {
  constructor(props: IFreeBreadcrumbsProps) {
    super(props);
    this.state = {
      breadcrumbs: [],
      loading: false,
      error: false,
    }
  }

  getUrl = (type: IFreeBreadcrumbsProps['type'], id: number) => {
    switch(type) {
      case 'courses': return `admin/breadcrumbs/free_courses`;
      case 'plans': return `admin/breadcrumbs/free_courses/${id}/plans`;
      case 'weeks': return `admin/breadcrumbs/free_plans/${id}/weeks`;
      case 'days': return `admin/breadcrumbs/free_weeks/${id}/days`;
      case 'trainings': return `admin/breadcrumbs/free_days/${id}/trainings`;
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const token = JSON.parse(localStorage.getItem('@@user/token')).token;
    const response = await callApi(
      'get',
      this.getUrl(this.props.type, this.props.id),
      token
    );
    if (response.error) {
      this.setState({ error: true, loading: false });
    } else {
      this.setState({ breadcrumbs: response.data as any[], loading: false });
    }
  }

  render() {
    if (this.state.loading) return <Spin spinning />;
    if (this.state.error) return <Title>Ошибка загрузки хлебных крошек</Title>;

    return (
      <Title>
        {
          this.state.breadcrumbs.map((item, i) => {
            return (
              <>
                { i === 0 ? '' : ' ' }
                {
                  i === this.state.breadcrumbs.length - 1 ? (
                    <b>
                      {item.title}
                    </b>
                  ) : (
                    <>
                      <span onClick={goToPath.bind(null, item.link.replace('/api/admin', ''))}>
                        {item.title}
                      </span>
                      {' -'}
                    </>
                  )
                }
              </>
            )
          })
        }
      </Title>
    )
  }
}

export default FreeBreadcrumbs;