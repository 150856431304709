export enum IFreeCoursesActionsTypes {
  // COURSES
  GET_FREE_COURSES_REQUEST = '@@FreeCourses/GET_FREE_COURSES_REQUEST',
  GET_FREE_COURSES_SUCCESS = '@@FreeCourses/GET_FREE_COURSES_SUCCESS',
  GET_FREE_COURSES_ERROR = '@@FreeCourses/GET_FREE_COURSES_ERROR',
  EDIT_FREE_COURSES_REQUEST = '@@FreeCourses/EDIT_FREE_COURSES_REQUEST',
  EDIT_FREE_COURSES_SUCCESS = '@@FreeCourses/EDIT_FREE_COURSES_SUCCESS',
  EDIT_FREE_COURSES_ERROR = '@@FreeCourses/EDIT_FREE_COURSES_ERROR',
  // PLANS
  GET_FREE_PLANS_REQUEST = '@@FreeCourses/GET_FREE_PLANS_REQUEST',
  GET_FREE_PLANS_SUCCESS = '@@FreeCourses/GET_FREE_PLANS_SUCCESS',
  GET_FREE_PLANS_ERROR = '@@FreeCourses/GET_FREE_PLANS_ERROR',
  EDIT_FREE_PLAN_REQUEST = '@@FreeCourses/EDIT_FREE_PLAN_REQUEST',
  EDIT_FREE_PLAN_SUCCESS = '@@FreeCourses/EDIT_FREE_PLAN_SUCCESS',
  EDIT_FREE_PLAN_ERROR = '@@FreeCourses/EDIT_FREE_PLAN_ERROR',
  DELETE_FREE_PLAN_REQUEST = '@@FreeCourses/DELETE_FREE_PLAN_REQUEST',
  DELETE_FREE_PLAN_SUCCESS = '@@FreeCourses/DELETE_FREE_PLAN_SUCCESS',
  DELETE_FREE_PLAN_ERROR = '@@FreeCourses/DELETE_FREE_PLAN_ERROR',
  // WEEKS
  GET_FREE_COURSES_WEEKS_REQUEST = '@@FreeCourses/GET_FREE_COURSES_WEEKS_REQUEST',
  GET_FREE_COURSES_WEEKS_SUCCESS = '@@FreeCourses/GET_FREE_COURSES_WEEKS_SUCCESS',
  GET_FREE_COURSES_WEEKS_ERROR = '@@FreeCourses/GET_FREE_COURSES_WEEKS_ERROR',
  EDIT_FREE_COURSES_WEEK_REQUEST = '@@FreeCourses/EDIT_FREE_COURSES_WEEK_REQUEST',
  EDIT_FREE_COURSES_WEEK_SUCCESS = '@@FreeCourses/EDIT_FREE_COURSES_WEEK_SUCCESS',
  EDIT_FREE_COURSES_WEEK_ERROR = '@@FreeCourses/EDIT_FREE_COURSES_WEEK_ERROR',
  DELETE_FREE_COURSES_WEEK_REQUEST = '@@FreeCourses/DELETE_FREE_COURSES_WEEK_REQUEST',
  DELETE_FREE_COURSES_WEEK_SUCCESS = '@@FreeCourses/DELETE_FREE_COURSES_WEEK_SUCCESS',
  DELETE_FREE_COURSES_WEEK_ERROR = '@@FreeCourses/DELETE_FREE_COURSES_WEEK_ERROR',
  // DAYS
  GET_FREE_DAYS_REQUEST = '@@FreeCourses/GET_FREE_DAYS_REQUEST',
  GET_FREE_DAYS_SUCCESS = '@@FreeCourses/GET_FREE_DAYS_SUCCESS',
  GET_FREE_DAYS_ERROR = '@@FreeCourses/GET_FREE_DAYS_ERROR',
  EDIT_FREE_DAYS_REQUEST = '@@FreeCourses/EDIT_FREE_DAYS_REQUEST',
  EDIT_FREE_DAYS_SUCCESS = '@@FreeCourses/EDIT_FREE_DAYS_SUCCESS',
  EDIT_FREE_DAYS_ERROR = '@@FreeCourses/EDIT_FREE_DAYS_ERROR',
  DELETE_FREE_DAYS_REQUEST = '@@FreeCourses/DELETE_FREE_DAYS_REQUEST',
  DELETE_FREE_DAYS_SUCCESS = '@@FreeCourses/DELETE_FREE_DAYS_SUCCESS',
  DELETE_FREE_DAYS_ERROR = '@@FreeCourses/DELETE_FREE_DAYS_ERROR',
  // TRAININGS
  GET_FREE_TRAININGS_REQUEST = '@@FreeCourses/GET_FREE_TRAININGS_REQUEST',
  GET_FREE_TRAININGS_SUCCESS = '@@FreeCourses/GET_FREE_TRAININGS_SUCCESS',
  GET_FREE_TRAININGS_ERROR = '@@FreeCourses/GET_FREE_TRAININGS_ERROR',
  EDIT_FREE_TRAINING_REQUEST = '@@FreeCourses/EDIT_FREE_TRAINING_REQUEST',
  EDIT_FREE_TRAINING_SUCCESS = '@@FreeCourses/EDIT_FREE_TRAINING_SUCCESS',
  EDIT_FREE_TRAINING_ERROR = '@@FreeCourses/EDIT_FREE_TRAINING_ERROR',
  DELETE_FREE_TRAINING_REQUEST = '@@FreeCourses/DELETE_FREE_TRAINING_REQUEST',
  DELETE_FREE_TRAINING_SUCCESS = '@@FreeCourses/DELETE_FREE_TRAINING_SUCCESS',
  DELETE_FREE_TRAINING_ERROR = '@@FreeCourses/DELETE_FREE_TRAINING_ERROR',
}

export interface IFreeCoursesState {
  data: any;
  loaders: { [key: string]: boolean };
}
