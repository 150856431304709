interface IRouteMap {
  [route: string]: IRouteMapItem;
}

const routeMap: IRouteMap = {
  root: {
    path: '/',
    name: 'root',
  },
  exercises: {
    name: 'Упражнения',
    path: '/exercises',
    includeInMenu: true,
    icon: 'snippets',
  },
  exercisesDetail: {
    name: 'Уровни упражнения',
    path: '/exercises/:exerciseId/levels',
    getPath: params =>
      routeMap.exercisesDetail.path.replace(':exerciseId', params.exerciseId),
  },
  workouts: {
    name: 'Тренировки',
    path: '/workouts',
    includeInMenu: true,
    icon: 'rocket',
  },
  workoutsPlan: {
    name: 'Планы тренировок',
    path: `/workouts/:workoutId/plans`,
    getPath: params =>
      routeMap.workoutsPlan.path.replace(':workoutId', params.workoutId),
  },
  workoutsWeeks: {
    name: 'Недели тренировок',
    path: '/workouts/:workoutId/plans/:workoutsPlanId/weeks',
    getPath: params =>
      routeMap.workoutsWeeks.path
        .replace(':workoutsPlanId', params.workoutsPlanId)
        .replace(':workoutId', params.workoutId),
  },
  motivationCategory: {
    name: 'Мотивации',
    path: '/motivation',
    includeInMenu: true,
    icon: 'video-camera',
  },
  motivation: {
    name: 'Мотивации',
    path: '/motivation/:motivationCategoryId/videos',
    getPath: params =>
      routeMap.motivation.path.replace(
        ':motivationCategoryId',
        params.motivationCategoryId,
      ),
  },
  rations: {
    name: 'Питание',
    path: '/rations',
    includeInMenu: true,
    icon: 'coffee',
  },
  portions: {
    name: 'Приемы пищи',
    path: '/rations/:rationId/portions',
    getPath: params =>
      routeMap.portions.path.replace(':rationId', params.rationId),
  },
  meals: {
    name: 'Блюда',
    path: '/rations/:rationId/portions/:portionId/meals',
    getPath: params =>
      routeMap.meals.path
        .replace(':rationId', params.rationId)
        .replace(':portionId', params.portionId),
  },
  freeCourses: {
    name: 'Бесплатные курсы',
    path: '/free_courses',
    includeInMenu: true,
    icon: 'rocket',
  },
  freeCoursesPlan: {
    name: 'Планы тренировок',
    path: `/free_courses/:freeCoursesId/plans`,
    getPath: params =>
      routeMap.freeCoursesPlan.path.replace(':freeCoursesId', params.freeCoursesId),
  },
  freeCoursesWeeks: {
    name: 'Недели тренировок',
    path: `/free_plans/:freePlanId/weeks`,
    getPath: params =>
      routeMap.freeCoursesWeeks.path.replace(':freePlanId', params.freePlanId),
  },
  freeCoursesDays: {
    name: 'Дни тренировок',
    path: `/free_weeks/:freeWeekId/days`,
    getPath: params =>
      routeMap.freeCoursesDays.path.replace(':freeWeekId', params.freeWeekId),
  },
  freeTrainings: {
    name: 'Бесплатные тренировки',
    path: `/free_days/:freeDayId/trainings`,
    getPath: params =>
      routeMap.freeTrainings.path.replace(':freeDayId', params.freeDayId),
  },
  freeExercises: {
    name: 'Бесплатные упражнения',
    path: '/free_exercises',
    includeInMenu: true,
    icon: 'snippets',
  },
  trainingPinnedVideo: {
    name: 'Входное видео',
    path: '/training-pinned-video',
    includeInMenu: true,
    icon: 'video-camera',
  },
};

export interface IRouteMapItem {
  path: string;
  name?: string;
  icon?: string;
  includeInMenu?: boolean;
  getPath?: (params: { [key: string]: string }) => string;
}

export default routeMap;
