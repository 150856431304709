import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { IFreeCoursesActionsTypes } from '../types';
import { callApi } from '../../../utils/api';
import { CONTENT_TYPES } from '../../../utils/api';
import { getAuthToken } from '../../utils';
import {
  getFreeCourses,
  getFreeCoursesError,
  getFreeCoursesSuccess,
  editFreeCourses,
  editFreeCoursesSuccess,
  editFreeCoursesError,
} from '../actions';


function* handleGetFreeCoursesRequest() {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(callApi, 'get', 'admin/free_courses', token);
  if (response.error) {
    yield put(getFreeCoursesError(response));
  } else {
    yield put(getFreeCoursesSuccess(response));
  }
}

function* handleEditFreeCoursesRequest(
  action: ReturnType<typeof editFreeCourses>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const values = action.payload;
  const freeCoursesId = values.id;
  const response = yield call(
    callApi,
    'put',
    `admin/free_courses/${freeCoursesId}`,
    token,
    { ...values },
  );

  function* successCallback() {
    yield put(editFreeCoursesSuccess());
    yield put(getFreeCourses());
    if (values.callback) {
      values.callback();
    };
  }

  if (response.error) {
    yield put(editFreeCoursesError(response));
  } else {
    if ((values.image && typeof values.image !== 'string') || (values.image_eng && typeof values.image_eng !== 'string')) {
      const response = yield call(
        callApi,
        'post',
        `admin/free_courses/${freeCoursesId}/image`,
        token,
        { image: values.image, image_eng: values.image_eng },
        CONTENT_TYPES.MPFD,
      );
      if (response.error) {
        yield put(editFreeCoursesError(response));
      } else {
       yield successCallback();
      }
    } else {
      yield successCallback();
    }
  }
}

export function* watchGetFreeCoursesRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.GET_FREE_COURSES_REQUEST,
    handleGetFreeCoursesRequest,
  );
}

export function* watchEditFreeCoursesRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.EDIT_FREE_COURSES_REQUEST,
    handleEditFreeCoursesRequest,
  );
}