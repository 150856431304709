import React, { Component } from 'react';
import { Form, Modal, InputNumber, Select } from 'antd';
import { get } from 'lodash';
import { IFreeExercisesState } from '../../../../store/free-exercise/types';
import {
  required,
  NOT_BLANK,
  rangeValue,
} from '../../../../constants/validationRules';
import { FormComponentProps } from 'antd/lib/form';

interface IEditFormState {
  visible: boolean;
  modalContent: JSX.Element | JSX.Element[] | string;
}

interface IEditFormProps extends FormComponentProps {
  data?: { [key: string]: any };
  free_exercises: IFreeExercisesState;
}

const Option = Select.Option;

class EditForm extends Component<IEditFormProps, IEditFormState> {
  constructor(props: IEditFormProps) {
    super(props);
    this.state = {
      visible: false,
      modalContent: '',
    };
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;
    const exerciseOptions = this.props.free_exercises.data
      ? this.props.free_exercises.data.map((exercise: { [key: string]: any }) => (
        <Option key={exercise.id} value={exercise.id}>{exercise.name}</Option>
      ))
      : [];

    const id = getFieldDecorator(`id`, {
      initialValue: get(this.props.data, 'id', null),
    });

    const exercise = getFieldDecorator(`exercise`,
      {
        validateTrigger: 'onSubmit',
        rules: [required, NOT_BLANK],
        initialValue:
          get(this.props.data, 'exercise.id'),
      },
    )(
      <Select placeholder="Выберите упражнение" loading={this.props.free_exercises.loaders.getFreeExercise}>
        {exerciseOptions}
      </Select>,
    );

    const sort = getFieldDecorator(`sort`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, rangeValue(0, 99)],
      initialValue: get(this.props.data, 'sort', ''),
    })(<InputNumber placeholder="Приоритет" style={{ width: '100%' }} />);

    return {
      id,
      sort,
      exercise,
    };
  }

  render() {
    return (
      <Form>
        <Form.Item label="Приоритет">{this.fields.sort}</Form.Item>
        <Form.Item label="Упражнение">{this.fields.exercise}</Form.Item>
        <Modal
          footer={null}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
        >
          {this.state.modalContent}
        </Modal>
      </Form>
    );
  }
}

export default EditForm;
