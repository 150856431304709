import React, { Component } from 'react';
import { get } from 'lodash';
import { Form, Input, Upload, Icon, Modal, Tabs} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  required,
  NOT_BLANK,
  IS_PICTURE,
  maxLength,
} from '../../../../constants/validationRules';
import Thumbnail from '../../../../components/Thumbnail';
import RequiredLabel from '../../../../components/RequiredLabel';

interface IExerciseDetailFormProps extends FormComponentProps {
  data: { [key: string]: any };
}

interface IExerciseDetailFormState {
  visible: boolean;
  modalContent: JSX.Element | JSX.Element[] | string;
}

class ExerciseDetailForm extends Component<
  IExerciseDetailFormProps,
  IExerciseDetailFormState
> {
  constructor(props: IExerciseDetailFormProps) {
    super(props);
    this.state = {
      visible: false,
      modalContent: '',
    };
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.handleChangeImageEng = this.handleChangeImageEng.bind(this);
    this.onRemoveImageEng = this.onRemoveImageEng.bind(this);
    this.createOnPreview = this.createOnPreview.bind(this);
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;

    const name = getFieldDecorator(`name`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(26)],
      initialValue: get(this.props.data, 'name', ''),
    })(<Input placeholder="Название" />);

    const name_eng = getFieldDecorator(`name_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(26)],
      initialValue: get(this.props.data, 'name_eng', ''),
    })(<Input placeholder="Title" />);

    const video = getFieldDecorator(`video`, {
      validateTrigger: 'onSubmit',
      rules: [required],
      initialValue: get(this.props.data, 'video', ''),
    })(<Input placeholder="Ссылка на видео" />);

    const video_eng = getFieldDecorator(`video_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required],
      initialValue: get(this.props.data, 'video_eng', ''),
    })(<Input placeholder="Link to the video" />);

    const imageSrc = getFieldDecorator(`imageSrc`, {
      initialValue: get(this.props.data, 'videoPreview'),
    })(<></>);

    const imageSrcEng = getFieldDecorator(`imageSrcEng`, {
      initialValue: get(this.props.data, 'videoPreview_eng'),
    })(<></>);

    const imageRules = this.props.form.getFieldValue('imageSrc')
      ? []
      : [IS_PICTURE];

    const imageRulesEng = this.props.form.getFieldValue('imageSrcEng')
      ? []
      : [IS_PICTURE];

    const videoPreview = getFieldDecorator(`videoPreview`, {
      validateTrigger: 'onSubmit',
      rules: imageRules,
      initialValue: null,
    })(<></>);

    const videoPreview_eng = getFieldDecorator(`videoPreview_eng`, {
      validateTrigger: 'onSubmit',
      rules: imageRulesEng,
      initialValue: null,
    })(<></>);

    const id = getFieldDecorator(`id`, {
      initialValue: get(this.props.data, 'id', null),
    })(<></>);

    return {
      id,
      name,
      name_eng,
      video,
      video_eng,
      imageSrc,
      imageSrcEng,
      videoPreview,
      videoPreview_eng,
    };
  }

  handleChangeImage(file: { [key: string]: any }) {
    this.props.form.setFieldsValue({ videoPreview: file });
    this.props.form.validateFields(['videoPreview']);
    return false;
  }

  handleChangeImageEng(file: { [key: string]: any }) {
    this.props.form.setFieldsValue({ videoPreview_eng: file });
    this.props.form.validateFields(['videoPreview_eng']);
    return false;
  }

  onRemoveImage() {
    this.props.form.setFieldsValue({ videoPreview: null });
    this.props.form.validateFields(['videoPreview']);
    return true;
  }

  onRemoveImageEng() {
    this.props.form.setFieldsValue({ videoPreview_eng: null });
    this.props.form.validateFields(['videoPreview_eng']);
    return true;
  }

  createOnPreview(fieldName: string) {
    return (file: { [key: string]: any }) => {
      const { form } = this.props;
      const errorMessages = form.getFieldError(fieldName);
      const formatErrorMessage: string = 'Файл должен быть картинкой';
      if (
        errorMessages &&
        errorMessages.length &&
        errorMessages[0] === formatErrorMessage
      ) {
        return false;
      }
      const modalContent = <img src={file.thumbUrl} />;
      this.setState({ modalContent, visible: true });
    }
  }

  render() {
    const createUploadButton = (fieldName: string) => {
      return this.props.form.getFieldValue(fieldName) ? null : (
        <>
          <Icon type={'plus'} />
          <div className="ant-upload-text">Загрузить</div>
        </>
      );
    }
    const uploadButton = createUploadButton('videoPreview');
    const uploadButtonEng = createUploadButton('videoPreview_eng');

    const imageHref = this.fields.imageSrc.props.value;
    const imageHrefEng = this.fields.imageSrcEng.props.value;

    const onRemoveThumbnail = () =>
      this.props.form.setFieldsValue({ imageSrc: null });
    const onRemoveThumbnailEng = () =>
      this.props.form.setFieldsValue({ imageSrcEng: null });

    const onPreviewThumbnail = () => {
      const modalContent = <img src={imageHref} />;
      this.setState({ modalContent, visible: true });
    };
    const onPreviewThumbnailEng = () => {
      const modalContent = <img src={imageHrefEng} />;
      this.setState({ modalContent, visible: true });
    };
    const onPreview = this.createOnPreview('videoPreview');
    const onPreviewEng = this.createOnPreview('videoPreview_eng');

    const thumbnail = imageHref ? (
      <Thumbnail
        src={imageHref}
        onRemove={onRemoveThumbnail}
        onPreview={onPreviewThumbnail}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={this.handleChangeImage}
          onRemove={this.onRemoveImage}
          onPreview={onPreview}
        >
          {uploadButton}
        </Upload>
        {this.fields.videoPreview}
      </>
    );

    const thumbnailEng = imageHrefEng ? (
      <Thumbnail
        src={imageHrefEng}
        onRemove={onRemoveThumbnailEng}
        onPreview={onPreviewThumbnailEng}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={this.handleChangeImageEng}
          onRemove={this.onRemoveImageEng}
          onPreview={onPreviewEng}
        >
          {uploadButtonEng}
        </Upload>
        {this.fields.videoPreview_eng}
      </>
    );

    return (
      <Form>
        <Tabs defaultActiveKey="1" centered>
          <Tabs.TabPane tab="Общие настройки" key="1">
            <Form.Item label="Название">{this.fields.name}</Form.Item>
            <Form.Item label={<RequiredLabel text="Превью видео" />}>
              {thumbnail}
            </Form.Item>
            <Form.Item label="Ссылка на видео">{this.fields.video}</Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Английский вариант" key="2">
            <Form.Item label="Title">{this.fields.name_eng}</Form.Item>
            <Form.Item label={<RequiredLabel text="Video Preview" />}>
              {thumbnailEng}
            </Form.Item>
            <Form.Item label="Link to the video">{this.fields.video_eng}</Form.Item>
          </Tabs.TabPane>
        </Tabs>

        <Modal
          footer={null}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
        >
          {this.state.modalContent}
        </Modal>
      </Form>
    );
  }
}

export default ExerciseDetailForm;
