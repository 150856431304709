import { IFreeCoursesActionsTypes, IFreeCoursesState } from './types';
import { Reducer } from 'redux';

const InitialState: IFreeCoursesState = {
  data: null,
  loaders: {},
};

const reducer: Reducer = (state = InitialState, action) => {
  switch (action.type) {
    // get free courses
    case IFreeCoursesActionsTypes.GET_FREE_COURSES_REQUEST:
      return { ...state, loaders: { ...state.loaders, getFreeCourses: true } };
    case IFreeCoursesActionsTypes.GET_FREE_COURSES_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getFreeCourses: false },
        data: action.payload,
      };
    case IFreeCoursesActionsTypes.GET_FREE_COURSES_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getFreeCourses: false },
      };

    // edit free courses
    case IFreeCoursesActionsTypes.EDIT_FREE_COURSES_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editFreeCourses: true },
      };
    case IFreeCoursesActionsTypes.EDIT_FREE_COURSES_SUCCESS:
    case IFreeCoursesActionsTypes.EDIT_FREE_COURSES_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editFreeCourses: false },
      };

    // get free plan
    case IFreeCoursesActionsTypes.GET_FREE_PLANS_REQUEST:
      return { ...state, loaders: { ...state.loaders, getFreePlans: true } };
    case IFreeCoursesActionsTypes.GET_FREE_PLANS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getFreePlans: false },
        data: action.payload,
      };
    case IFreeCoursesActionsTypes.GET_FREE_PLANS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getFreePlans: false },
      };

    // edit free plan
    case IFreeCoursesActionsTypes.EDIT_FREE_PLAN_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editFreePlan: true },
      };
    case IFreeCoursesActionsTypes.EDIT_FREE_PLAN_SUCCESS:
    case IFreeCoursesActionsTypes.EDIT_FREE_PLAN_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editFreePlan: false },
      };

    // delete free plan
    case IFreeCoursesActionsTypes.DELETE_FREE_PLAN_REQUEST:
      return {
        ...state,
        loaders: { 
          ...state.loaders,
          [`delete/${action.payload.freePlanId}`]: true,
        },
      };
    case IFreeCoursesActionsTypes.DELETE_FREE_PLAN_SUCCESS:
    case IFreeCoursesActionsTypes.DELETE_FREE_PLAN_ERROR:
      return {
        ...state,
        loaders: {
          ...state.loaders, 
          [`delete/${action.payload.freePlanId}`]: false,
        },
      };

    // get free courses week
    case IFreeCoursesActionsTypes.GET_FREE_COURSES_WEEKS_REQUEST:
      return { ...state, loaders: { ...state.loaders, getFreeCoursesWeek: true } };
    case IFreeCoursesActionsTypes.GET_FREE_COURSES_WEEKS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getFreeCoursesWeek: false },
        data: action.payload,
      };
    case IFreeCoursesActionsTypes.GET_FREE_COURSES_WEEKS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getFreeCoursesWeek: false },
      };

    // edit free courses week
    case IFreeCoursesActionsTypes.EDIT_FREE_COURSES_WEEK_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editFreeCoursesWeek: true },
      };
    case IFreeCoursesActionsTypes.EDIT_FREE_COURSES_WEEK_SUCCESS:
    case IFreeCoursesActionsTypes.EDIT_FREE_COURSES_WEEK_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editFreeCoursesWeek: false },
      };

    // delete free courses week
    case IFreeCoursesActionsTypes.DELETE_FREE_COURSES_WEEK_REQUEST:
      return {
        ...state,
        loaders: { 
          ...state.loaders,
          [`delete/${action.payload.freeCoursesWeekId}`]: true,
        },
      };
    case IFreeCoursesActionsTypes.DELETE_FREE_COURSES_WEEK_SUCCESS:
    case IFreeCoursesActionsTypes.DELETE_FREE_COURSES_WEEK_ERROR:
      return {
        ...state,
        loaders: {
          ...state.loaders, 
          [`delete/${action.payload.freeCoursesWeekId}`]: false,
        },
      };

    // get free days
    case IFreeCoursesActionsTypes.GET_FREE_DAYS_REQUEST:
      return { ...state, loaders: { ...state.loaders, getFreeDays: true } };
    case IFreeCoursesActionsTypes.GET_FREE_DAYS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getFreeDays: false },
        data: action.payload,
      };
    case IFreeCoursesActionsTypes.GET_FREE_DAYS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getFreeDays: false },
      };

    // edit free day
    case IFreeCoursesActionsTypes.EDIT_FREE_DAYS_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editFreeDay: true },
      };
    case IFreeCoursesActionsTypes.EDIT_FREE_DAYS_SUCCESS:
    case IFreeCoursesActionsTypes.EDIT_FREE_DAYS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editFreeDay: false },
      };

    // delete free days
    case IFreeCoursesActionsTypes.DELETE_FREE_DAYS_REQUEST:
      return {
        ...state,
        loaders: { 
          ...state.loaders,
          [`delete/${action.payload.freeDayId}`]: true,
        },
      };
    case IFreeCoursesActionsTypes.DELETE_FREE_DAYS_SUCCESS:
    case IFreeCoursesActionsTypes.DELETE_FREE_DAYS_ERROR:
      return {
        ...state,
        loaders: {
          ...state.loaders, 
          [`delete/${action.payload.freeDayId}`]: false,
        },
      };

    // get free trainings
    case IFreeCoursesActionsTypes.GET_FREE_TRAININGS_REQUEST:
      return { ...state, loaders: { ...state.loaders, getFreeTrainings: true } };
    case IFreeCoursesActionsTypes.GET_FREE_TRAININGS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getFreeTrainings: false },
        data: action.payload,
      };
    case IFreeCoursesActionsTypes.GET_FREE_TRAININGS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getFreeTrainings: false },
      };

    // edit free training
    case IFreeCoursesActionsTypes.EDIT_FREE_TRAINING_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editFreeTraining: true },
      };
    case IFreeCoursesActionsTypes.EDIT_FREE_TRAINING_SUCCESS:
    case IFreeCoursesActionsTypes.EDIT_FREE_TRAINING_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editFreeTraining: false },
      };

    // delete free training
    case IFreeCoursesActionsTypes.DELETE_FREE_TRAINING_REQUEST:
      return {
        ...state,
        loaders: { 
          ...state.loaders,
          [`delete/${action.payload.freeTrainingId}`]: true,
        },
      };
    case IFreeCoursesActionsTypes.DELETE_FREE_TRAINING_ERROR:
    case IFreeCoursesActionsTypes.DELETE_FREE_TRAINING_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders, 
          [`delete/${action.payload.freeTrainingId}`]: false,
        },
      };
      
      
    default:
      return state;
  }
};

export { reducer as FreeCoursesReducer };
