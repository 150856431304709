import { IFreeExercisesActionsTypes } from './types';
import { action } from 'typesafe-actions';
import { ApiResponse } from '../../utils/api';

// GET FREE EXERCISE

export const getFreeExercises = () => action(IFreeExercisesActionsTypes.GET_FREE_EXERCISE_REQUEST);

export const getFreeExercisesSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(
  IFreeExercisesActionsTypes.GET_FREE_EXERCISE_SUCCESS,
  response.data,
  response.code,
);

export const getFreeExercisesError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(IFreeExercisesActionsTypes.GET_FREE_EXERCISE_ERROR, {
  error: response.error,
  code: response.code,
});

// EDIT FREE TRAINING

export const editFreeExercise = (
  values: { [key: string]: any }
) => action(IFreeExercisesActionsTypes.EDIT_FREE_EXERCISE_REQUEST, values);

export const editFreeExerciseSuccess = () => action(IFreeExercisesActionsTypes.EDIT_FREE_EXERCISE_SUCCESS);

export const editFreeExerciseError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(IFreeExercisesActionsTypes.EDIT_FREE_EXERCISE_ERROR, {
  error: response.error,
  code: response.code,
});

// DELETE FREE TRAINING

export const deleteFreeExercise = ({
  freeExerciseId,
}: {
  freeExerciseId: number,
}) => action(IFreeExercisesActionsTypes.DELETE_FREE_EXERCISE_REQUEST, { freeExerciseId });

export const deleteFreeExerciseSuccess = (
  { freeExerciseId }: { freeExerciseId: number }
) => action(IFreeExercisesActionsTypes.DELETE_FREE_EXERCISE_SUCCESS, freeExerciseId);

export const deleteFreeExerciseError = (
  response: ApiResponse<{ [key: string]: any }>,
  freeExerciseId: number,
) =>
action(IFreeExercisesActionsTypes.DELETE_FREE_EXERCISE_ERROR, {
  error: response.error,
  code: response.code,
  freeExerciseId,
});