import { put, race, select, take, takeEvery } from 'redux-saga/effects';

import { logout, tokenRefreshRequest } from '../auth/actions';
import { Action } from 'redux';
import { ApplicationState } from '../index';
import { AuthActionTypes } from '../auth/types';
import { delay } from 'redux-saga';

const ignoreActionTypes = ['TOKEN_REFRESH', 'LOGIN'];

function monitorableAction(action: Action) {
  return (
    action.type.includes('REQUEST') &&
    ignoreActionTypes.every(fragment => !action.type.includes(fragment))
  );
}

function identifyAction(action: Action) {
  return action.type
    .split('_')
    .slice(0, -1)
    .join('_');
}

function getSuccessType(action: Action) {
  return `${identifyAction(action)}_SUCCESS`;
}

function getFailType(action: Action) {
  return `${identifyAction(action)}_ERROR`;
}

function* monitor(monitoredAction: Action) {
  const { exp } = yield select<ApplicationState>(state => state.auth.data);
  let shouldBeRefreshed = false;
  if (exp < Date.now() / 1000) {
    shouldBeRefreshed = true;
  } else {
    const { fail } = yield race({
      success: take(getSuccessType(monitoredAction)),
      fail: take(getFailType(monitoredAction)),
    });
    shouldBeRefreshed = fail && fail.meta === 401;
  }

  if (shouldBeRefreshed) {
    const { refresh_token } = yield select(
      (state: ApplicationState) => state.auth.token,
    );
    yield put(tokenRefreshRequest({ refresh_token }));

    const { success } = yield race({
      success: take(AuthActionTypes.TOKEN_REFRESH_SUCCESS),
      fail: take(AuthActionTypes.TOKEN_REFRESH_ERROR),
    });

    if (success) {
      yield delay(50);
      yield put(monitoredAction);
    } else {
      yield put(logout());
    }
  }
}

export default function*() {
  yield takeEvery(monitorableAction, monitor);
}
