import { all, fork, takeLatest } from 'redux-saga/effects';
import { IFoodActionsTypes } from './types';
import { message } from 'antd';
import {
  watchEditPortionsRequest,
  watchGetPortionsRequest,
  watchDeletePortionRequest,
  watchGetPortionItemRequest,
} from './portions.sagas';
import {
  watchDeleteRationRequest,
  watchEditRationsRequest,
  watchGetRationsRequest,
  watchGetRationItemRequest,
} from './rations.sagas';
import {
  watchGetMealsRequest,
  watchEditMealsRequest,
  watchDeleteMealsRequest,
} from './meals.sagas';

function handleShowError(action: { [key: string]: any }) {
  const { code } = action.payload;
  const { message: errorMessage } = action.payload.error;
  message.error(`${code} ${errorMessage}`);
}

function* watchRequestError() {
  yield takeLatest(
    [
      IFoodActionsTypes.GET_RATIONS_ERROR,
      IFoodActionsTypes.EDIT_RATIONS_ERROR,
      IFoodActionsTypes.DELETE_RATION_ERROR,
      IFoodActionsTypes.GET_PORTIONS_ERROR,
      IFoodActionsTypes.EDIT_PORTIONS_ERROR,
      IFoodActionsTypes.DELETE_PORTION_ERROR,
      IFoodActionsTypes.GET_RATION_ITEM_ERROR,
      IFoodActionsTypes.GET_PORTION_ITEM_ERROR,
      IFoodActionsTypes.GET_MEALS_ERROR,
      IFoodActionsTypes.EDIT_MEALS_ERROR,
      IFoodActionsTypes.DELETE_MEALS_ERROR,
    ],
    handleShowError,
  );
}

function* FoodSaga() {
  yield all([
    fork(watchGetRationsRequest),
    fork(watchRequestError),
    fork(watchEditRationsRequest),
    fork(watchGetPortionsRequest),
    fork(watchDeleteRationRequest),
    fork(watchEditPortionsRequest),
    fork(watchDeletePortionRequest),
    fork(watchGetRationItemRequest),
    fork(watchGetPortionItemRequest),
    fork(watchGetMealsRequest),
    fork(watchEditMealsRequest),
    fork(watchDeleteMealsRequest),
  ]);
}

export default FoodSaga;
