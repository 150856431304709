import { action } from 'typesafe-actions'
import { AuthActionTypes, AuthRequest, AuthToken, TokenRefreshRequest } from './types';

export const loginPerform = (authRequest: AuthRequest) => action(AuthActionTypes.LOGIN_REQUEST, authRequest);
export const loginError = (error: string) => action(AuthActionTypes.LOGIN_ERROR, error)
export const loginSuccess = (authData: AuthToken) => action(AuthActionTypes.LOGIN_SUCCESS, authData)
export const logout = () => action(AuthActionTypes.LOGOUT)
export const tokenRefreshRequest = (refreshRequest: TokenRefreshRequest) => action(AuthActionTypes.TOKEN_REFRESH_REQUEST, refreshRequest);
export const tokenRefreshError = () => action(AuthActionTypes.TOKEN_REFRESH_ERROR);
export const tokenRefreshSuccess = () => action(AuthActionTypes.TOKEN_REFRESH_SUCCESS);
