import history from '../utils/history';
import routeMap from '../constants/routeMap';
import qs from 'qs';

export const goToPath = (path: string) => {
  history.push(path);
};

export const goTo = (namespace: string, params?: any) => {
  if (!routeMap[namespace].getPath) {
    history.push(routeMap[namespace].path);
  } else {
    // @ts-ignore
    history.push(routeMap[namespace].getPath(params));
  }
};

export const replaceLocation = (namespace: string) => {
  history.replace(routeMap[namespace].path);
};

export const replaceWindowLocation = (pathname: string) => {
  window.location.href = pathname;
};

export const goToRoot = () => goTo('root');

export const getParams = () =>
  qs.parse(history.location.search, { ignoreQueryPrefix: true });
