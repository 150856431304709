import { css } from 'react-emotion';

export default css`
  body {
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    position: relative;
    vertical-align: middle;
  }

  figure {
    margin: 2rem 0;
  }

  figcaption {
    font-size: 80%;
  }

  table {
    width: 100%;
    font-size: 85%;
    border-collapse: collapse;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  input[type="number"]:hover {
    appearence: none;
  }

  .ant-form-item-label {
    line-height: 1.5;
  }

  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-table-thead > tr > th .ant-table-filter-icon > svg {
    transform: scale(1.5);
  }

  .ant-modal-body {
    padding: 40px;
  }

  .dpw-dayform-row {
    width: 33%;
    display: flex;
  }

  .dpw-dayform-row > button {
    width: 48%;
  }

  .dpw-dayform-row > button:last-child {
    margin-left: 15px;
  }
`;
