import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { IWorkoutsActionsTypes } from './types';
import { callApi } from '../../utils/api';
import { message } from 'antd';
import {
  getWorkoutsDay,  // new
  getWorkoutsDayError,  // new
  getWorkoutsDaySuccess,  // new
  getWorkoutsWeek,  // new
  getWorkoutsWeekError,  // new
  getWorkoutsWeekSuccess,  // new
  getWorkoutsPlan,  // new
  getWorkoutsPlanError,  // new
  getWorkoutsPlanSuccess,  // new
  getWorkouts,  // new
  getWorkoutsError,  // new
  getWorkoutsSuccess,  // new
  editWorkoutsPlan,
  editWorkoutsPlanError,
  editWorkoutsPlanSuccess,
  deleteWorkoutsPlan,
  deleteWorkoutsPlanSuccess,
  deleteWorkoutsPlanError,
  editWorkoutsWeek,
  editWorkoutsWeekError,
  editWorkoutsWeekSuccess,
  deleteWorkoutsWeek,
  deleteWorkoutsWeekError,
  deleteWorkoutsWeekSuccess,
  copyWorkoutsWeek,
  copyWorkoutsWeekError,
  copyWorkoutsWeekSuccess,
  editWorkoutsDay,
  editWorkoutsDayError,
  editWorkoutsDaySuccess,
  editWorkouts,
  editWorkoutsError,
  editWorkoutsSuccess,
} from './actions';
import { AuthActionTypes } from '../auth/types';
import { getToken, isTokenRefreshing } from '../auth/selectors';
import { CONTENT_TYPES } from '../../utils/api';

function handleShowError(action: { [key: string]: any }) {
  message.error(action.payload.error.message);
}

function* getAuthToken() {
  const isRefreshing = yield select(isTokenRefreshing);
  if (isRefreshing) {
    const { fail } = yield race({
      success: take(AuthActionTypes.TOKEN_REFRESH_SUCCESS),
      fail: take(AuthActionTypes.TOKEN_REFRESH_ERROR),
    });
    if (fail) {
      return null;
    }
  }
  return yield select(getToken);
}


function* handleGetWorkoutsDayRequest({payload}: any) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const {workoutWeekId, workoutDayIndex} = payload;
  const response = yield call(callApi, 'get', `admin/workoutWeeks/${workoutWeekId}/days/${workoutDayIndex}/exercises`, token);
  if (response.error) {
    yield put(getWorkoutsDayError(response));
  } else {
    yield put(getWorkoutsDaySuccess(response));
  }
}

function* handleGetWorkoutsWeekRequest(args: any) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const workoutPlanId = args.payload;
  const response = yield call(callApi, 'get', `admin/workoutPlans/${workoutPlanId}/weeks`, token);
  if (response.error) {
    yield put(getWorkoutsWeekError(response));
  } else {
    yield put(getWorkoutsWeekSuccess(response));
  }
}

function* handleGetWorkoutsPlanRequest(args: any) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const workoutId = args.payload;
  const response = yield call(callApi, 'get', `admin/workouts/${workoutId}/plans`, token);
  if (response.error) {
    yield put(getWorkoutsPlanError(response));
  } else {
    yield put(getWorkoutsPlanSuccess(response));
  }
}

function* handleUploadWorkoutsPlanImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { workoutId, workoutPlanId } = data;
  const response = yield call(
    callApi,
    'post',
    `admin/workouts/${workoutId}/plans/${workoutPlanId}/image`,
    token,
    { image: data.image, image_eng: data.image_eng },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* handleUploadWorkoutsPlanPreview({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { workoutId, workoutPlanId } = data;
  const response = yield call(
    callApi,
    'post',
    `admin/workouts/${workoutId}/plans/${workoutPlanId}/videoPreview`,
    token,
    { videoPreview: data.videoPreview, videoPreview_eng: data.videoPreview_eng },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* handleDeleteWorkoutsPlanRequest(
  action: ReturnType<typeof deleteWorkoutsPlan>,
) {
  const { workoutId, workoutPlanId } = action.payload;
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'delete',
    `admin/workouts/${workoutId}/plans/${workoutPlanId}`,
    token,
  );
  if (response.error) {
    yield put(deleteWorkoutsPlanError(response, workoutPlanId));
  } else {
    yield put(deleteWorkoutsPlanSuccess({ workoutPlanId }));
    // yield put(getWorkouts());
    yield put(getWorkoutsPlan(workoutId));
  }
}

function* handleGetWorkoutsRequest() {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(callApi, 'get', 'admin/workouts', token);
  if (response.error) {
    yield put(getWorkoutsError(response));
  } else {
    yield put(getWorkoutsSuccess(response));
  }
}

function* handleEditExtraDescription(
  token: string,
  extra: { [key: string]: any },
  extraImgToDelete: string[],
) {
  try {
    yield call(
      callApi,
      'put',
      `admin/workoutPlanExtraDescriptions/${extra.id}`,
      token,
      { ...extra },
    );
    if (extra.image || extra.image_eng) {
      yield call(
        callApi,
        'post',
        `admin/workoutPlanExtraDescriptions/${extra.id}/image`,
        token,
        { image: extra.image, image_eng: extra.image_eng },
        CONTENT_TYPES.MPFD,
      );
    }
    // Удаление русской картинки из блока workoutsPlan
    if (!extra.image &&
      extraImgToDelete &&
      extraImgToDelete.includes(extra.id)
    ) {
      yield call(
        callApi,
        'delete',
        `admin/workoutPlanExtraDescriptions/${extra.id}/image`,
        token
      );
    }
    // Удаление английской картинки из блока workoutsPlan
    if (!extra.image_eng &&
      extraImgToDelete &&
      extraImgToDelete.includes(`${extra.id}eng`)
    ) {
      yield call(
        callApi,
        'delete',
        `admin/workoutPlanExtraDescriptions/${extra.id}/image/eng`,
        token
      );
    }
  } catch (error) {
    message.error(error.message);
  }
}

function* handleCreateExtraDescription(
  token: string,
  workoutPlanId: number,
  extra: { [key: string]: any },
) {
  try {
    delete extra.id;
    const response = yield call(
      callApi,
      'post',
      `admin/workoutPlans/${workoutPlanId}/extraDescriptions`,
      token,
      { ...extra },
    );
    const workoutPlanExtraDescriptionId = response.data.id;
    if (extra.image || extra.image_eng) {
      yield call(
        callApi,
        'post',
        `admin/workoutPlanExtraDescriptions/${workoutPlanExtraDescriptionId}/image`,
        token,
        { image: extra.image, image_eng: extra.image_eng },
        CONTENT_TYPES.MPFD,
      );
    }
  } catch (error) {
    message.error(error.message);
  }
}

function* createWorkoutsPlan(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'post',
    `admin/workouts/${values.workoutId}/plans`,
    token,
    { ...values },
  );
  if (response.error) {
    yield put(editWorkoutsPlanError(response));
  } else {
    const workoutPlanId = response.data.id;
    yield handleUploadWorkoutsPlanImage({
      token,
      data: { ...values, workoutPlanId },
    });
    if (values.videoPreview || values.videoPreview_eng) {
      yield handleUploadWorkoutsPlanPreview({
        token,
        data: { ...values, workoutPlanId },
      });
    }

    if (values.extra) {
      for (const item in values.extra) {
        const extra = values.extra[item];
        yield handleCreateExtraDescription(token, workoutPlanId, extra);
      }
    }

    yield put(editWorkoutsPlanSuccess(response));
    //yield put(getWorkouts());
    yield put(getWorkoutsPlan(values.workoutId));
    values.callback();
  }
}

function* editWorkoutsPlanRequest(
  token: string,
  values: { [key: string]: any },
) {
  const { extraImgToDelete, ...restProps } = values;
  const response = yield call(
    callApi,
    'put',
    `admin/workouts/${restProps.workoutId}/plans/${restProps.id}`,
    token,
    { ...restProps },
  );

  if (response.error) {
    yield put(editWorkoutsPlanError(response));
  } else {
    if ((restProps.image && typeof restProps.image !== 'string') || (restProps.image_eng && typeof restProps.image_eng !== 'string')) {
      yield handleUploadWorkoutsPlanImage({
        token,
        data: { ...restProps, workoutPlanId: restProps.id },
      });
    }
    if ((restProps.videoPreview && typeof restProps.videoPreview !== 'string') || (restProps.videoPreview_eng && typeof restProps.videoPreview_eng !== 'string')) {
      yield handleUploadWorkoutsPlanPreview({
        token,
        data: { ...restProps, workoutPlanId: restProps.id },
      });
    }

    if (restProps.extra) {
      for (const item in restProps.extra) {
        const extra = restProps.extra[item];
        if (extra.id.includes('new')) {
          yield handleCreateExtraDescription(token, restProps.id, extra);
        } else {
          yield handleEditExtraDescription(token, extra, extraImgToDelete);
        }
      }
    }

    if (restProps.extraToDelete) {
      restProps.extraToDelete.forEach((extra: string) => {
        callApi('delete', `admin/workoutPlanExtraDescriptions/${extra}`, token);
      });
    }

    yield put(editWorkoutsPlanSuccess(response));
    // yield put(getWorkouts());
    yield put(getWorkoutsPlan(restProps.workoutId));
    if (restProps.callback) restProps.callback();
  }
}

function* handleEditWorkoutsPlanRequest(
  action: ReturnType<typeof editWorkoutsPlan>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield editWorkoutsPlanRequest(token, action.payload);
  } else {
    yield createWorkoutsPlan(token, action.payload);
  }
}

function* createWorkoutsWeeks(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'post',
    `admin/workoutPlans/${values.workoutsPlanId}/weeks`,
    token,
    { ...values },
  );
  if (response.error) {
    yield put(editWorkoutsWeekError(response));
  } else {
    yield put(editWorkoutsWeekSuccess(response));
    // yield put(getWorkouts());
    yield put(getWorkoutsWeek(values.workoutsPlanId));
    values.callback();
  }
}

function* editWorkoutsWeeks(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'put',
    `admin/workoutPlans/${values.workoutsPlanId}/weeks/${values.id}`,
    token,
    { ...values },
  );
  if (response.error) {
    yield put(editWorkoutsWeekError(response));
  } else {
    yield put(editWorkoutsWeekSuccess(response));
    // yield put(getWorkouts());
    yield put(getWorkoutsWeek(values.workoutsPlanId));
    values.callback();
  }
}

function* handleEditWorkoutsWeeksRequest(
  action: ReturnType<typeof editWorkoutsWeek>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield editWorkoutsWeeks(token, action.payload);
  } else {
    yield createWorkoutsWeeks(token, action.payload);
  }
}

function* handleDeleteWorkoutsWeekRequest(
  action: ReturnType<typeof deleteWorkoutsWeek>,
) {
  const { workoutWeekId, workoutPlanId } = action.payload;
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'delete',
    `admin/workoutPlans/${workoutPlanId}/weeks/${workoutWeekId}`,
    token,
  );

  if (response.error) {
    yield put(deleteWorkoutsWeekError(response, workoutWeekId));
  } else {
    yield put(deleteWorkoutsWeekSuccess({ workoutWeekId }));
    // yield put(getWorkouts());
    yield put(getWorkoutsWeek(workoutPlanId));
  }
}

function* handleCopyWorkoutsWeekRequest(
  action: ReturnType<typeof copyWorkoutsWeek>,
) {
  const { workoutWeekId, workoutPlanId } = action.payload;
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'post',
    `admin/workoutPlans/${workoutPlanId}/weeks/${workoutWeekId}`,
    token,
  );

  if (response.error) {
    yield put(copyWorkoutsWeekError(response, workoutWeekId));
  } else {
    yield put(copyWorkoutsWeekSuccess({ workoutWeekId }));
    // yield put(getWorkouts());
    yield put(getWorkoutsWeek(workoutPlanId));
  }
}

function* handleUploadWorkoutsDayImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { workoutWeekId, workoutDayIndex, image } = data;
  const response = image ? yield call(
    callApi,
    'post',
    `admin/workoutWeeks/${workoutWeekId}/days/${workoutDayIndex}/videoIntroImage`,
    token,
    { image },
    CONTENT_TYPES.MPFD,
  ) : yield call(
    callApi,
    'delete',
    `admin/workoutWeeks/${workoutWeekId}/days/${workoutDayIndex}/videoIntroImage`,
    token,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* handleUploadWorkoutsDayImageEng({ data, token }: { data: { [key: string]: any }; token: string }) {
  const { workoutWeekId, workoutDayIndex, image_eng } = data;
  const response = image_eng ? yield call(
    callApi,
    'post',
    `admin/workoutWeeks/${workoutWeekId}/days/${workoutDayIndex}/videoIntroImage`,
    token,
    { image_eng },
    CONTENT_TYPES.MPFD,
  ) : yield call(
    callApi,
    'delete',
    `admin/workoutWeeks/${workoutWeekId}/days/${workoutDayIndex}/videoIntroImage/eng`,
    token,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* handleEditWorkoutsDayRequest(
  action: ReturnType<typeof editWorkoutsDay>,
) {
  const {
    workoutWeekId,
    workoutDayIndex,
    videoIntroImage,
    videoIntroImage_eng,
    oldVideoIntroImage,
    oldVideoIntroImage_eng,
  } = action.payload;
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'put',
    `admin/workoutWeeks/${workoutWeekId}/days/${workoutDayIndex}`,
    token,
    action.payload,
  );

  if (response.error) {
    yield put(editWorkoutsDayError(response));
  } else {
    if ( response.data.videoIntroImage !== oldVideoIntroImage || videoIntroImage ) {
      yield handleUploadWorkoutsDayImage({
        token,
        data: {
          image: videoIntroImage,
          workoutWeekId,
          workoutDayIndex
        },
      });
    }
    if ( response.data.videoIntroImage_eng !== oldVideoIntroImage_eng || videoIntroImage_eng ) {
      yield handleUploadWorkoutsDayImageEng({
        token,
        data: {
          image_eng: videoIntroImage_eng,
          workoutWeekId,
          workoutDayIndex
        },
      });
    }

    yield put(editWorkoutsDaySuccess(response));
    // yield put(getWorkouts());
    // yield put(getWorkoutsDay(workoutWeekId, workoutDayIndex));
    action.payload.callback();
  }
}

function* handleUploadWorkoutsImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { id } = data;
  const response = yield call(
    callApi,
    'post',
    `admin/workouts/${id}/image`,
    token,
    { image: data.image, image_eng: data.image_eng },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* editWorkoutRequest(
  token: string,
  values: {[key: string]: any}
) {
  const { id } = values;
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'put',
    `admin/workouts/${id}`,
    token,
    values,
  );

  if (response.error) {
    yield put(editWorkoutsError(response));
  } else {
    if ((values.image && typeof values.image !== 'string') || (values.image_eng && typeof values.image_eng !== 'string')) {
      yield handleUploadWorkoutsImage({
        token,
        data: values,
      });
    }
    yield put(editWorkoutsSuccess(response));
    yield put(getWorkouts());
    if (values.callback) values.callback();
  }
}

function* createWorkoutRequest(
  token: string,
  values: {[key: string]: any}
) {
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'post',
    `admin/workouts`,
    token,
    values,
  );
  if (response.error) {
    yield put(editWorkoutsError(response));
  } else {
    if ((values.image && typeof values.image !== 'string') || (values.image_eng && typeof values.image_eng !== 'string')) {
      yield handleUploadWorkoutsImage({
        token,
        data: {
          ...values,
          id: response.data.id,
        },
      });
    }
    yield put(editWorkoutsSuccess(response));
    yield put(getWorkouts());
    if (values.callback) values.callback();
  }
}

function* handleEditWorkoutsRequest(action: ReturnType<typeof editWorkouts>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield editWorkoutRequest(token, action.payload);
  } else {
    yield createWorkoutRequest(token, action.payload);
  }
  
}

function* watchEditWorkoutsRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.EDIT_WORKOUTS_REQUEST,
    handleEditWorkoutsRequest,
  );
}

function* watchEditWorkoutsDayRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.EDIT_WORKOUTS_DAY_REQUEST,
    handleEditWorkoutsDayRequest,
  );
}

function* watchGetWorkoutsDayRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.GET_WORKOUTS_DAY_REQUEST,
    handleGetWorkoutsDayRequest,
  );
}

function* watchGetWorkoutsWeekRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.GET_WORKOUTS_WEEK_REQUEST,
    handleGetWorkoutsWeekRequest,
  );
}

function* watchCopyWorkoutsWeeksRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.COPY_WORKOUTS_WEEK_REQUEST,
    handleCopyWorkoutsWeekRequest,
  );
}

function* watchEditWorkoutsWeeksRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.EDIT_WORKOUTS_WEEK_REQUEST,
    handleEditWorkoutsWeeksRequest,
  );
}

function* watchGetWorkoutsRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.GET_WORKOUTS_REQUEST,
    handleGetWorkoutsRequest,
  );
}
function* watchGetWorkoutsPlanRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.GET_WORKOUTS_PLAN_REQUEST,
    handleGetWorkoutsPlanRequest,
  );
}

function* watchEditWorkoutsPlanRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.EDIT_WORKOUTS_PLAN_REQUEST,
    handleEditWorkoutsPlanRequest,
  );
}

function* watchDeleteWorkoutsPlanRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.DELETE_WORKOUTS_PLAN_REQUEST,
    handleDeleteWorkoutsPlanRequest,
  );
}

function* watchDeleteWorkoutsWeekRequest() {
  yield takeLatest(
    IWorkoutsActionsTypes.DELETE_WORKOUTS_WEEK_REQUEST,
    handleDeleteWorkoutsWeekRequest,
  );
}

function* watchRequestError() {
  yield takeLatest(
    [
      IWorkoutsActionsTypes.GET_WORKOUTS_DAY_ERROR,
      IWorkoutsActionsTypes.GET_WORKOUTS_WEEK_ERROR,
      IWorkoutsActionsTypes.GET_WORKOUTS_PLAN_ERROR,
      IWorkoutsActionsTypes.GET_WORKOUTS_ERROR,
      IWorkoutsActionsTypes.EDIT_WORKOUTS_PLAN_ERROR,
      IWorkoutsActionsTypes.DELETE_WORKOUTS_PLAN_ERROR,
      IWorkoutsActionsTypes.EDIT_WORKOUTS_WEEK_ERROR,
      IWorkoutsActionsTypes.DELETE_WORKOUTS_WEEK_ERROR,
      IWorkoutsActionsTypes.COPY_WORKOUTS_WEEK_ERROR,
      IWorkoutsActionsTypes.EDIT_WORKOUTS_DAY_ERROR,
      IWorkoutsActionsTypes.EDIT_WORKOUTS_ERROR,
    ],
    handleShowError,
  );
}

function* WorkoutsSaga() {
  yield all([
    fork(watchGetWorkoutsDayRequest),
    fork(watchGetWorkoutsWeekRequest),
    fork(watchGetWorkoutsPlanRequest),
    fork(watchGetWorkoutsRequest),
    fork(watchEditWorkoutsRequest),
    fork(watchDeleteWorkoutsPlanRequest),
    fork(watchEditWorkoutsWeeksRequest),
    fork(watchDeleteWorkoutsWeekRequest),
    fork(watchCopyWorkoutsWeeksRequest),
    fork(watchEditWorkoutsPlanRequest),
    fork(watchEditWorkoutsDayRequest),
    fork(watchRequestError),
  ]);
}

export default WorkoutsSaga;
