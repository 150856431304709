import { IMotivationActionsTypes, IMotivationState } from './types';
import { Reducer } from 'redux';

const InitialState: IMotivationState = {
  list: [],
  loaders: {},
};

const reducer: Reducer = (state = InitialState, action) => {
  switch (action.type) {
    // get motivations
    case IMotivationActionsTypes.GET_MOTIVATIONS_REQUEST:
      return { ...state, loaders: { ...state.loaders, getMotivations: true } };
    case IMotivationActionsTypes.GET_MOTIVATIONS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getMotivations: false },
        list: action.payload,
      };
    case IMotivationActionsTypes.GET_MOTIVATIONS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getMotivation: false },
      };

    // edit motivations
    case IMotivationActionsTypes.EDIT_MOTIVATION_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editMotivation: true },
      };
    case IMotivationActionsTypes.EDIT_MOTIVATION_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, editMotivation: false },
      };
    case IMotivationActionsTypes.EDIT_MOTIVATION_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editMotivation: false },
      };

    // delete workouts plan
    case IMotivationActionsTypes.DELETE_MOTIVATION_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.motivationId}`]: true,
        },
      };
    case IMotivationActionsTypes.DELETE_MOTIVATION_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.motivationId}`]: false,
        },
      };
    case IMotivationActionsTypes.DELETE_MOTIVATION_ERROR:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.motivationId}`]: false,
        },
      };

    // edit motivation category
    case IMotivationActionsTypes.EDIT_MOTIVATION_CATEGORY_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editMotivationCategory: true },
      };
    case IMotivationActionsTypes.EDIT_MOTIVATION_CATEGORY_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, editMotivationCategory: false },
      };
    case IMotivationActionsTypes.EDIT_MOTIVATION_CATEGORY_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editMotivationCategory: false },
      };

    default:
      return state;
  }
};

export { reducer as MotivationReducer };
