import React from 'react';
import { Table, Button, Modal, Popconfirm, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormComponentProps } from 'antd/lib/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getFreeDays, editFreeDay, deleteFreeDay } from '../../store/free-courses/actions';
import { ApplicationState } from '../../store';
import { IFreeCoursesState } from '../../store/free-courses/types';
import { ContentWrapper } from '../../components/layout/Content';
import Breadcrumbs from '../../components/FreeBreadcrumbs';
import {
  ExercisiesPageWrapper,
  ButtonGroup
} from '../../constants/styles';
import { goTo } from '../../services/navigator.service';
import { TableColumns } from './columns';
import EditForm from './components/EditForm';

interface IFreeDayProps extends FormComponentProps {
  getFreeDays: (freeWeekId: number) => void;
  deleteFreeDay: (freeDayId: number, freeWeekId: number) => void;
  editFreeDay: (values: {[key: string]: any}) => void;
  free_courses: IFreeCoursesState;
  match: { [key: string]: any };
}

interface IFreeDayComponentState {
  modalVisible: boolean;
  modalData: { [key: string]: any };
}

class FreeCoursesDay extends React.Component<IFreeDayProps, IFreeDayComponentState> {
  constructor(props: IFreeDayProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {},
    }
    this.isPublishOnChangeHandler = this.isPublishOnChangeHandler.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getFreeDays(this.props.match.params.freeWeekId);
  }

  isPublishOnChangeHandler(
    data: { [key: string]: any },
    event: CheckboxChangeEvent,
  ) {
    let isChecked = event.target.checked;

    this.props.editFreeDay({
      ...data,
      isPublish: isChecked,
      freeWeekId: this.props.match.params.freeWeekId
    });
  };

  closeModal() {
    this.setState({ modalVisible: false, modalData: {} });
  }

  openModal() {
    this.setState({ modalVisible: true });
  }

  onSubmit() {
    const { form } = this.props;
    form!.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.editFreeDay({
          ...values,
          freeWeekId: this.props.match.params.freeWeekId,
          callback: this.closeModal
        });
      };
      if (err) {
        message.error('Правильно заполните поля');
      };
    });
  }

  render() {
    const { loaders, data } = this.props.free_courses;
    const tableData = data || [];

    const ModifyTableColumns: ColumnProps<any>[] = [
      ...TableColumns,
      {
        title: (
          <Button
            className="action-button"
            icon="plus-circle"
            htmlType="button"
            title="Добавить день тренировки"
            onClick={this.openModal}
          />
        ),
        dataIndex: '',
        key: 'x',
        render: (_: string, data: { [key: string]: any }) => {
          const openEditModal = () => {
            this.setState({ modalData: data });
            this.setState({ modalVisible: true });
          };

          return (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="read"
                htmlType="button"
                title="Просмотреть день тренировки"
                onClick={goTo.bind(null, 'freeTrainings', {
                  freeDayId: `${data.id}`,
                })}
              />
              <Button
                className="action-button"
                icon="edit"
                htmlType="button"
                title="Редактировать день тренировки"
                onClick={openEditModal}
              />
              <Popconfirm
                title="Вы уверены, что хотите удалить день тренировки?"
                onConfirm={() => this.props.deleteFreeDay(data.id, this.props.match.params.freeWeekId)}
                placement="rightBottom"
              >
                <Button
                  className="action-button"
                  icon="delete"
                  htmlType="button"
                  title="Удалить день тренировки"
                  loading={loaders[`delete/${data.id}`]}
                />
              </Popconfirm>
            </ButtonGroup>
          );
        },
      },
    ];

    const modalTitle = this.state.modalData.id
      ? 'Редактирование дня'
      : 'Добавление дня';

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Breadcrumbs type="days" id={this.props.match.params.freeWeekId} />
          <Table
            rowKey="id"
            columns={ModifyTableColumns}
            loading={loaders.getFreeDays}
            dataSource={tableData}
          />
          <Modal
            title={modalTitle}
            destroyOnClose={true}
            maskClosable={false}
            visible={this.state.modalVisible}
            confirmLoading={loaders.editFreeDay}
            onCancel={this.closeModal}
            onOk={this.onSubmit}
          >
            <EditForm
              form={this.props.form}
              data={this.state.modalData}
            />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  free_courses: state.free_courses
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFreeDays: (freeWeekId: number) => dispatch(getFreeDays(freeWeekId)),
  deleteFreeDay: (freeDayId: number, freeWeekId: number) => dispatch(deleteFreeDay({ freeDayId, freeWeekId })),
  editFreeDay: (values: { [key: string]: any }) => dispatch(editFreeDay(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreeCoursesDay);