import {
  all,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import { IFreeCoursesActionsTypes } from './types';
import { handleShowError } from '../utils';
import { watchGetFreeCoursesRequest, watchEditFreeCoursesRequest } from './sagas/courses';
import { watchDeleteFreeCoursesWeekRequest, watchGetFreeCoursesWeekRequest, watchEditFreeCoursesWeekRequest } from './sagas/weeks';
import { watchGetFreePlansRequest, watchEditFreePlanRequest, watchDeleteFreePlanRequest } from './sagas/plans';
import { watchDeleteFreeDayRequest, watchEditFreeDayRequest, watchGetFreeDaysRequest } from './sagas/days';
import { watchDeleteFreeTrainingRequest, watchEditFreeTrainingRequest, watchGetFreeTrainingsRequest} from './sagas/trainings';

function* watchRequestError() {
  yield takeLatest(
    [
      IFreeCoursesActionsTypes.GET_FREE_COURSES_ERROR,
      IFreeCoursesActionsTypes.EDIT_FREE_COURSES_ERROR,
      IFreeCoursesActionsTypes.GET_FREE_PLANS_ERROR,
      IFreeCoursesActionsTypes.GET_FREE_COURSES_ERROR,
      IFreeCoursesActionsTypes.DELETE_FREE_PLAN_ERROR,
      IFreeCoursesActionsTypes.GET_FREE_COURSES_WEEKS_ERROR,
      IFreeCoursesActionsTypes.EDIT_FREE_COURSES_WEEK_ERROR,
      IFreeCoursesActionsTypes.DELETE_FREE_COURSES_WEEK_ERROR,
      IFreeCoursesActionsTypes.GET_FREE_DAYS_ERROR,
      IFreeCoursesActionsTypes.EDIT_FREE_DAYS_ERROR,
      IFreeCoursesActionsTypes.DELETE_FREE_DAYS_ERROR,
      IFreeCoursesActionsTypes.GET_FREE_TRAININGS_ERROR,
      IFreeCoursesActionsTypes.EDIT_FREE_TRAINING_ERROR,
      IFreeCoursesActionsTypes.DELETE_FREE_TRAINING_ERROR,
      
    ],
    handleShowError,
  );
}

function* FreeCoursesSaga() {
  yield all([
    fork(watchGetFreeCoursesRequest),
    fork(watchEditFreeCoursesRequest),
    fork(watchGetFreePlansRequest),
    fork(watchEditFreePlanRequest),
    fork(watchDeleteFreePlanRequest),
    fork(watchDeleteFreeCoursesWeekRequest),
    fork(watchGetFreeCoursesWeekRequest),
    fork(watchEditFreeCoursesWeekRequest),
    fork(watchDeleteFreeDayRequest),
    fork(watchEditFreeDayRequest),
    fork(watchGetFreeDaysRequest),
    fork(watchGetFreeTrainingsRequest),
    fork(watchDeleteFreeTrainingRequest),
    fork(watchEditFreeTrainingRequest),
    fork(watchRequestError),
  ]);
}

export default FreeCoursesSaga;
