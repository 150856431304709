import { ExercisiesActionsTypes, ExercisiesState } from './types';
import { Reducer } from 'redux';

const InitialState: ExercisiesState = {
  ExerciseFormOpen: false,
  ExerciseForm: undefined,
  Exercisies: undefined,
  ExercisiesIsLoading: false,
  ExercisiesLoadingError: undefined,
  ExerciseFormProcessing: false,
  ExerciseDeleteLoaders: {},
};

const reducer: Reducer<ExercisiesState> = (state = InitialState, action) => {
  switch (action.type) {
    case ExercisiesActionsTypes.GET_EXERCISIES_SUCCESS:
      return {
        ...state,
        ExercisiesIsLoading: false,
        Exercisies: action.payload,
      };
    case ExercisiesActionsTypes.GET_EXERCISIES_REQUEST:
      return { ...state, ExercisiesIsLoading: true, Exercisies: undefined };
    case ExercisiesActionsTypes.GET_EXERCISIES_ERROR:
      return {
        ...state,
        ExercisiesIsLoading: false,
        ExercisiesLoadingError: action.payload,
      };
    case ExercisiesActionsTypes.CLOSE_EXERCISE_FORM:
      return { ...state, ExerciseFormOpen: false, ExerciseForm: undefined };
    case ExercisiesActionsTypes.OPEN_EXERCISE_FORM:
      return { ...state, ExerciseFormOpen: true, ExerciseForm: action.payload };
    case ExercisiesActionsTypes.UPDATE_EXERCISE_REQUEST:
      return {
        ...state,
        ExerciseFormProcessing: true,
      };
    case ExercisiesActionsTypes.UPDATE_EXERCISE_ERROR:
      return { ...state, ExerciseFormProcessing: false };
    case ExercisiesActionsTypes.CREATE_EXERCISE_REQUEST:
      return {
        ...state,
        ExerciseFormProcessing: true,
      };
    case ExercisiesActionsTypes.UPDATE_EXERCISE_SUCCESS:
      return { ...state, ExerciseFormProcessing: false };
    case ExercisiesActionsTypes.CREATE_EXERCISE_SUCCESS:
      return { ...state, ExerciseFormProcessing: false };
    case ExercisiesActionsTypes.CREATE_EXERCISE_ERROR:
      return { ...state, ExerciseFormProcessing: false };
    case ExercisiesActionsTypes.DELETE_EXERCISE_REQUEST:
      return {
        ...state,
        ExerciseDeleteLoaders: {
          ...state.ExerciseDeleteLoaders,
          [action.payload.id]: true,
        },
      };
    case ExercisiesActionsTypes.DELETE_EXERCISE_SUCCESS:
      return {
        ...state,
        ExerciseDeleteLoaders: {
          ...state.ExerciseDeleteLoaders,
          [action.payload.id]: false,
        },
      };
    case ExercisiesActionsTypes.DELETE_EXERCISE_ERROR:
      return {
        ...state,
        ExerciseDeleteLoaders: {
          ...state.ExerciseDeleteLoaders,
          [action.payload.id]: false,
        },
      };

    // exercise levels
    case ExercisiesActionsTypes.CREATE_EXERCISE_LEVELS_REQUEST:
      return {
        ...state,
        ExerciseFormProcessing: true,
      };
    case ExercisiesActionsTypes.CREATE_EXERCISE_LEVELS_SUCCESS:
      return { ...state, ExerciseFormProcessing: false };
    case ExercisiesActionsTypes.CREATE_EXERCISE_LEVELS_ERROR:
      return { ...state, ExerciseFormProcessing: false };
    default: {
      return state;
    }

    case ExercisiesActionsTypes.DELETE_EXERCISE_LEVELS_REQUEST:
      return {
        ...state,
        ExerciseDeleteLoaders: {
          ...state.ExerciseDeleteLoaders,
          [action.payload.exerciseLevelId]: true,
        },
      };
    case ExercisiesActionsTypes.DELETE_EXERCISE_LEVELS_SUCCESS:
      return {
        ...state,
        ExerciseDeleteLoaders: {
          ...state.ExerciseDeleteLoaders,
          [action.payload.exerciseLevelId]: false,
        },
      };
    case ExercisiesActionsTypes.DELETE_EXERCISE_LEVELS_ERROR:
      return {
        ...state,
        ExerciseDeleteLoaders: {
          ...state.ExerciseDeleteLoaders,
          [action.payload.exerciseLevelId]: false,
        },
      };
  }
};

export { reducer as ExercisiesReducer };
