import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { IFreeCoursesActionsTypes } from '../types';
import { callApi } from '../../../utils/api';
import {
  getFreeDays,
  getFreeDaysError,
  getFreeDaysSuccess,
  editFreeDay,
  editFreeDaySuccess,
  editFreeDayError,
  deleteFreeDay,
  deleteFreeDayError,
  deleteFreeDaySuccess
} from '../actions';
import { getAuthToken } from '../../utils';

function* handleGetFreeDaysRequest(action: ReturnType<typeof getFreeDays>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const freeWeekId = action.payload;
  const response = yield call(callApi, 'get', `admin/free_weeks/${freeWeekId}/days`, token);
  if (response.error) {
    yield put(getFreeDaysError(response));
  } else {
    yield put(getFreeDaysSuccess(response));
  }
}

function* handleDeleteFreeDaysRequest(
  action: ReturnType<typeof deleteFreeDay>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const { freeDayId, freeWeekId } = action.payload;
  const response = yield call(
    callApi,
    'delete',
    `admin/free_days/${freeDayId}`,
    token,
  );
  if (response.error) {
    yield put(deleteFreeDayError(response, freeDayId));
  } else {
    yield put(deleteFreeDaySuccess({ freeDayId }));
    yield put(getFreeDays(freeWeekId));
  }
}

function* createFreeDay(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'post',
    `admin/free_weeks/${values.freeWeekId}/days`,
    token,
    values
  );

  if (response.error) {
    yield put(editFreeDayError(response));
  } else {
    yield put(editFreeDaySuccess());
    yield put(getFreeDays(values.freeWeekId));
    values.callback();
  }
}

function* editFreeDayRequest(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'put',
    `admin/free_days/${values.id}`,
    token,
    { ...values },
  );

  if (response.error) {
    yield put(editFreeDayError(response));
  } else {
    yield put(editFreeDaySuccess());
    yield put(getFreeDays(values.freeWeekId));
    if (values.callback) {
      values.callback();
    }
  }
}

function* handleEditFreeDayRequest(
  action: ReturnType<typeof editFreeDay>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield editFreeDayRequest(token, action.payload);
  } else {
    yield createFreeDay(token, action.payload);
  }
}

export function* watchDeleteFreeDayRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.DELETE_FREE_DAYS_REQUEST,
    handleDeleteFreeDaysRequest,
  );
}

export function* watchGetFreeDaysRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.GET_FREE_DAYS_REQUEST,
    handleGetFreeDaysRequest,
  );
}

export function* watchEditFreeDayRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.EDIT_FREE_DAYS_REQUEST,
    handleEditFreeDayRequest,
  );
}