export enum IMotivationActionsTypes {
  GET_MOTIVATIONS_REQUEST = '@@Workouts/GET_MOTIVATION_REQUEST',
  GET_MOTIVATIONS_SUCCESS = '@@Workouts/GET_MOTIVATION_SUCCESS',
  GET_MOTIVATIONS_ERROR = '@@Workouts/GET_MOTIVATION_ERROR',
  EDIT_MOTIVATION_REQUEST = '@@Workouts/EDIT_MOTIVATION_PLAN_REQUEST',
  EDIT_MOTIVATION_SUCCESS = '@@Workouts/EDIT_MOTIVATION_PLAN_SUCCESS',
  EDIT_MOTIVATION_ERROR = '@@Workouts/EDIT_MOTIVATION_PLAN_ERROR',
  DELETE_MOTIVATION_REQUEST = '@@Workouts/DELETE_MOTIVATION_REQUEST',
  DELETE_MOTIVATION_SUCCESS = '@@Workouts/DELETE_MOTIVATION_SUCCESS',
  DELETE_MOTIVATION_ERROR = '@@Workouts/DELETE_MOTIVATION_ERROR',
  EDIT_MOTIVATION_CATEGORY_REQUEST = '@@Workouts/EDIT_MOTIVATION_CATEGORY_REQUEST',
  EDIT_MOTIVATION_CATEGORY_SUCCESS = '@@Workouts/EDIT_MOTIVATION_CATEGORY_SUCCESS',
  EDIT_MOTIVATION_CATEGORY_ERROR = '@@Workouts/EDIT_MOTIVATION_CATEGORY_ERROR',
}

export interface IMotivationState {
  list: any[];
  loaders: { [key: string]: boolean };
}
