export enum ITrainingPinnedVideoActionsTypes {
  EDIT_TRAINING_PINNED_VIDEO_REQUEST = '@@Workouts/EDIT_TRAINING_PINNED_VIDEO_REQUEST',
  EDIT_TRAINING_PINNED_VIDEO_SUCCESS = '@@Workouts/EDIT_TRAINING_PINNED_VIDEO_SUCCESS',
  EDIT_TRAINING_PINNED_VIDEO_ERROR = '@@Workouts/EDIT_TRAINING_PINNED_VIDEO_ERROR',
  GET_TRAINING_PINNED_VIDEO_REQUEST = '@@Workouts/GET_TRAINING_PINNED_VIDEO_REQUEST',
  GET_TRAINING_PINNED_VIDEO_SUCCESS = '@@Workouts/GET_TRAINING_PINNED_VIDEO_SUCCESS',
  GET_TRAINING_PINNED_VIDEO_ERROR = '@@Workouts/GET_TRAINING_PINNED_VIDEO_ERROR',
}

export interface ITrainingPinnedVideoState {
  data: { [key: string]: any };
  loaders: { [key: string]: boolean };
}
