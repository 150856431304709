import React, { Component } from 'react';
import { Table, Button, Modal, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormComponentProps } from 'antd/lib/form';
import { ContentWrapper } from '../../components/layout/Content';
import {
  ExercisiesPageWrapper,
  Title,
  ButtonGroup,
} from '../../constants/styles';
import { ApplicationState } from '../../store';
import { TableColumns } from './columns';
import { goToPath, goTo } from '../../services/navigator.service';
import routeMap from '../../constants/routeMap';
import {
  getRationItem,
  getPortionItem,
  getMeals,
  editMeals,
  deleteMeals,
} from '../../store/food/actions';
import EditForm from './components/EditForm';

interface IPortionsProps extends FormComponentProps {
  getRationItem: ({ rationId }: { rationId: number }) => void;
  getPortionItem: ({ portionId }: { portionId: number }) => void;
  getMeals: ({ portionId }: { portionId: number }) => void;
  editMeals: (values: { [key: string]: any }) => void;
  deleteMeals: ({
    portionId,
    mealId,
  }: {
    portionId: number;
    mealId: number;
  }) => void;
  match: { [key: string]: any };
  meals: { [key: string]: any };
}

interface IPortionsState {
  modalVisible: boolean;
  modalData: { [key: string]: any };
}

class Portions extends Component<IPortionsProps, IPortionsState> {
  constructor(props: IPortionsProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {},
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { rationId, portionId } = this.props.match.params;
    this.props.getRationItem({ rationId });
    this.props.getPortionItem({ portionId });
    this.props.getMeals({ portionId });
  }

  closeModal() {
    this.setState({ modalVisible: false });
  }

  openModal(data?: { [key: string]: any }) {
    this.setState({ modalVisible: true, modalData: data ? data : {} });
  }

  onSubmit() {
    const { form } = this.props;
    const { portionId } = this.props.match.params;

    const fieldNames = [
      'name',
      'sort',
      'description',
      'calories',
      'video',
      'videoPreview',
      'isPublish'
    ]

    form!.validateFields(fieldNames, { firstFields: fieldNames }, (err: any, values: any) => {
      const data = { ...values, id: this.state.modalData.id }

      if (!err) {
        this.props.editMeals({
          ...data,
          portionId,
          callback: this.closeModal,
        });
      }
    });
  }

  render() {
    const { rationItem, portionItem, loaders } = this.props.meals;
    const { rationId, portionId } = this.props.match.params;
    const MealsTableColumns: ColumnProps<any>[] = [
      ...TableColumns,
      {
        title: (
          <Button
            className="action-button"
            icon="plus-circle"
            htmlType="button"
            title="Добавить блюдо"
            onClick={this.openModal}
          />
        ),
        dataIndex: '',
        key: 'x',
        render: (_: string, data: { [key: string]: any }) => {
          const openModalEdit = () => this.openModal(data);

          const deleteMeals = () =>
            this.props.deleteMeals({
              portionId,
              mealId: data.id,
            });

          return (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="edit"
                htmlType="button"
                title="Редактировать блюдо"
                onClick={openModalEdit}
              />
              <Popconfirm
                title="Вы уверены, что хотите удалить блюдо?"
                onConfirm={deleteMeals}
                placement="rightBottom"
              >
                <Button
                  className="action-button"
                  icon="delete"
                  htmlType="button"
                  title="Удалить блюдо"
                  loading={loaders[`delete/${data.id}`]}
                />
              </Popconfirm>
            </ButtonGroup>
          );
        },
      },
    ];

    const modalTitle = this.state.modalData.id
      ? 'Редактирование блюда'
      : 'Добавление блюда';

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Title>
            <span onClick={goToPath.bind(null, routeMap.rations.path)}>
              Питание
            </span>{' '}
            -{' '}
            <span
              onClick={goTo.bind(null, 'portions', {
                rationId,
              })}
            >
              {rationItem.name}
            </span>{' '}
            - {portionItem.name}
          </Title>
          <Table
            rowKey="id"
            columns={MealsTableColumns}
            loading={loaders.getMeals}
            dataSource={this.props.meals.list}
          />
          <Modal
            maskClosable={false}
            title={modalTitle}
            destroyOnClose={true}
            visible={this.state.modalVisible}
            confirmLoading={loaders.editMeals}
            onCancel={this.closeModal}
            onOk={this.onSubmit}
          >
            <EditForm form={this.props.form} data={this.state.modalData} />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  meals: {
    list: state.food.list,
    loaders: state.food.loaders,
    rationItem: state.food.rationItem,
    portionItem: state.food.portionItem,
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getRationItem: ({ rationId }: { rationId: number }) =>
    dispatch(getRationItem({ rationId })),
  getPortionItem: ({ portionId }: { portionId: number }) =>
    dispatch(getPortionItem({ portionId })),
  getMeals: ({ portionId }: { portionId: number }) =>
    dispatch(getMeals({ portionId })),
  editMeals: (values: { [key: string]: any }) => dispatch(editMeals(values)),
  deleteMeals: ({ portionId, mealId }: { portionId: number; mealId: number }) =>
    dispatch(deleteMeals({ portionId, mealId })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Portions);
