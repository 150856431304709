import React, { Component } from 'react';
import { get } from 'lodash';
import {
  Form,
  Input,
  InputNumber,
  Select,
  Upload,
  Icon,
  Modal,
  Row,
  Button,
  Tabs
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  required,
  NOT_BLANK,
  IS_PICTURE,
  IS_PICTURE_NO_REQUIRED,
  maxLength,
  rangeValue,
  IS_VIMEO_URL,
} from '../../../../constants/validationRules';
import Thumbnail from '../../../../components/Thumbnail';
import RequiredLabel from '../../../../components/RequiredLabel';

interface IExerciseDetailFormProps extends FormComponentProps {
  data?: { [key: string]: any };
  plans: any[];
}

interface IExerciseDetailFormState {
  visible: boolean;
  modalContent: JSX.Element | JSX.Element[] | string;
  extraDescriptionsIds: string[];
  isExtraDescriptions: boolean;
}

const Option = Select.Option;

class ExerciseDetailForm extends Component<
  IExerciseDetailFormProps,
  IExerciseDetailFormState
> {
  constructor(props: IExerciseDetailFormProps) {
    super(props);
    const defaultDescriptionsIds = [];
    const isExtraDescriptions = !!(
      props.data &&
      props.data.extraDescriptions &&
      props.data.extraDescriptions.length
    );

    if (isExtraDescriptions) {
      props.data.extraDescriptions.forEach((item: any) => {
        defaultDescriptionsIds.push(`${item.id}`);
      });
    } else {
      defaultDescriptionsIds.push(`new-${Date.now()}`);
    }

    this.state = {
      isExtraDescriptions,
      visible: false,
      modalContent: '',
      extraDescriptionsIds: defaultDescriptionsIds,
    };
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.upload = this.upload.bind(this);
  }

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  handleChangeImage(file: { [key: string]: any }, field: string) {
    this.props.form.setFieldsValue({ [field]: file });
    this.props.form.validateFields([field]);
    return false;
  }

  onRemoveImage(field: string) {
    this.props.form.setFieldsValue({ [field]: null });
    this.props.form.validateFields([field]);
    return true;
  }

  onPreview(file: { [key: string]: any }, field: string) {
    const { form } = this.props;
    const errorMessages = form.getFieldError(field);
    const formatErrorMessage: string = 'Файл должен быть картинкой';

    if (
      errorMessages &&
      errorMessages.length &&
      errorMessages[0] === formatErrorMessage
    ) {
      return false;
    }

    const modalContent = <img src={file.thumbUrl} />;
    this.setState({ modalContent, visible: true });
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;
    const currentId = this.props.form.getFieldValue('id');

    const availableAfterOptions = this.props.plans.map(
      (item: { [key: string]: any }) => {
        if (currentId) {
          return currentId !== item.id
            ? currentId !== item.isAvailableAfterCompletedPlan && (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              )
            : null;
        } else {
          return currentId !== item.id ? (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ) : null;
        }
      },
    );

    const name = getFieldDecorator(`name`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(23)],
      initialValue: get(this.props.data, 'name', ''),
    })(<Input placeholder="Название" />);

    const name_eng = getFieldDecorator(`name_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, maxLength(23)],
      initialValue: get(this.props.data, 'name_eng', ''),
    })(<Input placeholder="Title" />);

    const sort = getFieldDecorator(`sort`, {
      validateTrigger: 'onSubmit',
      rules: [required, NOT_BLANK, rangeValue(1, 99)],
      initialValue: get(this.props.data, 'sort', ''),
    })(<InputNumber placeholder="Приоритет" style={{ width: '100%' }} />);

    const isAvailableAfterCompletedPlan = getFieldDecorator(
      `isAvailableAfterCompletedPlan`,
      {
        validateTrigger: 'onSubmit',
        rules: [required, NOT_BLANK],
        initialValue:
          get(this.props.data, 'isAvailableAfterCompletedPlan') || 0,
      },
    )(
      <Select>
        <Option key="default" value={0}>
          Доступно всегда
        </Option>
        {availableAfterOptions}
      </Select>,
    );

    const id = getFieldDecorator(`id`, {
      initialValue: get(this.props.data, 'id', null),
    })(<></>);

    const imageSrc = getFieldDecorator(`imageSrc`, {
      initialValue: get(this.props.data, 'image'),
    })(<></>);

    const imageRules = this.props.form.getFieldValue('imageSrc')
      ? []
      : [IS_PICTURE];

    const image = getFieldDecorator(`image`, {
      rules: imageRules,
      initialValue: null,
    })(<></>);

    const image_engSrc = getFieldDecorator(`image_engSrc`, {
      initialValue: get(this.props.data, 'image_eng'),
    })(<></>);

    const imageRulesEng = this.props.form.getFieldValue('image_engSrc')
      ? []
      : [IS_PICTURE];

    const image_eng = getFieldDecorator(`image_eng`, {
      rules: imageRulesEng,
      initialValue: null,
    })(<></>);

    const videoPreviewSrc = getFieldDecorator(`videoPreviewSrc`, {
      initialValue: get(this.props.data, 'videoPreview'),
    })(<></>);

    const previewRules = this.props.form.getFieldValue('videoPreviewSrc')
      ? []
      : [IS_PICTURE];

    const videoPreview = getFieldDecorator(`videoPreview`, {
      rules: previewRules,
      initialValue: null,
    })(<></>);

    const videoPreview_engSrc = getFieldDecorator(`videoPreview_engSrc`, {
      initialValue: get(this.props.data, 'videoPreview_eng'),
    })(<></>);

    const previewRulesEng = this.props.form.getFieldValue('videoPreview_engSrc')
      ? []
      : [IS_PICTURE];

    const videoPreview_eng = getFieldDecorator(`videoPreview_eng`, {
      rules: previewRulesEng,
      initialValue: null,
    })(<></>);

    const video = getFieldDecorator(`video`, {
      validateTrigger: 'onSubmit',
      rules: [required, IS_VIMEO_URL],
      initialValue: get(this.props.data, 'video', ''),
    })(<Input placeholder="Видео курса" />);

    const video_eng = getFieldDecorator(`video_eng`, {
      validateTrigger: 'onSubmit',
      rules: [required, IS_VIMEO_URL],
      initialValue: get(this.props.data, 'video_eng', ''),
    })(<Input placeholder="Video of the course" />);

    const extraDescriptionsTitles = this.state.extraDescriptionsIds.map(key => {
      const thisKey = key.includes('new') ? key.split('new-')[1] : key;
      const thisDescr = this.state.isExtraDescriptions
        ? this.props.data.extraDescriptions.find((item: any) => `${item.id}` === thisKey)
        : {};
      const initialValue = thisDescr ? thisDescr.title : '';
      return getFieldDecorator(`extraDescriptions.title-${thisKey}`, {
        validateTrigger: 'onSubmit',
        rules: [NOT_BLANK, maxLength(100)],
        initialValue: initialValue,
      })(<Input placeholder="Заголовок" />);
    });

    const extraDescriptionsTitlesEng = this.state.extraDescriptionsIds.map(key => {
      const thisKey = key.includes('new') ? key.split('new-')[1] : key;
      const thisDescr = this.state.isExtraDescriptions
        ? this.props.data.extraDescriptions.find((item: any) => `${item.id}` === thisKey)
        : {};
      const initialValue = thisDescr ? thisDescr.title_eng : '';
      return getFieldDecorator(`extraDescriptions.title_eng-${thisKey}`, {
        validateTrigger: 'onSubmit',
        rules: [NOT_BLANK, maxLength(100)],
        initialValue: initialValue,
      })(<Input placeholder="Title" />);
    });

    const extraDescriptionsDescriptions = this.state.extraDescriptionsIds.map(
      key => {
        const thisKey = key.includes('new') ? key.split('new-')[1] : key;
        const thisDescr = this.state.isExtraDescriptions
          ? this.props.data.extraDescriptions.find((item: any) => `${item.id}` === thisKey)
          : {};
        const initialValue = thisDescr ? thisDescr.description : '';
        return getFieldDecorator(`extraDescriptions.description-${thisKey}`, {
          validateTrigger: 'onSubmit',
          rules: [required, NOT_BLANK, maxLength(1000)],
          initialValue: initialValue,
        })(<Input.TextArea placeholder="Описание" />);
      },
    );

    const extraDescriptionsDescriptionsEng = this.state.extraDescriptionsIds.map(
      key => {
        const thisKey = key.includes('new') ? key.split('new-')[1] : key;
        const thisDescr = this.state.isExtraDescriptions
          ? this.props.data.extraDescriptions.find((item: any) => `${item.id}` === thisKey)
          : {};
        const initialValue = thisDescr ? thisDescr.description_eng : '';
        return getFieldDecorator(`extraDescriptions.description_eng-${thisKey}`, {
          validateTrigger: 'onSubmit',
          rules: [required, NOT_BLANK, maxLength(1000)],
          initialValue: initialValue,
        })(<Input.TextArea placeholder="Description" />);
      },
    );

    const extraDescriptionsImageSrc = this.state.extraDescriptionsIds.map(
      key => {
        const thisKey = key.includes('new') ? key.split('new-')[1] : key;
        const thisDescr = this.state.isExtraDescriptions
          ? this.props.data.extraDescriptions.find((item: any) => `${item.id}` === thisKey)
          : {};
        const initialValue = thisDescr ? thisDescr.image : '';
        return getFieldDecorator(`extraDescriptions.image-${thisKey}Src`, {
          initialValue: initialValue,
        })(<></>);
      },
    );

    const extraDescriptionsImageEngSrc = this.state.extraDescriptionsIds.map(
      key => {
        const thisKey = key.includes('new') ? key.split('new-')[1] : key;
        const thisDescr = this.state.isExtraDescriptions
          ? this.props.data.extraDescriptions.find((item: any) => `${item.id}` === thisKey)
          : {};
        const initialValue = thisDescr ? thisDescr.image_eng : '';
        return getFieldDecorator(`extraDescriptions.image_eng-${thisKey}Src`, {
          initialValue: initialValue,
        })(<></>);
      },
    );

    const extraDescriptionImage = this.state.extraDescriptionsIds.map(key => {
      const thisKey = key.includes('new') ? key.split('new-')[1] : key;
      return getFieldDecorator(`extraDescriptions.image-${thisKey}`, {
        rules: [IS_PICTURE_NO_REQUIRED],
        initialValue: null,
      })(<></>);
    });

    const extraDescriptionImageEng = this.state.extraDescriptionsIds.map(key => {
      const thisKey = key.includes('new') ? key.split('new-')[1] : key;
      return getFieldDecorator(`extraDescriptions.image_eng-${thisKey}`, {
        rules: [IS_PICTURE_NO_REQUIRED],
        initialValue: null,
      })(<></>);
    });

    const extraDescriptionsIds = getFieldDecorator(`extraDescriptionsIds`, {
      initialValue: this.state.extraDescriptionsIds,
    });

    const extraDescriptionsToDelete = getFieldDecorator(
      `extraDescriptionsToDelete`,
      {
        initialValue: [],
      },
    );

    const extraImgToDelete = getFieldDecorator(`extraImgToDelete`, {
      initialValue: [],
    });

    return {
      id,
      name,
      name_eng,
      sort,
      isAvailableAfterCompletedPlan,
      image,
      image_eng,
      imageSrc,
      image_engSrc,
      video,
      video_eng,
      videoPreviewSrc,
      videoPreview_engSrc,
      videoPreview,
      videoPreview_eng,
      extraDescriptionsTitles,
      extraDescriptionsTitlesEng,
      extraDescriptionsDescriptions,
      extraDescriptionsDescriptionsEng,
      extraDescriptionsImageSrc,
      extraDescriptionsImageEngSrc,
      extraDescriptionImage,
      extraDescriptionImageEng,
      extraDescriptionsIds,
      extraDescriptionsToDelete,
      extraImgToDelete,
    };
  }

  upload(field: string) {
    const uploadButton = this.props.form.getFieldValue(field) ? null : (
      <>
        <Icon type={'plus'} />
        <div className="ant-upload-text">Загрузить</div>
      </>
    );

    let fieldName = `${field}Src`;
    if (field.includes('extraDescriptions.image-')) fieldName = `extraDescriptionsImageSrc`;
    if (field.includes('extraDescriptions.image_eng-')) fieldName = `extraDescriptionsImageEngSrc`;

    let thisFieldSrc = this.fields[fieldName];
    if (field.includes('extra')) {
      thisFieldSrc = this.fields[fieldName].find((item: any) => item.props.id.includes(field));
    }

    let imageHref = thisFieldSrc.props.value;

    const onRemoveThumbnail = () => {
      this.props.form.setFieldsValue({ [`${field}Src`]: null });
      if (field.includes('extra')) {
        const id = field.split('-')[1];
        if (this.state.isExtraDescriptions) {
          const currentExtra = this.props.data.extraDescriptions.find(
            (item: any) => item.id === +id,
          );
          if (currentExtra && currentExtra.image && !field.includes('_eng')) {
            const extraImgToDelete = this.props.form.getFieldValue('extraImgToDelete');
            this.props.form.setFieldsValue({
              extraImgToDelete: [...extraImgToDelete, id],
            });
          }
          if (currentExtra && currentExtra.image_eng && field.includes('_eng')) {
            const extraImgToDelete = this.props.form.getFieldValue('extraImgToDelete');
            this.props.form.setFieldsValue({
              extraImgToDelete: [...extraImgToDelete, id + 'eng'],
            });
          }
        }
      }
    };

    const onPreviewThumbnail = () => {
      const { form } = this.props;
      const errorMessages = form.getFieldError(field);
      const formatErrorMessage: string = 'Файл должен быть картинкой';

      if (
        errorMessages &&
        errorMessages.length &&
        errorMessages[0] === formatErrorMessage
      ) {
        return false;
      }

      const modalContent = <img src={imageHref} />;
      this.setState({ modalContent, visible: true });
    };

    let thisField = this.fields[field];

    if (field.includes('extraDescriptions.image-')) {
      thisField = this.fields.extraDescriptionImage.find((item: any) => item.props.id === field);
    }
    if (field.includes('extraDescriptions.image_eng-')) {
      thisField = this.fields.extraDescriptionImageEng.find((item: any) => item.props.id === field);
    }

    if (field.includes('extra')) {
      thisFieldSrc = this.fields[fieldName].find((item: any) =>
        item.props.id.includes(field),
      );
    }

    const thumbnail = imageHref ? (
      <Thumbnail
        src={imageHref}
        onRemove={onRemoveThumbnail}
        onPreview={onPreviewThumbnail}
      />
    ) : (
      <>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          beforeUpload={file => this.handleChangeImage(file, field)}
          onRemove={() => this.onRemoveImage(field)}
          onPreview={file => this.onPreview(file, field)}
        >
          {uploadButton}
        </Upload>
        {thisField}
      </>
    );

    return thumbnail;
  }

  render() {
    const extraDescriptionsFields = this.state.extraDescriptionsIds.map(key => {
      const thisKey = key.includes('new') ? key.split('new-')[1] : key;
      const currentTitle = this.fields.extraDescriptionsTitles.find(item =>
        // @ts-ignore
        item.props.id.includes(thisKey),
      );
      const currentTitleEng = this.fields.extraDescriptionsTitlesEng.find(item =>
        // @ts-ignore
        item.props.id.includes(thisKey),
      );
      const currentDescription = this.fields.extraDescriptionsDescriptions.find(
        // @ts-ignore
        item => item.props.id.includes(thisKey),
      );
      const currentDescriptionEng = this.fields.extraDescriptionsDescriptionsEng.find(
        // @ts-ignore
        item => item.props.id.includes(thisKey),
      );

      const removeThisKey = () => {
        const keys = this.state.extraDescriptionsIds.filter(eId => {
          const format_eId = eId.includes('new-') ? eId.split('new-')[1] : eId;
          const format_key = key.includes('new-') ? key.split('new-')[1] : key;
          return format_eId !== format_key;
        });
        this.setState({ extraDescriptionsIds: keys });
        if (!key.includes('new')) {
          const idsToDelete = this.props.form.getFieldValue(
            'extraDescriptionsToDelete',
          );
          this.props.form.setFieldsValue({
            extraDescriptionsToDelete: [...idsToDelete, key],
          });
        }
        this.props.form.setFieldsValue({ extraDescriptionsIds: keys });
      };

      const deleteBtn =
        this.state.extraDescriptionsIds.length > 1 ? (
          <Form.Item>
            <Button type="danger" onClick={removeThisKey}>
              Удалить
            </Button>
          </Form.Item>
        ) : null;

      return (
        <div
          style={{ borderBottom: '1px solid #d9d9d9', marginBottom: '15px' }}
        >
          <Form.Item label="Заголовок">{currentTitle}</Form.Item>
          <Form.Item label="Title">{currentTitleEng}</Form.Item>
          <Form.Item label="Описание">{currentDescription}</Form.Item>
          <Form.Item label="Description">{currentDescriptionEng}</Form.Item>
          <Form.Item label="Изображение">
            {this.upload(`extraDescriptions.image-${thisKey}`)}
          </Form.Item>
          <Form.Item label="Image">
            {this.upload(`extraDescriptions.image_eng-${thisKey}`)}
          </Form.Item>
          {deleteBtn}
        </div>
      );
    });

    const addExtraId = () => {
      const keys = this.state.extraDescriptionsIds;
      this.setState({ extraDescriptionsIds: [...keys, `new-${Date.now()}`] });
      this.props.form.setFieldsValue({
        extraDescriptionsIds: [...keys, `new-${Date.now()}`],
      });
    };

    const addBtn =
      this.state.extraDescriptionsIds.length < 15 ? (
        <Button type="primary" onClick={addExtraId}>
          Добавить блок описания
        </Button>
      ) : null;

    return (
      <Form>
        <Tabs defaultActiveKey="1" centered>
          <Tabs.TabPane tab="Общие настройки" key="1">
            <Form.Item label="Название">{this.fields.name}</Form.Item>
            <Form.Item label="Приоритет">{this.fields.sort}</Form.Item>
            <Form.Item label="Доступно после прохождения">
              {this.fields.isAvailableAfterCompletedPlan}
            </Form.Item>
            <Form.Item label={<RequiredLabel text="Изображение" />}>
              {this.upload('image')}
            </Form.Item>
            <Form.Item label="Видео курса">{this.fields.video}</Form.Item>
            <Form.Item label={<RequiredLabel text="Превью видео курса" />}>
              {this.upload('videoPreview')}
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Английский вариант" key="2">
            <Form.Item label="Title">{this.fields.name_eng}</Form.Item>
            <Form.Item label={<RequiredLabel text="Image" />}>
              {this.upload('image_eng')}
            </Form.Item>
            <Form.Item label="Video of the course">{this.fields.video_eng}</Form.Item>
            <Form.Item label={<RequiredLabel text="Preview of the course video" />}>
              {this.upload('videoPreview_eng')}
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Блоки описания" key="3">
            <h3 style={{ textAlign: 'center' }}>Блоки описания</h3>
            <Row>{extraDescriptionsFields}</Row>
            {addBtn}
          </Tabs.TabPane>
        </Tabs>
        <Modal
          footer={null}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
        >
          {this.state.modalContent}
        </Modal>
      </Form>
    );
  }
}

export default ExerciseDetailForm;
