export enum IFreeExercisesActionsTypes {
  // EXERCISE
  GET_FREE_EXERCISE_REQUEST = '@@FreeExercises/GET_FREE_EXERCISE_REQUEST',
  GET_FREE_EXERCISE_SUCCESS = '@@FreeExercises/GET_FREE_EXERCISE_SUCCESS',
  GET_FREE_EXERCISE_ERROR = '@@FreeExercises/GET_FREE_EXERCISE_ERROR',
  EDIT_FREE_EXERCISE_REQUEST = '@@FreeExercises/EDIT_FREE_EXERCISE_REQUEST',
  EDIT_FREE_EXERCISE_SUCCESS = '@@FreeExercises/EDIT_FREE_EXERCISE_SUCCESS',
  EDIT_FREE_EXERCISE_ERROR = '@@FreeExercises/EDIT_FREE_EXERCISE_ERROR',
  DELETE_FREE_EXERCISE_REQUEST = '@@FreeExercises/DELETE_FREE_EXERCISE_REQUEST',
  DELETE_FREE_EXERCISE_SUCCESS = '@@FreeExercises/DELETE_FREE_EXERCISE_SUCCESS',
  DELETE_FREE_EXERCISE_ERROR = '@@FreeExercises/DELETE_FREE_EXERCISE_ERROR',
}

export interface IFreeExercisesState {
  data: any;
  loaders: { [key: string]: boolean };
}
