import * as React from 'react';
import { Button, Modal, Table, Popconfirm, message } from 'antd';
import { ExercisiesTableColumns } from './columns';
import ExerciseFormComponent from './exercise-form';
import { FormProps } from 'antd/lib/form';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  closeExerciseForm,
  createExerciseRequest,
  getExercisies,
  deleteExerciseRequest,
  showExerciseForm,
  updateExerciseRequest,
} from '../../store/exercisies/actions';
import {
  Exercise,
  ExerciseForm,
  ExercisiesState,
} from '../../store/exercisies/types';
import { goTo } from '../../services/navigator.service';
import { ApplicationState } from '../../store';
import { ContentWrapper } from '../../components/layout/Content';
import {
  ButtonGroup,
  ExercisiesPageWrapper,
  Title,
} from '../../constants/styles';

interface PropsFromDispatch {
  showExerciseForm: typeof showExerciseForm;
  closeExerciseForm: typeof closeExerciseForm;
  getExercisies: typeof getExercisies;
  createExerciseRequest: typeof createExerciseRequest;
  deleteExerciseRequest: typeof deleteExerciseRequest;
  updateExerciseRequest: typeof updateExerciseRequest;
}

type FormModuleRef = React.Component<FormProps>;

type AllProps = ExercisiesState & PropsFromDispatch;

class ExercisiesPage extends React.Component<AllProps> {
  public rows = [
    ...ExercisiesTableColumns,
    {
      title: (
        <Button
          className="action-button"
          icon="plus-circle"
          htmlType="button"
          title="Добавить категорию упражнений"
          onClick={() => this.props.showExerciseForm({ isEdit: false })}
        />
      ),
      dataIndex: '',
      key: 'x',
      render: (_: string, ExerciseData: Exercise) => {
        const onDelete = () =>
          this.props.deleteExerciseRequest({ id: ExerciseData.id });

        return (
          <ButtonGroup>
            <Button
              className="action-button"
              icon="read"
              htmlType="button"
              title="Перейти к упражнениям"
              onClick={goTo.bind(null, 'exercisesDetail', {
                exerciseId: `${ExerciseData.id}`,
              })}
            />
            <Button
              className="action-button"
              icon="edit"
              title="Редактировать категорию упражнений"
              htmlType="button"
              onClick={() =>
                this.props.showExerciseForm({ isEdit: true, ExerciseData })
              }
            />
            <Popconfirm
              title="Вы уверены, что хотите удалить категорию упражнений?"
              onConfirm={onDelete}
              placement="rightBottom"
            >
              <Button
                title="Удалить категорию упражнений"
                className="action-button"
                icon="delete"
                htmlType="button"
                loading={
                  this.props.ExerciseDeleteLoaders[ExerciseData.id || -1]
                }
              />
            </Popconfirm>
          </ButtonGroup>
        );
      },
    },
  ];

  public componentDidMount() {
    this.props.getExercisies();
  }

  private ExerciseFormRef!: FormModuleRef;

  public handleOk() {
    const { form } = this.ExerciseFormRef.props;
    const { createExerciseRequest, updateExerciseRequest } = this.props;
    form!.validateFields((err: any, values: any) => {
      const { isRest, isGroup } = values
      values = {
        ...values, 
        type: isRest
          ? 'rest'
          : isGroup ? 'group' : 'exercise'
      }
      if (!err) {
        if (values.id) {
          updateExerciseRequest(values);
        } else {
          createExerciseRequest(values);
        }
      };
      if (err) {
        message.error('Правильно заполните поля во всех вкладках');
      };
    });
  }

  public setExerciseFormRef = (formRef: FormModuleRef) =>
    (this.ExerciseFormRef = formRef);

  render() {
    const {
      ExerciseFormOpen,
      ExerciseForm,
      closeExerciseForm,
      Exercisies,
      ExercisiesIsLoading,
      ExerciseFormProcessing,
    } = this.props;

    const modalTitle =
      ExerciseForm && ExerciseForm.isEdit
        ? 'Редактирование категории упражнения'
        : 'Добавление категории упражнения';

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Title>Категории упражнений</Title>
          <Table
            rowKey="id"
            loading={ExercisiesIsLoading}
            columns={this.rows}
            dataSource={Exercisies}
          />
          <Modal
            title={modalTitle}
            destroyOnClose={true}
            visible={ExerciseFormOpen}
            maskClosable={false}
            onOk={() => this.handleOk()}
            confirmLoading={ExerciseFormProcessing}
            onCancel={closeExerciseForm}
          >
            {ExerciseFormOpen ? (
              <ExerciseFormComponent
                wrappedComponentRef={this.setExerciseFormRef}
                ExerciseFormProcessing={ExerciseFormProcessing}
                ExerciseData={ExerciseForm!.ExerciseData}
              />
            ) : null}
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  ExerciseFormOpen: state.exercisies.ExerciseFormOpen,
  ExerciseForm: state.exercisies.ExerciseForm,
  Exercisies: state.exercisies.Exercisies,
  ExercisiesIsLoading: state.exercisies.ExercisiesIsLoading,
  ExerciseLoadingError: state.exercisies.ExercisiesLoadingError,
  ExerciseFormProcessing: state.exercisies.ExerciseFormProcessing,
  ExerciseDeleteLoaders: state.exercisies.ExerciseDeleteLoaders,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showExerciseForm: (data: ExerciseForm) => dispatch(showExerciseForm(data)),
  closeExerciseForm: () => dispatch(closeExerciseForm()),
  deleteExerciseRequest: ({ id }: { id: number }) =>
    dispatch(deleteExerciseRequest({ id })),
  getExercisies: () => dispatch(getExercisies()),
  createExerciseRequest: (Exercise: Exercise) =>
    dispatch(createExerciseRequest(Exercise)),
  updateExerciseRequest: (Exercise: Exercise) =>
    dispatch(updateExerciseRequest(Exercise)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExercisiesPage);
