import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { IFreeCoursesActionsTypes } from '../types';
import { callApi } from '../../../utils/api';
import {
  getFreeTrainings,
  getFreeTrainingsError,
  getFreeTrainingsSuccess,
  editFreeTrainingError,
  editFreeTrainingSuccess,
  deleteFreeTraining,
  deleteFreeTrainingError,
  deleteFreeTrainingSuccess,
  editFreeTraining
} from '../actions';
import { getAuthToken } from '../../utils';

function* handleGetFreeTrainingsRequest(action: ReturnType<typeof getFreeTrainings>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const freeDayId = action.payload;
  const response = yield call(callApi, 'get', `admin/free_days/${freeDayId}/trainings`, token);
  if (response.error) {
    yield put(getFreeTrainingsError(response));
  } else {
    yield put(getFreeTrainingsSuccess(response));
  }
}

function* handleDeleteFreeTrainingRequest(
  action: ReturnType<typeof deleteFreeTraining>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const { freeTrainingId, freeDayId } = action.payload;
  const response = yield call(
    callApi,
    'delete',
    `admin/free_trainings/${freeTrainingId}`,
    token,
  );
  if (response.error) {
    yield put(deleteFreeTrainingError(response, freeTrainingId));
  } else {
    yield put(deleteFreeTrainingSuccess({ freeTrainingId }));
    yield put(getFreeTrainings(freeDayId));
  }
}

function* createFreeTraining(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'post',
    `admin/free_days/${values.freeDayId}/trainings`,
    token,
    values
  );

  if (response.error) {
    yield put(editFreeTrainingError(response));
  } else {
    yield put(editFreeTrainingSuccess());
    yield put(getFreeTrainings(values.freeDayId));
    values.callback();
  }
}

function* editFreeTrainingRequest(token: string, values: { [key: string]: any }) {
  const response = yield call(
    callApi,
    'put',
    `admin/free_trainings/${values.id}`,
    token,
    { ...values },
  );

  if (response.error) {
    yield put(editFreeTrainingError(response));
  } else {
    yield put(editFreeTrainingSuccess());
    yield put(getFreeTrainings(values.freeDayId));
    if (values.callback) {
      values.callback();
    }
  }
}

function* handleEditFreeDayRequest(
  action: ReturnType<typeof editFreeTraining>,
) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield editFreeTrainingRequest(token, action.payload);
  } else {
    yield createFreeTraining(token, action.payload);
  }
}

export function* watchDeleteFreeTrainingRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.DELETE_FREE_TRAINING_REQUEST,
    handleDeleteFreeTrainingRequest,
  );
}

export function* watchGetFreeTrainingsRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.GET_FREE_TRAININGS_REQUEST,
    handleGetFreeTrainingsRequest,
  );
}

export function* watchEditFreeTrainingRequest() {
  yield takeLatest(
    IFreeCoursesActionsTypes.EDIT_FREE_TRAINING_REQUEST,
    handleEditFreeDayRequest,
  );
}