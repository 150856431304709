import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { IFoodActionsTypes } from './types';
import { callApi } from '../../utils/api';
import { message } from 'antd';
import {
  getMeals,
  getMealsError,
  getMealsSuccess,
  editMeals,
  editMealsError,
  editMealsSuccess,
  deleteMeals,
  deleteMealsError,
  deleteMealsSuccess,
} from './actions';
import { pick } from 'lodash';
import { AuthActionTypes } from '../auth/types';
import { getToken, isTokenRefreshing } from '../auth/selectors';
import { CONTENT_TYPES } from '../../utils/api';

function* getAuthToken() {
  const isRefreshing = yield select(isTokenRefreshing);
  if (isRefreshing) {
    const { fail } = yield race({
      success: take(AuthActionTypes.TOKEN_REFRESH_SUCCESS),
      fail: take(AuthActionTypes.TOKEN_REFRESH_ERROR),
    });
    if (fail) {
      return null;
    }
  }
  return yield select(getToken);
}

function* handleGetMealsRequest(action: ReturnType<typeof getMeals>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const { portionId } = action.payload;
  const response = yield call(
    callApi,
    'get',
    `admin/portions/${portionId}/meals`,
    token,
  );
  if (response.error) {
    yield put(getMealsError(response));
  } else {
    yield put(getMealsSuccess(response));
  }
}

function* handleUploadMealsPreviewVideo({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { id } = data;
  const response = yield call(
    callApi,
    'post',
    `admin/meals/${id}/video-preview`,
    token,
    { videoPreview: data.videoPreview },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* updateMeals(token: string, values: { [key: string]: any }) {
  const updValues = pick(values, [
    'name',
    'description',
    'calories',
    'isPublish',
    'sort',
    'video',
  ]);
  const { portionId, id } = values;
  const response = yield call(
    callApi,
    'put',
    `admin/meals/${id}`,
    token,
    updValues,
  );

  if (response.error) {
    yield put(editMealsError(response));
  } else {
    if (values.videoPreview) {
      yield handleUploadMealsPreviewVideo({
        token,
        data: { ...values, id },
      });
    }
    yield put(editMealsSuccess(response));
    yield put(getMeals({ portionId }));
    values.callback();
  }
}

function* createMeals(token: string, values: { [key: string]: any }) {
  const updValues = pick(values, [
    'name',
    'description',
    'calories',
    'isPublish',
    'sort',
    'video',
  ]);
  const { portionId } = values;
  const response = yield call(
    callApi,
    'post',
    `admin/portions/${portionId}/meals`,
    token,
    updValues,
  );

  if (response.error) {
    yield put(editMealsError(response));
  } else {
    const id = response.data.id;
    yield handleUploadMealsPreviewVideo({
      token,
      data: { ...values, id },
    });
    yield put(editMealsSuccess(response));
    yield put(getMeals({ portionId }));
    values.callback();
  }
}

function* handleEditMealsRequest(action: ReturnType<typeof editMeals>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }

  if (action.payload.id) {
    yield updateMeals(token, action.payload);
  } else {
    yield createMeals(token, action.payload);
  }
}

function* handleDeleteMealsRequest(action: ReturnType<typeof deleteMeals>) {
  const { portionId, mealId } = action.payload;
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'delete',
    `admin/meals/${mealId}`,
    token,
  );

  if (response.error) {
    yield put(deleteMealsError(response, portionId));
  } else {
    yield put(deleteMealsSuccess({ mealId }));
    yield put(getMeals({ portionId }));
  }
}

export function* watchGetMealsRequest() {
  yield takeLatest(IFoodActionsTypes.GET_MEALS_REQUEST, handleGetMealsRequest);
}

export function* watchDeleteMealsRequest() {
  yield takeLatest(
    IFoodActionsTypes.DELETE_MEALS_REQUEST,
    handleDeleteMealsRequest,
  );
}

export function* watchEditMealsRequest() {
  yield takeLatest(
    IFoodActionsTypes.EDIT_MEALS_REQUEST,
    handleEditMealsRequest,
  );
}
