export enum IWorkoutsActionsTypes {
  GET_WORKOUTS_DAY_REQUEST = '@@Workouts/GET_WORKOUTS_DAY_REQUEST',
  GET_WORKOUTS_DAY_SUCCESS = '@@Workouts/GET_WORKOUTS_DAY_SUCCESS',
  GET_WORKOUTS_DAY_ERROR = '@@Workouts/GET_WORKOUTS_DAY_ERROR',
  GET_WORKOUTS_WEEK_REQUEST = '@@Workouts/GET_WORKOUTS_WEEK_REQUEST',
  GET_WORKOUTS_WEEK_SUCCESS = '@@Workouts/GET_WORKOUTS_WEEK_SUCCESS',
  GET_WORKOUTS_WEEK_ERROR = '@@Workouts/GET_WORKOUTS_WEEK_ERROR',
  GET_WORKOUTS_PLAN_REQUEST = '@@Workouts/GET_WORKOUTS_PLAN_REQUEST',
  GET_WORKOUTS_PLAN_SUCCESS = '@@Workouts/GET_WORKOUTS_PLAN_SUCCESS',
  GET_WORKOUTS_PLAN_ERROR = '@@Workouts/GET_WORKOUTS_PLAN_ERROR',
  GET_WORKOUTS_REQUEST = '@@Workouts/GET_WORKOUTS_REQUEST',
  GET_WORKOUTS_SUCCESS = '@@Workouts/GET_WORKOUTS_SUCCESS',
  GET_WORKOUTS_ERROR = '@@Workouts/GET_WORKOUTS_ERROR',
  EDIT_WORKOUTS_REQUEST = '@@Workouts/EDIT_WORKOUTS_REQUEST',
  EDIT_WORKOUTS_SUCCESS = '@@Workouts/EDIT_WORKOUTS_SUCCESS',
  EDIT_WORKOUTS_ERROR = '@@Workouts/EDIT_WORKOUTS_ERROR',
  EDIT_WORKOUTS_PLAN_REQUEST = '@@Workouts/EDIT_WORKOUTS_PLAN_REQUEST',
  EDIT_WORKOUTS_PLAN_SUCCESS = '@@Workouts/EDIT_WORKOUTS_PLAN_SUCCESS',
  EDIT_WORKOUTS_PLAN_ERROR = '@@Workouts/EDIT_WORKOUTS_PLAN_ERROR',
  DELETE_WORKOUTS_PLAN_REQUEST = '@@Workouts/DELETE_WORKOUTS_PLAN_REQUEST',
  DELETE_WORKOUTS_PLAN_SUCCESS = '@@Workouts/DELETE_WORKOUTS_PLAN_SUCCESS',
  DELETE_WORKOUTS_PLAN_ERROR = '@@Workouts/DELETE_WORKOUTS_PLAN_ERROR',
  EDIT_WORKOUTS_WEEK_REQUEST = '@@Workouts/EDIT_WORKOUTS_WEEK_REQUEST',
  EDIT_WORKOUTS_WEEK_SUCCESS = '@@Workouts/EDIT_WORKOUTS_WEEK_SUCCESS',
  EDIT_WORKOUTS_WEEK_ERROR = '@@Workouts/EDIT_WORKOUTS_WEEK_ERROR',
  DELETE_WORKOUTS_WEEK_REQUEST = '@@Workouts/DELETE_WORKOUTS_WEEK_REQUEST',
  DELETE_WORKOUTS_WEEK_SUCCESS = '@@Workouts/DELETE_WORKOUTS_WEEK_SUCCESS',
  DELETE_WORKOUTS_WEEK_ERROR = '@@Workouts/DELETE_WORKOUTS_WEEK_ERROR',
  COPY_WORKOUTS_WEEK_REQUEST = '@@Workouts/COPY_WORKOUTS_WEEK_REQUEST',
  COPY_WORKOUTS_WEEK_SUCCESS = '@@Workouts/COPY_WORKOUTS_WEEK_SUCCESS',
  COPY_WORKOUTS_WEEK_ERROR = '@@Workouts/COPY_WORKOUTS_WEEK_ERROR',
  EDIT_WORKOUTS_DAY_REQUEST = '@@Workouts/EDIT_WORKOUTS_DAY_REQUEST',
  EDIT_WORKOUTS_DAY_SUCCESS = '@@Workouts/EDIT_WORKOUTS_DAY_SUCCESS',
  EDIT_WORKOUTS_DAY_ERROR = '@@Workouts/EDIT_WORKOUTS_DAY_ERROR',
}

export interface IWorkoutsState {
  list: any[];
  plans: any[];
  weeks: any[];
  day: any;
  loaders: { [key: string]: boolean };
}
