import { IFreeCoursesActionsTypes } from './types';
import { action } from 'typesafe-actions';
import { ApiResponse } from '../../utils/api';

// GET FREE COURSES

export const getFreeCourses = () =>
  action(IFreeCoursesActionsTypes.GET_FREE_COURSES_REQUEST);

export const getFreeCoursesSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IFreeCoursesActionsTypes.GET_FREE_COURSES_SUCCESS,
    response.data,
    response.code,
  );

export const getFreeCoursesError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFreeCoursesActionsTypes.GET_FREE_COURSES_ERROR, {
    error: response.error,
    code: response.code,
  });

// EDIT FREE COURSES

export const editFreeCourses = (values: { [key: string]: any }) =>
  action(IFreeCoursesActionsTypes.EDIT_FREE_COURSES_REQUEST, values);

export const editFreeCoursesSuccess = () => action(IFreeCoursesActionsTypes.EDIT_FREE_COURSES_SUCCESS);

export const editFreeCoursesError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IFreeCoursesActionsTypes.EDIT_FREE_COURSES_ERROR, {
    error: response.error,
    code: response.code,
  });

// GET FREE PLANS

export const getFreePlans = (freeCoursesId: number) => action(IFreeCoursesActionsTypes.GET_FREE_PLANS_REQUEST, freeCoursesId);

export const getFreePlansSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(
  IFreeCoursesActionsTypes.GET_FREE_PLANS_SUCCESS,
  response.data,
  response.code,
);

export const getFreePlansError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(IFreeCoursesActionsTypes.GET_FREE_PLANS_ERROR, {
  error: response.error,
  code: response.code,
});

// EDIT FREE PLAN

export const editFreePlan = (values: { [key: string]: any }) => action(IFreeCoursesActionsTypes.EDIT_FREE_PLAN_REQUEST, values);

export const editFreePlanSuccess = () => action(IFreeCoursesActionsTypes.EDIT_FREE_PLAN_SUCCESS);

export const editFreePlanError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(IFreeCoursesActionsTypes.EDIT_FREE_PLAN_ERROR, {
  error: response.error,
  code: response.code,
});

// DELETE FREE PLAN

export const deleteFreePlan = ({
  freePlanId,
  freeCoursesId,
}: {
  freePlanId: number,
  freeCoursesId: number,
}) => action(IFreeCoursesActionsTypes.DELETE_FREE_PLAN_REQUEST, { freePlanId, freeCoursesId });

export const deleteFreePlanSuccess = ({ freePlanId }: { freePlanId: number }) => action(IFreeCoursesActionsTypes.DELETE_FREE_PLAN_SUCCESS, freePlanId);

export const deleteFreePlanError = (
  response: ApiResponse<{ [key: string]: any }>,
  freePlanId: number,
) =>
action(IFreeCoursesActionsTypes.DELETE_FREE_PLAN_ERROR, {
  error: response.error,
  code: response.code,
  freePlanId,
});

// GET FREE WEEKS

export const getFreeCoursesWeeks = (
  freePlanId: number
) => action(IFreeCoursesActionsTypes.GET_FREE_COURSES_WEEKS_REQUEST, freePlanId);

export const getFreeCoursesWeeksSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(
  IFreeCoursesActionsTypes.GET_FREE_COURSES_WEEKS_SUCCESS,
  response.data,
  response.code,
);

export const getFreeCoursesWeeksError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(IFreeCoursesActionsTypes.GET_FREE_COURSES_WEEKS_ERROR, {
  error: response.error,
  code: response.code,
});

// EDIT FREE COURSES WEEK

export const editFreeCoursesWeek = (
  values: { [key: string]: any }
) => action(IFreeCoursesActionsTypes.EDIT_FREE_COURSES_WEEK_REQUEST, values);

export const editFreeCoursesWeekSuccess = () => action(IFreeCoursesActionsTypes.EDIT_FREE_COURSES_WEEK_SUCCESS);

export const editFreeCoursesWeekError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(IFreeCoursesActionsTypes.EDIT_FREE_COURSES_WEEK_ERROR, {
  error: response.error,
  code: response.code,
});

// DELETE FREE PLAN

export const deleteFreeCoursesWeek = ({
  freePlanId,
  freeCoursesWeekId,
}: {
  freePlanId: number,
  freeCoursesWeekId: number,
}) => action(IFreeCoursesActionsTypes.DELETE_FREE_COURSES_WEEK_REQUEST, { freePlanId, freeCoursesWeekId });

export const deleteFreeCoursesWeekSuccess = ({ freeCoursesWeekId }: { freeCoursesWeekId: number }) => action(IFreeCoursesActionsTypes.DELETE_FREE_COURSES_WEEK_SUCCESS, freeCoursesWeekId);

export const deleteFreeCoursesWeekError = (
  response: ApiResponse<{ [key: string]: any }>,
  freeCoursesWeekId: number,
) =>
action(IFreeCoursesActionsTypes.DELETE_FREE_COURSES_WEEK_ERROR, {
  error: response.error,
  code: response.code,
  freeCoursesWeekId,
});

// GET FREE DAYS

export const getFreeDays = (
  freeWeekId: number
) => action(IFreeCoursesActionsTypes.GET_FREE_DAYS_REQUEST, freeWeekId);

export const getFreeDaysSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(
  IFreeCoursesActionsTypes.GET_FREE_DAYS_SUCCESS,
  response.data,
  response.code,
);

export const getFreeDaysError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(IFreeCoursesActionsTypes.GET_FREE_DAYS_ERROR, {
  error: response.error,
  code: response.code,
});

// EDIT FREE COURSES WEEK

export const editFreeDay = (
  values: { [key: string]: any }
) => action(IFreeCoursesActionsTypes.EDIT_FREE_DAYS_REQUEST, values);

export const editFreeDaySuccess = () => action(IFreeCoursesActionsTypes.EDIT_FREE_DAYS_SUCCESS);

export const editFreeDayError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(IFreeCoursesActionsTypes.EDIT_FREE_DAYS_ERROR, {
  error: response.error,
  code: response.code,
});

// DELETE FREE PLAN

export const deleteFreeDay = ({
  freeDayId,
  freeWeekId,
}: {
  freeDayId: number,
  freeWeekId: number,
}) => action(IFreeCoursesActionsTypes.DELETE_FREE_DAYS_REQUEST, { freeWeekId, freeDayId });

export const deleteFreeDaySuccess = (
  { freeDayId }: { freeDayId: number }
) => action(IFreeCoursesActionsTypes.DELETE_FREE_DAYS_SUCCESS, freeDayId);

export const deleteFreeDayError = (
  response: ApiResponse<{ [key: string]: any }>,
  freeDayId: number,
) =>
action(IFreeCoursesActionsTypes.DELETE_FREE_DAYS_ERROR, {
  error: response.error,
  code: response.code,
  freeDayId,
});

// GET FREE TRAININGS

export const getFreeTrainings = (
  freeDayId: number
) => action(IFreeCoursesActionsTypes.GET_FREE_TRAININGS_REQUEST, freeDayId);

export const getFreeTrainingsSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(
  IFreeCoursesActionsTypes.GET_FREE_TRAININGS_SUCCESS,
  response.data,
  response.code,
);

export const getFreeTrainingsError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(IFreeCoursesActionsTypes.GET_FREE_TRAININGS_ERROR, {
  error: response.error,
  code: response.code,
});

// EDIT FREE TRAINING

export const editFreeTraining = (
  values: { [key: string]: any }
) => action(IFreeCoursesActionsTypes.EDIT_FREE_TRAINING_REQUEST, values);

export const editFreeTrainingSuccess = () => action(IFreeCoursesActionsTypes.EDIT_FREE_TRAINING_SUCCESS);

export const editFreeTrainingError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
action(IFreeCoursesActionsTypes.EDIT_FREE_TRAINING_ERROR, {
  error: response.error,
  code: response.code,
});

// DELETE FREE TRAINING

export const deleteFreeTraining = ({
  freeTrainingId,
  freeDayId,
}: {
  freeTrainingId: number,
  freeDayId: number,
}) => action(IFreeCoursesActionsTypes.DELETE_FREE_TRAINING_REQUEST, { freeTrainingId, freeDayId });

export const deleteFreeTrainingSuccess = (
  { freeTrainingId }: { freeTrainingId: number }
) => action(IFreeCoursesActionsTypes.DELETE_FREE_TRAINING_SUCCESS, freeTrainingId);

export const deleteFreeTrainingError = (
  response: ApiResponse<{ [key: string]: any }>,
  freeTrainingId: number,
) =>
action(IFreeCoursesActionsTypes.DELETE_FREE_TRAINING_ERROR, {
  error: response.error,
  code: response.code,
  freeTrainingId,
});