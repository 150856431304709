import React, { Component } from 'react';
import { Input, InputNumber, Form, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { get } from 'lodash';
import {
  required,
  NOT_BLANK,
  rangeValue,
} from '../../../../constants/validationRules';

interface IModalFormProps extends FormComponentProps {
  data?: { [key: string]: any };
  weeks: any[];
}

class ModalForm extends Component<IModalFormProps> {
  componentWillUnmount() {
    this.props.form.resetFields();
  }

  get fields() {
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;
    const convertDecimalToInt = (value: any, name: string) => {
      const convertedValue = Number(value);
        if (isNaN(convertedValue)) {
          return Number(this.props.form.getFieldValue(name));
        } else {
          return convertedValue;
        }
    }

    const defaultName =
      data && data.name ? +data.name.replace('Неделя ', '') : null;

    const name = getFieldDecorator(`name`, {
      validateTrigger: 'onSubmit',
      getValueFromEvent: value => convertDecimalToInt(value, 'name'),
      rules: [required, rangeValue(1, 99), NOT_BLANK],
      initialValue: defaultName,
    })(<InputNumber style={{ width: '100%' }} placeholder="Номер недели" type="number"/>);

    const sort = getFieldDecorator(`sort`, {
      validateTrigger: 'onSubmit',
      getValueFromEvent: value => convertDecimalToInt(value, 'sort'),
      rules: [required, NOT_BLANK, rangeValue(1, 99)],
      initialValue: get(data, 'sort', ''),
    })(<InputNumber style={{ width: '100%' }} placeholder="Приоритет" type="number"/>);

    const id = getFieldDecorator(`id`, {
      initialValue: get(data, 'id', null),
    });

    const isFast = getFieldDecorator(`isFast`, {
      initialValue: get(data, 'isFast', false),
      valuePropName: 'checked'
    })(
      <Checkbox>
        Не сохранять результаты этой недели
      </Checkbox>
    );

    return {
      id,
      name,
      sort,
      defaultName,
      isFast,
    };
  }

  render() {
    return (
      <Form>
        <Form.Item label="Название">
          <Input
            value={`Неделя ${this.props.form.getFieldValue('name') ||
              this.fields.defaultName ||
              ''}`}
            disabled
          />
        </Form.Item>
        <Form.Item label="Номер недели">{this.fields.name}</Form.Item>
        <Form.Item label="Приоритет">{this.fields.sort}</Form.Item>
        <Form.Item>{this.fields.isFast}</Form.Item>
      </Form>
    );
  }
}

export default ModalForm;
