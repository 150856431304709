import { IWorkoutsActionsTypes } from './types';
import { action } from 'typesafe-actions';
import { ApiResponse } from '../../utils/api';

// get workoutsDay
export const getWorkoutsDay = (workoutWeekId: number, workoutDayIndex: number) =>
  action(IWorkoutsActionsTypes.GET_WORKOUTS_DAY_REQUEST, {workoutWeekId, workoutDayIndex});

export const getWorkoutsDaySuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IWorkoutsActionsTypes.GET_WORKOUTS_DAY_SUCCESS,
    response.data,
    response.code,
  );

export const getWorkoutsDayError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IWorkoutsActionsTypes.GET_WORKOUTS_DAY_ERROR, {
    error: response.error,
    code: response.code,
  });

// get workoutsWeek
export const getWorkoutsWeek = (workoutPlanId: number) =>
  action(IWorkoutsActionsTypes.GET_WORKOUTS_WEEK_REQUEST, workoutPlanId);

export const getWorkoutsWeekSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IWorkoutsActionsTypes.GET_WORKOUTS_WEEK_SUCCESS,
    response.data,
    response.code,
  );

export const getWorkoutsWeekError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IWorkoutsActionsTypes.GET_WORKOUTS_WEEK_ERROR, {
    error: response.error,
    code: response.code,
  });

// get workoutsPlan
export const getWorkoutsPlan = (workoutId: number) =>
  action(IWorkoutsActionsTypes.GET_WORKOUTS_PLAN_REQUEST, workoutId);

export const getWorkoutsPlanSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IWorkoutsActionsTypes.GET_WORKOUTS_PLAN_SUCCESS,
    response.data,
    response.code,
  );

export const getWorkoutsPlanError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IWorkoutsActionsTypes.GET_WORKOUTS_PLAN_ERROR, {
    error: response.error,
    code: response.code,
  });

// get workouts
export const getWorkouts = () =>
  action(IWorkoutsActionsTypes.GET_WORKOUTS_REQUEST);

export const getWorkoutsSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IWorkoutsActionsTypes.GET_WORKOUTS_SUCCESS,
    response.data,
    response.code,
  );

export const getWorkoutsError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IWorkoutsActionsTypes.GET_WORKOUTS_ERROR, {
    error: response.error,
    code: response.code,
  });

export const editWorkoutsPlan = (values: { [key: string]: any }) =>
  action(IWorkoutsActionsTypes.EDIT_WORKOUTS_PLAN_REQUEST, values);

export const editWorkoutsPlanSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IWorkoutsActionsTypes.EDIT_WORKOUTS_PLAN_SUCCESS,
    response.data,
    response.code,
  );

export const editWorkoutsPlanError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IWorkoutsActionsTypes.EDIT_WORKOUTS_PLAN_ERROR, {
    error: response.error,
    code: response.code,
  });

// delete workouts plan

export const deleteWorkoutsPlan = ({
  workoutId,
  workoutPlanId,
}: {
  workoutId: number;
  workoutPlanId: number;
}) =>
  action(IWorkoutsActionsTypes.DELETE_WORKOUTS_PLAN_REQUEST, {
    workoutId,
    workoutPlanId,
  });

export const deleteWorkoutsPlanSuccess = ({
  workoutPlanId,
}: {
  workoutPlanId: number;
}) =>
  action(IWorkoutsActionsTypes.DELETE_WORKOUTS_PLAN_SUCCESS, { workoutPlanId });

export const deleteWorkoutsPlanError = (
  response: ApiResponse<{ [key: string]: any }>,
  workoutPlanId: number,
) =>
  action(IWorkoutsActionsTypes.DELETE_WORKOUTS_PLAN_ERROR, {
    error: response.error,
    code: response.code,
    workoutPlanId: workoutPlanId,
  });

// edit workouts weeks
export const editWorkoutsWeek = (values: { [key: string]: any }) =>
  action(IWorkoutsActionsTypes.EDIT_WORKOUTS_WEEK_REQUEST, values);

export const editWorkoutsWeekSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IWorkoutsActionsTypes.EDIT_WORKOUTS_WEEK_SUCCESS,
    response.data,
    response.code,
  );

export const editWorkoutsWeekError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IWorkoutsActionsTypes.EDIT_WORKOUTS_WEEK_ERROR, {
    error: response.error,
    code: response.code,
  });

// delete workouts weeks

export const deleteWorkoutsWeek = ({
  workoutPlanId,
  workoutWeekId,
}: {
  workoutPlanId: number;
  workoutWeekId: number;
}) =>
  action(IWorkoutsActionsTypes.DELETE_WORKOUTS_WEEK_REQUEST, {
    workoutPlanId,
    workoutWeekId,
  });

export const deleteWorkoutsWeekSuccess = ({
  workoutWeekId,
}: {
  workoutWeekId: number;
}) =>
  action(IWorkoutsActionsTypes.DELETE_WORKOUTS_WEEK_SUCCESS, { workoutWeekId });

export const deleteWorkoutsWeekError = (
  response: ApiResponse<{ [key: string]: any }>,
  workoutWeekId: number,
) =>
  action(IWorkoutsActionsTypes.DELETE_WORKOUTS_WEEK_ERROR, {
    workoutWeekId,
    error: response.error,
    code: response.code,
  });

// copy workouts weeks

export const copyWorkoutsWeek = ({
  workoutPlanId,
  workoutWeekId,
}: {
  workoutPlanId: number;
  workoutWeekId: number;
}) =>
  action(IWorkoutsActionsTypes.COPY_WORKOUTS_WEEK_REQUEST, {
    workoutPlanId,
    workoutWeekId,
  });

export const copyWorkoutsWeekSuccess = ({
  workoutWeekId,
}: {
  workoutWeekId: number;
}) =>
  action(IWorkoutsActionsTypes.COPY_WORKOUTS_WEEK_SUCCESS, { workoutWeekId });

export const copyWorkoutsWeekError = (
  response: ApiResponse<{ [key: string]: any }>,
  workoutWeekId: number,
) =>
  action(IWorkoutsActionsTypes.COPY_WORKOUTS_WEEK_ERROR, {
    workoutWeekId,
    error: response.error,
    code: response.code,
  });

// edit workouts weeks
export const editWorkoutsDay = (values: { [key: string]: any }) =>
  action(IWorkoutsActionsTypes.EDIT_WORKOUTS_DAY_REQUEST, values);

export const editWorkoutsDaySuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IWorkoutsActionsTypes.EDIT_WORKOUTS_DAY_SUCCESS,
    response.data,
    response.code,
  );

export const editWorkoutsDayError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IWorkoutsActionsTypes.EDIT_WORKOUTS_DAY_ERROR, {
    error: response.error,
    code: response.code,
  });

// edit workouts
export const editWorkouts = (values: { [key: string]: any }) =>
  action(IWorkoutsActionsTypes.EDIT_WORKOUTS_REQUEST, values);

export const editWorkoutsSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    IWorkoutsActionsTypes.EDIT_WORKOUTS_SUCCESS,
    response.data,
    response.code,
  );

export const editWorkoutsError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(IWorkoutsActionsTypes.EDIT_WORKOUTS_ERROR, {
    error: response.error,
    code: response.code,
  });
