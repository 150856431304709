import { Action, AnyAction, combineReducers, Dispatch } from 'redux';
import { all, fork, spawn } from 'redux-saga/effects';

import { AuthState } from './auth/types';
import { authReducer } from './auth/reducers';
import authSaga from './auth/sagas';
import { ExercisiesState } from './exercisies/types';
import { ExercisiesReducer } from './exercisies/reducers';
import ExercisiesSaga from './exercisies/sagas';
import tokenMonitor from './token-monitor/sagas';

// workouts
import { IWorkoutsState } from './workouts/types';
import { WorkoutsReducer } from './workouts/reducers';
import WorkoutsSaga from './workouts/sagas';

// motivations
import { IMotivationState } from './motivation/types';
import { MotivationReducer } from './motivation/reducers';
import MotivationSage from './motivation/sagas';

// food
import { IFoodState } from './food/types';
import { FoodReducer } from './food/reducers';
import FoodSaga from './food/sagas';

// free courses
import { IFreeCoursesState } from './free-courses/types';
import { FreeCoursesReducer } from './free-courses/reducers';
import FreeCoursesSaga from './free-courses/sagas';

// free exercise
import { IFreeExercisesState } from './free-exercise/types';
import { FreeExercisesReducer } from './free-exercise/reducers';
import FreeExercisesSaga from './free-exercise/sagas';

// free exercise
import { ITrainingPinnedVideoState } from './training_pinned_video/types';
import { TrainingPinnedVideoReducer } from './training_pinned_video/reducers';
import TrainingPinnedSaga from './training_pinned_video/sagas';

export interface ApplicationState {
  auth: AuthState;
  exercisies: ExercisiesState;
  workouts: IWorkoutsState;
  motivations: IMotivationState;
  food: IFoodState;
  free_courses: IFreeCoursesState;
  free_exercise: IFreeExercisesState;
  training_pinned_video: ITrainingPinnedVideoState;
}

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

export const rootReducer = combineReducers<ApplicationState>({
  auth: authReducer,
  exercisies: ExercisiesReducer,
  workouts: WorkoutsReducer,
  motivations: MotivationReducer,
  food: FoodReducer,
  free_courses: FreeCoursesReducer,
  free_exercise: FreeExercisesReducer,
  training_pinned_video: TrainingPinnedVideoReducer,
});

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(WorkoutsSaga),
    fork(ExercisiesSaga),
    fork(MotivationSage),
    fork(FoodSaga),
    fork(FreeCoursesSaga),
    fork(FreeExercisesSaga),
    fork(TrainingPinnedSaga),
    spawn(tokenMonitor),
  ]);
}
