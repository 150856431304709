import React, { Component } from 'react';
import { Table, Button, Modal, Popconfirm, message } from 'antd';
import { forEach } from 'lodash';
import { FormComponentProps } from 'antd/lib/form';
import { ColumnProps } from 'antd/lib/table';
import {
  ExercisiesPageWrapper,
  Title,
  ButtonGroup,
} from '../../constants/styles';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  getWorkouts,
  getWorkoutsPlan,
  getWorkoutsWeek,
  getWorkoutsDay,
  editWorkoutsWeek,
  deleteWorkoutsWeek,
  copyWorkoutsWeek,
  editWorkoutsDay,
} from '../../store/workouts/actions';
import { Exercise } from '../../store/exercisies/types';
import { getExercisies } from '../../store/exercisies/actions';
import { ContentWrapper } from '../../components/layout/Content';
import { goToPath, goTo } from '../../services/navigator.service';
import routeMap from '../../constants/routeMap';
import ModalForm from './components/ModalForm';
import ModalDayForm from './components/ModalDayForm';
import { TableColumns } from './columns';

interface IWorkoutsWeeksProps extends FormComponentProps {
  match: { [key: string]: any };
  workouts: { [key: string]: any };
  Exercisies: Exercise[];
  getExercisies: () => void;
  getWorkouts: () => void;
  getWorkoutsPlan: (workoutId: number) => void;
  getWorkoutsWeek: (workoutPlanId: number) => void;
  getWorkoutsDay: (workoutWeekId: number, workoutDayIndex: number) => void;
  editWorkoutsWeek: (values: { [key: string]: any }) => void;
  editWorkoutsDay: (values: { [key: string]: any }) => void;
  deleteWorkoutsWeek: ({
    workoutPlanId,
    workoutWeekId,
  }: {
    workoutPlanId: number;
    workoutWeekId: number;
  }) => void;
  copyWorkoutsWeek: ({
    workoutPlanId,
    workoutWeekId,
  }: {
    workoutPlanId: number;
    workoutWeekId: number;
  }) => void;
}

interface IWorkoutsWeeksState {
  modalVisible: boolean;
  modalDayVisible: boolean;
  modalData: { [key: string]: any };
  workoutDayIndex: number | null;
  workoutWeekId: number | null;
}

class WorkoutsWeeks extends Component<
  IWorkoutsWeeksProps,
  IWorkoutsWeeksState
> {
  constructor(props: IWorkoutsWeeksProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalDayVisible: false,
      modalData: {},
      workoutDayIndex: null,
      workoutWeekId: null,
    };
  }

  componentDidMount() {
    this.props.getExercisies();
    this.props.getWorkouts();
    this.props.getWorkoutsPlan(this.props.match.params.workoutId);
    this.props.getWorkoutsWeek(this.props.match.params.workoutsPlanId);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.workouts.day && Object.keys(this.props.workouts.day).length !== 0 ) {
      if (this.props.workouts.day !== prevProps.workouts.day) {
        const day = this.props.workouts.day;
        this.setState({
          modalData: {...day, isPlanPublished: this.plans.isPublish},
          modalDayVisible: true,
        });
      }
    }
  }

  get plans() {
    const { workoutsPlanId } = this.props.match.params;
    const { plans } = this.props.workouts;
    let plan: { [key: string]: any } = {};
    if (plans) {
      plan = plans.filter(
        (item: { [key: string]: any }) => item.id === +workoutsPlanId,
      )[0];
    }
    return plan;
  }

  openModal = () => this.setState({ modalVisible: true });

  closeModal = () => this.setState({ modalVisible: false, modalData: {} });

  closeDayModal = () =>
    this.setState({ modalDayVisible: false, modalData: {} });

  onSubmit = () => {
    const { form } = this.props;
    const { workoutsPlanId } = this.props.match.params;
    const fieldNames = ['name', 'sort', 'isFast'];
    form!.validateFields(
      fieldNames,
      { firstFields: fieldNames },
      (err: any, values: any) => {
        const data = { ...values, id: this.state.modalData.id };
        if (this.plans.isPublish) {
          this.showWarningIfPlanPublished();
          return;
        }
        if (err) {
          message.error('Правильно заполните поля');
        }
        if (!err) {
          this.props.editWorkoutsWeek({
            ...data,
            workoutsPlanId,
            callback: this.closeModal,
          });
        }
      },
    );
  };

  showWarningIfPlanPublished = () =>
    message.warning('Редактирование запрещено, т.к. курс опубликован');

  editDay = () => {
    const { form } = this.props;
    form!.validateFields((err: any, values: any) => {
      if (err) {
        message.error('Правильно заполните поля');
      }
      if (!err) {
        const valuesFormatted: { [key: string]: any } = {};
        valuesFormatted.name = values.name;
        valuesFormatted.warmUp = values.warmUp;
        valuesFormatted.videoIntro = values.videoIntro;
        valuesFormatted.videoIntroImage = values.videoIntroImage;
        valuesFormatted.oldVideoIntroImage = values.videoIntroImageSrc;
        valuesFormatted.name_eng = values.name_eng;
        valuesFormatted.warmUp_eng = values.warmUp_eng;
        valuesFormatted.videoIntro_eng = values.videoIntro_eng;
        valuesFormatted.videoIntroImage_eng = values.videoIntroImage_eng;
        valuesFormatted.oldVideoIntroImage_eng = values.videoIntroImage_engSrc;
        valuesFormatted.trainingCategories = [];
        valuesFormatted.workoutDayIndex = this.state.workoutDayIndex;
        valuesFormatted.workoutWeekId = this.state.workoutWeekId;
        valuesFormatted.callback = this.closeDayModal;

        forEach(values.trainingCategories, (value, key) => {
          if (value) {
            const trainings: any[] = [];

            if (values.trainingId && values.trainingId[value]) {
              const trainingIds = values.trainingId[value];
              forEach(trainingIds, (trainingId, trainingKey) => {
                if (trainingId) {
                  trainings.push({
                    exerciseId: trainingId,
                    repeatsCount: values.repeatsCount[value][trainingKey],
                    setsCount: values.setsCount[value][trainingKey],
                    restTime: values.restTime[value][trainingKey],
                  });
                }
              });
            }

            valuesFormatted.trainingCategories.push({
              exerciseCategoryId: value,
              sort: values.sort[value],
              trainings,
            });
          }
        });
        this.props.editWorkoutsDay(valuesFormatted);
      }
    });
  };

  render() {

    const { loaders } = this.props.workouts;

    const WorkoutsWeeksTableColumns: ColumnProps<any>[] = [
      ...TableColumns,
      {
        title: (
          <Button
            className="action-button"
            icon="plus-circle"
            htmlType="button"
            title="Добавить неделю"
            onClick={this.openModal}
          />
        ),
        dataIndex: '',
        key: 'x',
        render: (_: string, data: { [key: string]: any }) => {
          const { workoutsPlanId } = this.props.match.params;
          const weekId =
            data.type === 'week' ? data.id : +data.id.split('-')[0];
          const dayId = data.type === 'week' ? 0 : +data.id.split('-')[1];

          const openEditModal = () =>
            this.setState({ modalData: data, modalVisible: true });

          const onDeleteWeek = () => {
            if (this.plans.isPublish) {
              this.showWarningIfPlanPublished();
              return;
            }

            this.props.deleteWorkoutsWeek({
              workoutPlanId: workoutsPlanId,
              workoutWeekId: weekId,
            });
          };

          const onCopyWeek = () => {
            if (this.plans.isPublish) {
              this.showWarningIfPlanPublished();
              return;
            }

            this.props.copyWorkoutsWeek({
              workoutPlanId: workoutsPlanId,
              workoutWeekId: weekId,
            });
          };

          const openEditDayModal = () => {
            this.props.getWorkoutsDay(weekId, dayId);
            this.setState({
              workoutWeekId: weekId,
              workoutDayIndex: dayId,
            });
          };

          const { loaders } = this.props.workouts;

          return data.type === 'week' ? (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="edit"
                htmlType="button"
                title="Редактировать неделю"
                onClick={openEditModal}
              />
              <Button
                className="action-button"
                icon="copy"
                htmlType="button"
                title="Скопировать неделю"
                loading={loaders[`copy/${data.id}`]}
                onClick={onCopyWeek}
              />
              <Popconfirm
                title="Вы уверены, что хотите удалить неделю?"
                onConfirm={onDeleteWeek}
                placement="rightBottom"
              >
                <Button
                  className="action-button"
                  icon="delete"
                  htmlType="button"
                  title="Удалить неделю"
                  loading={loaders[`delete/${data.id}`]}
                />
              </Popconfirm>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="edit"
                title="Редактировать день"
                htmlType="button"
                onClick={openEditDayModal}
              />
            </ButtonGroup>
          );
        },
      },
    ];

    const tableData: any[] = [];
    const weeks = this.props.workouts.weeks;
    if (weeks) {
      weeks.forEach((week: { [key: string]: any }) => {
        const weekClone = { ...week };
        delete weekClone.days;
        const val: { [key: string]: any } = {
          ...weekClone,
          type: 'week',
          children: [],
        };
        week.days.forEach((day: { [key: string]: any }) => {
          val.children.push({
            id: `${week.id}-${day.index}`,
            index: day.index,
            sort: day.index,
            name: day.name || `День ${day.index}`,
            type: 'day',
          });
        });
        tableData.push(val);
      });
    }

    const { workoutId, workoutsPlanId } = this.props.match.params;
    const modalTitle = this.state.modalData.id
      ? 'Редактирование недели'
      : 'Добавление недели';

    const currentWorkout =
      this.props.workouts.list.find((item: any) => item.id === +workoutId) ||
      {};

    const currentPlan = this.props.workouts.plans.find((item: any) => item.id === +workoutsPlanId) || {};
    const namePlan = currentPlan.name ? currentPlan.name : 'namePlan';

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Title>
            <span onClick={goToPath.bind(null, routeMap.workouts.path)}>
              Категории тренировок
            </span>{' '}
            -{' '}
            <span
              onClick={goTo.bind(null, 'workoutsPlan', {
                workoutId,
              })}
            >
              {currentWorkout.name}
            </span>{' '}
            - {namePlan}
          </Title>
          <Table
            rowKey="id"
            columns={WorkoutsWeeksTableColumns}
            loading={loaders.getWorkoutsWeek}
            dataSource={tableData}
          />
          <Modal
            title={modalTitle}
            maskClosable={false}
            destroyOnClose={true}
            visible={this.state.modalVisible}
            confirmLoading={loaders.editWorkoutsWeeks}
            onOk={this.onSubmit}
            onCancel={this.closeModal}
          >
            <ModalForm
              form={this.props.form}
              weeks={this.props.workouts.weeks}
              data={this.state.modalData}
            />
          </Modal>
          <Modal
            title="Редактирование дня"
            destroyOnClose={true}
            visible={this.state.modalDayVisible}
            confirmLoading={loaders.editWorkoutsDay}
            onCancel={this.closeDayModal}
            onOk={this.editDay}
            style={{ minWidth: '90%' }}
            maskClosable={false}
          >
            <ModalDayForm
              form={this.props.form}
              data={this.state.modalData}
              exercises={this.props.Exercisies}
              workoutDayIndex={this.state.workoutDayIndex}
              workoutWeekId={this.state.workoutWeekId}
              maskClosable={false}
            />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  workouts: {
    list: state.workouts.list,
    plans: state.workouts.plans,
    weeks: state.workouts.weeks,
    day: state.workouts.day,
    loaders: state.workouts.loaders,
  },
  Exercisies: state.exercisies.Exercisies,
  ExercisiesIsLoading: state.exercisies.ExercisiesIsLoading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getExercisies: () => dispatch(getExercisies()),
  getWorkouts: () => dispatch(getWorkouts()),
  getWorkoutsPlan: (workoutId: number) => dispatch(getWorkoutsPlan(workoutId)),
  getWorkoutsWeek: (workoutPlanId: number) => dispatch(getWorkoutsWeek(workoutPlanId)),
  getWorkoutsDay: (workoutWeekId: number, workoutDayIndex: number) => dispatch(getWorkoutsDay(workoutWeekId, workoutDayIndex)),
  editWorkoutsWeek: (values: { [key: string]: any }) =>
    dispatch(editWorkoutsWeek(values)),
  deleteWorkoutsWeek: ({
    workoutPlanId,
    workoutWeekId,
  }: {
    workoutPlanId: number;
    workoutWeekId: number;
  }) => dispatch(deleteWorkoutsWeek({ workoutPlanId, workoutWeekId })),
  copyWorkoutsWeek: ({
    workoutPlanId,
    workoutWeekId,
  }: {
    workoutPlanId: number;
    workoutWeekId: number;
  }) => dispatch(copyWorkoutsWeek({ workoutPlanId, workoutWeekId })),
  editWorkoutsDay: (values: { [key: string]: any }) =>
    dispatch(editWorkoutsDay(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutsWeeks);
