export interface ExerciseInstance {
  id?: number;
  level: number;
  name: string;
  name_eng: string;
  video: string;
  video_eng: string;
  videoPreview: File | string;
  videoPreview_eng: File | string;
  detailedVideo?: string;
  detailedVideo_eng?: string;
  description?: string;
  description_eng?: string;
}

export interface Exercise {
  name: string;
  name_eng: string;
  image?: File | string;
  image_eng?: File | string;
  id?: number;
  type: string;
  exercises: ExerciseInstance[];
}

export interface ExerciseForm {
  isEdit: boolean;
  ExerciseData?: Exercise;
}

export interface ExercisiesState {
  ExerciseFormOpen: boolean;
  ExerciseForm?: ExerciseForm;
  ExerciseFormProcessing: boolean;
  Exercisies?: Exercise[];
  ExercisiesLoadingError?: string;
  ExercisiesIsLoading: boolean;
  ExerciseDeleteLoaders: { [key: number]: boolean };
}

export enum ExercisiesActionsTypes {
  OPEN_EXERCISE_FORM = '@@Exercisies/OPEN_EXERCISE_FORM',
  CLOSE_EXERCISE_FORM = '@@Exercisies/CLOSE_EXERCISE_FORM',
  GET_EXERCISIES_REQUEST = '@@Exercisies/GET_EXERCISIES_REQUEST',
  GET_EXERCISIES_SUCCESS = '@@Exercisies/GET_EXERCISIES_SUCCESS',
  GET_EXERCISIES_ERROR = '@@Exercisies/GET_EXERCISIES_ERROR',
  CREATE_EXERCISE_REQUEST = '@@Exercisies/CREATE_EXERCISE_REQUEST',
  CREATE_EXERCISE_SUCCESS = '@@Exercisies/CREATE_EXERCISE_SUCCESS',
  CREATE_EXERCISE_ERROR = '@@Exercisies/CREATE_EXERCISE_ERROR',
  UPDATE_EXERCISE_REQUEST = '@@Exercisies/UPDATE_EXERCISE_REQUEST',
  UPDATE_EXERCISE_SUCCESS = '@@Exercisies/UPDATE_EXERCISE_SUCCESS',
  UPDATE_EXERCISE_ERROR = '@@Exercisies/UPDATE_EXERCISE_ERROR',
  DELETE_EXERCISE_REQUEST = '@@Exercisies/DELETE_EXERCISE_REQUEST',
  DELETE_EXERCISE_SUCCESS = '@@Exercisies/DELETE_EXERCISE_SUCCESS',
  DELETE_EXERCISE_ERROR = '@@Exercisies/DELETE_EXERCISE_ERROR',
  CREATE_EXERCISE_LEVELS_REQUEST = '@@Exercisies/CREATE_EXERCISE_LEVELS_REQUEST',
  CREATE_EXERCISE_LEVELS_SUCCESS = '@@Exercisies/CREATE_EXERCISE_LEVELS_SUCCESS',
  CREATE_EXERCISE_LEVELS_ERROR = '@@Exercisies/CREATE_EXERCISE_LEVELS_ERROR',
  DELETE_EXERCISE_LEVELS_REQUEST = '@@Exercisies/DELETE_EXERCISE_LEVELS_REQUEST',
  DELETE_EXERCISE_LEVELS_SUCCESS = '@@Exercisies/DELETE_EXERCISE_LEVELS_SUCCESS',
  DELETE_EXERCISE_LEVELS_ERROR = '@@Exercisies/DELETE_EXERCISE_LEVELS_ERROR',
}
