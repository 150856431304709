import { ITrainingPinnedVideoActionsTypes } from './types';
import { action } from 'typesafe-actions';
import { ApiResponse } from '../../utils/api';

// get workouts

export const getTrainingPinnedVideo = () =>
  action(ITrainingPinnedVideoActionsTypes.GET_TRAINING_PINNED_VIDEO_REQUEST);

export const getTrainingPinnedVideoSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    ITrainingPinnedVideoActionsTypes.GET_TRAINING_PINNED_VIDEO_SUCCESS,
    response.data,
    response.code,
  );

export const getTrainingPinnedVideoError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(ITrainingPinnedVideoActionsTypes.GET_TRAINING_PINNED_VIDEO_ERROR, {
    error: response.error,
    code: response.code,
  });

export const editTrainingPinnedVideo = (values: { [key: string]: any }) =>
  action(ITrainingPinnedVideoActionsTypes.EDIT_TRAINING_PINNED_VIDEO_REQUEST, values);

export const editTrainingPinnedVideoSuccess = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(
    ITrainingPinnedVideoActionsTypes.EDIT_TRAINING_PINNED_VIDEO_SUCCESS,
    response.data,
    response.code,
  );

export const editTrainingPinnedVideoError = (
  response: ApiResponse<{ [key: string]: any }>,
) =>
  action(ITrainingPinnedVideoActionsTypes.EDIT_TRAINING_PINNED_VIDEO_ERROR, {
    error: response.error,
    code: response.code,
  });
