import { IFoodActionsTypes, IFoodState } from './types';
import { Reducer } from 'redux';

const InitialState: IFoodState = {
  list: [],
  loaders: {},
  rationItem: {},
  portionItem: {},
};

const reducer: Reducer = (state = InitialState, action) => {
  switch (action.type) {
    // get rations
    case IFoodActionsTypes.GET_RATIONS_REQUEST:
      return { ...state, loaders: { ...state.loaders, getRations: true } };
    case IFoodActionsTypes.GET_RATIONS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getRations: false },
        list: action.payload,
      };
    case IFoodActionsTypes.GET_RATIONS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getRations: false },
      };

    // get ration item
    case IFoodActionsTypes.GET_RATION_ITEM_REQUEST:
      return { ...state, loaders: { ...state.loaders, getRationItem: true } };
    case IFoodActionsTypes.GET_RATION_ITEM_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getRationItem: false },
        rationItem: action.payload,
      };
    case IFoodActionsTypes.GET_RATION_ITEM_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getRationItem: false },
      };

    // edit rations
    case IFoodActionsTypes.EDIT_RATIONS_REQUEST:
      return { ...state, loaders: { ...state.loaders, editRations: true } };
    case IFoodActionsTypes.EDIT_RATIONS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, editRations: false },
      };
    case IFoodActionsTypes.EDIT_RATIONS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editRations: false },
      };

    // delete rations
    case IFoodActionsTypes.DELETE_RATION_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.rationId}`]: true,
        },
      };

    case IFoodActionsTypes.DELETE_RATION_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.rationId}`]: false,
        },
      };

    case IFoodActionsTypes.DELETE_RATION_ERROR:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.rationId}`]: false,
        },
      };

    // get portions
    case IFoodActionsTypes.GET_PORTIONS_REQUEST:
      return { ...state, loaders: { ...state.loaders, getPortions: true } };
    case IFoodActionsTypes.GET_PORTIONS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getPortions: false },
        list: action.payload,
      };
    case IFoodActionsTypes.GET_PORTIONS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getPortions: false },
      };

    // get portion item
    case IFoodActionsTypes.GET_PORTION_ITEM_REQUEST:
      return { ...state, loaders: { ...state.loaders, getPortionItem: true } };
    case IFoodActionsTypes.GET_PORTION_ITEM_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getPortionItem: false },
        portionItem: action.payload,
      };
    case IFoodActionsTypes.GET_PORTION_ITEM_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getPortionItem: false },
      };

    // edit portions
    case IFoodActionsTypes.EDIT_PORTIONS_REQUEST:
      return { ...state, loaders: { ...state.loaders, editPortions: true } };
    case IFoodActionsTypes.EDIT_PORTIONS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, editPortions: false },
      };
    case IFoodActionsTypes.EDIT_PORTIONS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editPortions: false },
      };

    // delete portions
    case IFoodActionsTypes.DELETE_PORTION_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.portionId}`]: true,
        },
      };

    case IFoodActionsTypes.DELETE_PORTION_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.portionId}`]: false,
        },
      };

    case IFoodActionsTypes.DELETE_PORTION_ERROR:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.portionId}`]: false,
        },
      };

    // get meals
    case IFoodActionsTypes.GET_MEALS_REQUEST:
      return { ...state, loaders: { ...state.loaders, getMeals: true } };
    case IFoodActionsTypes.GET_MEALS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getMeals: false },
        list: action.payload,
      };
    case IFoodActionsTypes.GET_MEALS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getMeals: false },
      };

    // edit meals
    case IFoodActionsTypes.EDIT_MEALS_REQUEST:
      return { ...state, loaders: { ...state.loaders, editMeals: true } };
    case IFoodActionsTypes.EDIT_MEALS_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, editMeals: false },
      };
    case IFoodActionsTypes.EDIT_MEALS_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editMeals: false },
      };

    // delete meals
    case IFoodActionsTypes.DELETE_MEALS_REQUEST:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.mealId}`]: true,
        },
      };

    case IFoodActionsTypes.DELETE_MEALS_SUCCESS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.mealId}`]: false,
        },
      };

    case IFoodActionsTypes.DELETE_MEALS_ERROR:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [`delete/${action.payload.mealId}`]: false,
        },
      };

    default:
      return state;
  }
};

export { reducer as FoodReducer };
