import styled from '../utils/styled';

export const ExercisiesPageWrapper = styled.div`
  .action-button {
    float: right;
  }
`;

export const Title = styled.h4`
  margin-bottom: 16px;

  span {
    cursor: pointer;
    color: #1890ff;
  }

  b {
    font-weight: normal;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .ant-btn {
    margin-left: 10px;
  }
`;

export const ExercisiesCount = styled.div`
  background: #fafafa;
  display: inline-block;
  padding: 4px 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  min-width: 40px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
`;
