import React, { SFC } from 'react';
import { Icon } from 'antd';
import styled from '../../utils/styled';

interface IThumbnailProps {
  src: string;
  onRemove?: () => void;
  onPreview?: () => void;
}

const Thumbnail: SFC<IThumbnailProps> = ({ src, onRemove, onPreview }) => {
  return (
    <ThumbnailWrap style={{ backgroundImage: `url(${src})` }}>
      <div className="dt-thumbnail-cover">
        <Icon style={iconStyles} type="eye" onClick={onPreview} />
        <Icon style={iconStyles} type="delete" onClick={onRemove} />
      </div>
    </ThumbnailWrap>
  );
};

const ThumbnailWrap = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  padding: 7px;

  .dt-thumbnail-cover {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.3s all ease;
  }

  .dt-thumbnail-cover:hover {
    opacity: 1;
  }
`;

const iconStyles = {
  color: '#fff',
  cursor: 'pointer',
  margin: '0 5px',
};

export default Thumbnail;
