export interface AuthState {
  readonly loading: boolean
  readonly data?: AuthUser
  tokenRefreshing: boolean
  readonly token?: AuthToken
  readonly errors?: string
}

export interface AuthToken {
  token: string;
  refresh_token: string;
}

export interface AuthUser {
  exp: number;
  iat: number;
  roles: Array<keyof UserRoles>;
  username: string;
}

export interface AuthRequest {
  phone: string;
  password: string;
}

export interface TokenRefreshRequest {
  refresh_token: string;
}

export enum AuthActionTypes {
  LOGIN_REQUEST = '@@auth/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS',
  LOGIN_ERROR = '@@auth/LOGIN_ERROR',
  LOGOUT = '@@auth/LOGOUT',
  TOKEN_REFRESH_REQUEST = '@@auth/TOKEN_REFRESH_REQUEST',
  TOKEN_REFRESH_SUCCESS = '@@auth/TOKEN_REFRESH_SUCCESS',
  TOKEN_REFRESH_ERROR = '@@auth/TOKEN_REFRESH_ERROR',
}

export enum UserRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
}
