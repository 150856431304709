import { AuthActionTypes, AuthToken } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ApiResponse, callApi } from '../../utils/api';
import {
  loginError,
  loginPerform,
  loginSuccess,
  tokenRefreshError,
  tokenRefreshRequest,
  tokenRefreshSuccess,
} from './actions';
import history from '../../utils/history';

function* handleLogin(action: ReturnType<typeof loginPerform>) {
  const res: ApiResponse<AuthToken> = yield call(
    callApi,
    'post',
    'admin/auth',
    null,
    action.payload,
  );
  if (res.error) {
    yield put(loginError(res.error.message));
  } else {
    const { token, refresh_token } = res.data as AuthToken;

    localStorage.setItem(
      '@@user/token',
      JSON.stringify({ token, refresh_token }),
    );

    yield put(loginSuccess({ token, refresh_token }));

    history.push('/');
  }
}
function* handleRefreshToken(action: ReturnType<typeof tokenRefreshRequest>) {
  const res: ApiResponse<AuthToken> = yield call(
    callApi,
    'post',
    'token/refresh',
    null,
    action.payload,
  );
  if (res.error) {
    yield put(tokenRefreshError());
  } else {
    const { token, refresh_token } = res.data as AuthToken;

    localStorage.setItem(
      '@@user/token',
      JSON.stringify({ token, refresh_token }),
    );

    yield put(tokenRefreshSuccess());
    yield put(loginSuccess({ token, refresh_token }));
    history.push('/');
  }
}

function handleLogout() {
  localStorage.removeItem('@@user/token');
  history.push('/login');
}

function* watchLoginRequest() {
  yield takeEvery(AuthActionTypes.LOGIN_REQUEST, handleLogin);
}

function* watchTokenRefreshRequest() {
  yield takeEvery(AuthActionTypes.TOKEN_REFRESH_REQUEST, handleRefreshToken);
}

function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT, handleLogout);
}
function* authSaga() {
  yield all([
    fork(watchLoginRequest),
    fork(watchTokenRefreshRequest),
    fork(watchLogout),
  ]);
}

export default authSaga;
