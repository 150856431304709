import { loginSuccess } from '../store/auth/actions';
import { Store } from 'redux';
import { ApplicationState } from '../store';

export const checkAuthorization = ({ dispatch, getState } : Store<ApplicationState>) => {

  const user = getState().auth.data;
  if (user) { return true; }

  const storedToken = localStorage.getItem('@@user/token')

  if (storedToken) {
    try {
      const { token, refresh_token } = JSON.parse(storedToken)
      dispatch(loginSuccess({ token, refresh_token }))
      return true
    } catch (e) {
      return false;
    }
  }

  return false
}
