import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { IFoodActionsTypes } from './types';
import { callApi } from '../../utils/api';
import { message } from 'antd';
import {
  getRations,
  getRationsError,
  getRationsSuccess,
  editRations,
  editRationsError,
  editRationsSuccess,
  deleteRation,
  deleteRationError,
  deleteRationSuccess,
  getRationItem,
  getRationItemSuccess,
} from './actions';
import { pick } from 'lodash';
import { AuthActionTypes } from '../auth/types';
import { getToken, isTokenRefreshing } from '../auth/selectors';
import { CONTENT_TYPES } from '../../utils/api';

function* getAuthToken() {
  const isRefreshing = yield select(isTokenRefreshing);
  if (isRefreshing) {
    const { fail } = yield race({
      success: take(AuthActionTypes.TOKEN_REFRESH_SUCCESS),
      fail: take(AuthActionTypes.TOKEN_REFRESH_ERROR),
    });
    if (fail) {
      return null;
    }
  }
  return yield select(getToken);
}

function* handleGetRationItemRequest(action: ReturnType<typeof getRationItem>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const { rationId } = action.payload;
  const response = yield call(
    callApi,
    'get',
    `admin/rations/${rationId}`,
    token,
  );
  if (response.error) {
    yield put(getRationsError(response));
  } else {
    yield put(getRationItemSuccess(response));
  }
}

function* handleGetRationsRequest() {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(callApi, 'get', 'admin/rations', token);
  if (response.error) {
    yield put(getRationsError(response));
  } else {
    yield put(getRationsSuccess(response));
  }
}

function* handleUploadRationsImage({
  data,
  token,
}: {
  data: { [key: string]: any };
  token: string;
}) {
  const { id } = data;
  const response = yield call(
    callApi,
    'post',
    `admin/rations/${id}/video-preview`,
    token,
    { videoPreview: data.videoPreview },
    CONTENT_TYPES.MPFD,
  );
  if (response.error) {
    message.error(response.error.message);
  }
}

function* createRations(token: string, values: { [key: string]: any }) {
  const updValues = pick(values, [
    'name',
    'description',
    'isPublish',
    'sort',
    'video',
  ]);

  const response = yield call(
    callApi,
    'post',
    `admin/rations`,
    token,
    updValues,
  );

  if (response.error) {
    yield put(editRationsError(response));
  } else {
    const id = response.data.id;
    yield handleUploadRationsImage({
      token,
      data: { ...values, id },
    });
    yield put(editRationsSuccess(response));
    yield put(getRations());
    values.callback();
  }
}

function* updateRations(token: string, values: { [key: string]: any }) {
  const { id } = values;

  const updValues = pick(values, [
    'name',
    'description',
    'isPublish',
    'sort',
    'video',
  ]);

  const response = yield call(
    callApi,
    'put',
    `admin/rations/${id}`,
    token,
    updValues,
  );

  if (response.error) {
    yield put(editRationsError(response));
  } else {
    if (values.videoPreview) {
      yield handleUploadRationsImage({
        token,
        data: { ...values },
      });
    }
    yield put(editRationsSuccess(response));
    yield put(getRations());
    values.callback();
  }
}

function* handleEditRationsRequest(action: ReturnType<typeof editRations>) {
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  if (action.payload.id) {
    yield updateRations(token, action.payload);
  } else {
    yield createRations(token, action.payload);
  }
}

function* handleDeleteRationRequest(action: ReturnType<typeof deleteRation>) {
  const { rationId } = action.payload;
  const token = yield getAuthToken();
  if (!token) {
    return;
  }
  const response = yield call(
    callApi,
    'delete',
    `admin/rations/${rationId}`,
    token,
  );

  if (response.error) {
    yield put(deleteRationError(response, rationId));
  } else {
    yield put(deleteRationSuccess({ rationId }));
    yield put(getRations());
  }
}

export function* watchGetRationItemRequest() {
  yield takeLatest(
    IFoodActionsTypes.GET_RATION_ITEM_REQUEST,
    handleGetRationItemRequest,
  );
}

export function* watchDeleteRationRequest() {
  yield takeLatest(
    IFoodActionsTypes.DELETE_RATION_REQUEST,
    handleDeleteRationRequest,
  );
}

export function* watchEditRationsRequest() {
  yield takeLatest(
    IFoodActionsTypes.EDIT_RATIONS_REQUEST,
    handleEditRationsRequest,
  );
}

export function* watchGetRationsRequest() {
  yield takeLatest(
    IFoodActionsTypes.GET_RATIONS_REQUEST,
    handleGetRationsRequest,
  );
}
