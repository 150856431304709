import React, { Component } from 'react';
import { Table, Button, Modal, Popconfirm, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormComponentProps } from 'antd/lib/form';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  getExercisies,
  createExerciseLevelsRequest,
  deleteExerciseLevelsRequest,
} from '../../store/exercisies/actions';
import { ApplicationState } from '../../store';
import { ContentWrapper } from '../../components/layout/Content';
import { Exercise } from '../../store/exercisies/types';
import { goToPath } from '../../services/navigator.service';
import routeMap from '../../constants/routeMap';
import { TableColumns } from './columns';
import ExerciseDetailForm from './components/ExerciseDetailForm';
import {
  ButtonGroup,
  ExercisiesPageWrapper,
  Title,
} from '../../constants/styles';

interface IExerciseDetailProps extends FormComponentProps {
  ExercisiesIsLoading: boolean;
  Exercisies: Exercise[];
  getExercisies: typeof getExercisies;
  match: { [key: string]: any };
  createExerciseLevelsRequest: typeof createExerciseLevelsRequest;
  deleteExerciseLevelsRequest: typeof deleteExerciseLevelsRequest;
  ExerciseFormProcessing: boolean;
  ExerciseDeleteLoaders: { [key: number]: boolean };
}

interface IExerciseDetailState {
  modalVisible: boolean;
  modalData: { [key: string]: any };
}

class ExerciseDetail extends Component<
  IExerciseDetailProps,
  IExerciseDetailState
> {
  constructor(props: IExerciseDetailProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getExercisies();
  }

  get exercise() {
    const { exerciseId } = this.props.match.params;
    const exercise: { [key: string]: any } = this.props.Exercisies
      ? this.props.Exercisies.filter(
          (item: { [key: string]: any }) => item.id === +exerciseId,
        )[0]
      : {};
    return exercise;
  }

  get isExerciseHasRestType() {
    return this.exercise.type === 'rest';
  }

  openModal = () => {
    this.setState({ modalVisible: true });
  };

  closeModal = () => this.setState({ modalVisible: false, modalData: {} });

  onSubmit() {
    const { form } = this.props;
    const { createExerciseLevelsRequest } = this.props;
    const fieldNames = [
      'name',
      'name_eng',
      'video',
      'video_eng',
      'detailedVideo',
      'detailedVideo_eng',
      'videoPreview',
      'videoPreview_eng',
      'withWeight',
      !this.isExerciseHasRestType ? 'level' : null,
      this.isExerciseHasRestType ? 'description' : null,
      this.isExerciseHasRestType ? 'description_eng' : null,
    ];
    form!.validateFields(
      fieldNames,
      { firstFields: fieldNames },
      (err: any, values: any) => {
        const data = { ...values, id: this.state.modalData.id };
        if (!err) {
          const { exerciseId } = this.props.match.params;
          createExerciseLevelsRequest({
            ...data,
            exerciseId,
            callback: this.closeModal,
          });
        };
        if (err) {
          message.error('Правильно заполните поля во всех вкладках');
        };
      },
    );
  }

  render() {
    const columns = this.isExerciseHasRestType
      ? TableColumns.slice(1)
      : TableColumns;
    const ExercisiesTableColumns: ColumnProps<any>[] = [
      ...columns,
      {
        title: (
          <Button
            className="action-button"
            icon="plus-circle"
            htmlType="button"
            title={
              this.isExerciseHasRestType
                ? 'Добавить отдых'
                : 'Добавить упражнение'
            }
            onClick={this.openModal}
          />
        ),
        dataIndex: '',
        key: 'x',
        render: (_: string, ExerciseData: { [key: string]: any }) => {
          const openEditModal = () => {
            this.setState({ modalData: ExerciseData });
            this.setState({ modalVisible: true });
          };

          const deleteExerciseLevels = () =>
            this.props.deleteExerciseLevelsRequest({
              exerciseId: +this.props.match.params.exerciseId,
              exerciseLevelId: ExerciseData.id,
            });

          return (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="edit"
                htmlType="button"
                title={
                  this.isExerciseHasRestType
                    ? 'Редактировать отдых'
                    : 'Редактировать упражнение'
                }
                onClick={openEditModal}
              />
              <Popconfirm
                title="Вы уверены, что хотите удалить упражнение?"
                onConfirm={deleteExerciseLevels}
                placement="rightBottom"
              >
                <Button
                  className="action-button"
                  icon="delete"
                  htmlType="button"
                  title={
                    this.isExerciseHasRestType
                      ? 'Удалить отдых'
                      : 'Удалить упражнение'
                  }
                  loading={this.props.ExerciseDeleteLoaders[ExerciseData.id]}
                />
              </Popconfirm>
            </ButtonGroup>
          );
        },
      },
    ];

    const { ExerciseFormProcessing } = this.props;
    if (!this.exercise) {
      return (
        <ContentWrapper>
          <ExercisiesPageWrapper>
            <Title>
              Категория упражнений не найдена. Вернуться к{' '}
              <span onClick={goToPath.bind(null, routeMap.exercises.path)}>
                категориям упражнений
              </span>
            </Title>
          </ExercisiesPageWrapper>
        </ContentWrapper>
      );
    }

    const modalTitle = this.state.modalData.id
      ? `Редактирование ${
          !this.isExerciseHasRestType ? 'упражнения' : 'отдыха'
        }`
      : `Добавление ${!this.isExerciseHasRestType ? 'упражнения' : 'отдыха'}`;

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Title>
            <span onClick={goToPath.bind(null, routeMap.exercises.path)}>
              Упражнения
            </span>{' '}
            - {this.exercise.name}
          </Title>
          <Table
            rowKey="id"
            columns={ExercisiesTableColumns}
            loading={this.props.ExercisiesIsLoading}
            dataSource={this.exercise.exercises}
          />
          <Modal
            title={modalTitle}
            maskClosable={false}
            destroyOnClose={true}
            visible={this.state.modalVisible}
            confirmLoading={ExerciseFormProcessing}
            onCancel={this.closeModal}
            onOk={() => this.onSubmit()}
          >
            <ExerciseDetailForm
              data={{ ...this.state.modalData, type: this.exercise.type }}
              form={this.props.form}
              type={this.exercise.type}
            />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  Exercisies: state.exercisies.Exercisies,
  ExerciseDeleteLoaders: state.exercisies.ExerciseDeleteLoaders,
  ExercisiesIsLoading: state.exercisies.ExercisiesIsLoading,
  ExerciseFormProcessing: state.exercisies.ExerciseFormProcessing,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getExercisies: () => dispatch(getExercisies()),
  createExerciseLevelsRequest: (values: { [key: string]: any }) =>
    dispatch(createExerciseLevelsRequest(values)),
  deleteExerciseLevelsRequest: ({
    exerciseId,
    exerciseLevelId,
  }: {
    exerciseId: number;
    exerciseLevelId: number;
  }) => dispatch(deleteExerciseLevelsRequest({ exerciseId, exerciseLevelId })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExerciseDetail);
