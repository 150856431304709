import {
  race,
  select,
  take,
} from 'redux-saga/effects';
import { message } from 'antd';
import { AuthActionTypes } from './auth/types';
import { getToken, isTokenRefreshing } from './auth/selectors';

export function handleShowError(action: { [key: string]: any }) {
  message.error(action.payload.error.message);
}

export function* getAuthToken() {
  const isRefreshing = yield select(isTokenRefreshing);
  if (isRefreshing) {
    const { fail } = yield race({
      success: take(AuthActionTypes.TOKEN_REFRESH_SUCCESS),
      fail: take(AuthActionTypes.TOKEN_REFRESH_ERROR),
    });
    if (fail) {
      return null;
    }
  }
  return yield select(getToken);
}