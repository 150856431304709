import * as React from 'react';
import { connect, Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Store } from 'redux';
import { History } from 'history';
import { ThemeProvider } from 'emotion-theming';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import { ApplicationState } from './store';
import { defaultTheme } from './styles/theme';
import Root from './components/layout/Root';
import { Redirect, Route, Switch } from 'react-router';
import LoginPage from './pages/login';
import { checkAuthorization } from './utils/auth';
import IndexPage from './pages';

interface PropsFromState {}

interface PropsFromDispatch {
  [key: string]: any;
}

interface OwnProps {
  store: Store<ApplicationState>;
  history: History;
}

type AllProps = PropsFromState & PropsFromDispatch & OwnProps;

class Main extends React.Component<AllProps> {
  public render() {
    const { store, history } = this.props;

    return (
      <ConfigProvider locale={ruRU}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={defaultTheme}>
              <Root>
                <Switch>
                  <Route exact path="/login" component={LoginPage} />z
                  <Route
                    path="/"
                    render={props =>
                      checkAuthorization(store) ? (
                        <IndexPage {...props} />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  ></Route>
                </Switch>
              </Root>
            </ThemeProvider>
          </ConnectedRouter>
        </Provider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = ({}: ApplicationState) => ({});

export default connect<
  PropsFromState,
  PropsFromDispatch,
  OwnProps,
  ApplicationState
>(mapStateToProps)(Main);
