import { IFreeExercisesActionsTypes, IFreeExercisesState } from './types';
import { Reducer } from 'redux';

const InitialState: IFreeExercisesState = {
  data: null,
  loaders: {},
};

const reducer: Reducer = (state = InitialState, action) => {
  switch (action.type) {
     // get free days
     case IFreeExercisesActionsTypes.GET_FREE_EXERCISE_REQUEST:
      return { ...state, loaders: { ...state.loaders, getFreeExercises: true } };
    case IFreeExercisesActionsTypes.GET_FREE_EXERCISE_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getFreeExercises: false },
        data: action.payload,
      };
    case IFreeExercisesActionsTypes.GET_FREE_EXERCISE_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, getFreeExercises: false },
      };

    // edit free day
    case IFreeExercisesActionsTypes.EDIT_FREE_EXERCISE_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, editFreeExercise: true },
      };
    case IFreeExercisesActionsTypes.EDIT_FREE_EXERCISE_SUCCESS:
    case IFreeExercisesActionsTypes.EDIT_FREE_EXERCISE_ERROR:
      return {
        ...state,
        loaders: { ...state.loaders, editFreeExercise: false },
      };

    // delete free days
    case IFreeExercisesActionsTypes.DELETE_FREE_EXERCISE_REQUEST:
      return {
        ...state,
        loaders: { 
          ...state.loaders,
          [`delete/${action.payload.freeExerciseId}`]: true,
        },
      };
    case IFreeExercisesActionsTypes.DELETE_FREE_EXERCISE_SUCCESS:
    case IFreeExercisesActionsTypes.DELETE_FREE_EXERCISE_ERROR:
      return {
        ...state,
        loaders: {
          ...state.loaders, 
          [`delete/${action.payload.freeExerciseId}`]: false,
        },
      };
      
    default:
      return state;
  }
};

export { reducer as FreeExercisesReducer };
