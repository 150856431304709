import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Table, Button, Modal, Popconfirm, message, Checkbox } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FormComponentProps } from 'antd/lib/form';
import {
  getWorkouts,
  getWorkoutsPlan,
  editWorkoutsPlan,
  deleteWorkoutsPlan,
} from '../../store/workouts/actions';
import { ApplicationState } from '../../store';
import { ContentWrapper } from '../../components/layout/Content';
import {
  ExercisiesPageWrapper,
  Title,
  ButtonGroup,
} from '../../constants/styles';
import { TableColumns } from './columns';
import EditForm from './components/EditForm';
import { goTo, goToPath } from '../../services/navigator.service';
import routeMap from '../../constants/routeMap';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface IWorkoutsPlanProps extends FormComponentProps {
  getWorkouts: () => void;
  getWorkoutsPlan: (workoutId: number) => void;
  editWorkoutsPlan: (values: { [key: string]: any }) => void;
  deleteWorkoutsPlan: ({
    workoutId,
    workoutPlanId,
  }: {
    workoutId: number;
    workoutPlanId: number;
  }) => void;
  match: { [key: string]: any };
  workouts: { [key: string]: any };
  plans: { [key: string]: any };
}

interface IWorkoutsPlanState {
  modalVisible: boolean;
  modalData: { [key: string]: any };
}

class WorkoutsPlan extends Component<IWorkoutsPlanProps, IWorkoutsPlanState> {
  constructor(props: IWorkoutsPlanProps) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {},
    };
  }

  componentDidMount() {
    this.props.getWorkouts();
    this.props.getWorkoutsPlan(this.props.match.params.workoutId);
  }

  get workouts() {
    const { list } = this.props.workouts;
    const { workoutId } = this.props.match.params;
    const workouts =
      list && list.length
        ? list.find((item: { [key: string]: any }) => item.id === +workoutId)
        : {};
    return workouts;
  }

  openModal = () => this.setState({ modalVisible: true });
  closeModal = () => this.setState({ modalVisible: false, modalData: {} });
  showMessageIfWorkoutHasNoTrainings = () =>
    message.warning(
      'Невозможно опубликовать курс, т.к. в нем отсутствуют тренировки',
    );
  isPublishOnChangeHandler = (
    data: { [key: string]: any },
    event: CheckboxChangeEvent,
  ) => {
    let isChecked = event.target.checked;
    const { workoutId } = this.props.match.params;
    const { editWorkoutsPlan } = this.props;
    const confirm = Modal.confirm;

    if (isChecked) {
      const that = this;
      confirm({
        title: 'Вы действительно хотите опубликовать курс?',
        okText: 'Опубликовать',
        cancelText: 'Отмена',
        onOk() {
          if (data.exerciseCount) {
            editWorkoutsPlan({ ...data, workoutId, isPublish: isChecked });
            return;
          }

          that.showMessageIfWorkoutHasNoTrainings();
        },
      });
      return;
    }

    editWorkoutsPlan({
      ...data,
      workoutId,
      isPublish: isChecked,
    });
  };

  onSubmit() {
    const { form } = this.props;
    form!.validateFields((err: any, values: any) => {
      const workoutPlanId = this.state.modalData.id;
      const currentWorkoutPlan = this.props.workouts.plans.find(
        (item: { [key: string]: any }) => item.id === workoutPlanId,
      );

      if (values.isPublish && workoutPlanId && !currentWorkoutPlan.exerciseCount) {
        this.showMessageIfWorkoutHasNoTrainings();
        return;
      }

      if (values.isPublish && !workoutPlanId) {
        this.showMessageIfWorkoutHasNoTrainings();
        return;
      }

      if (values.extraDescriptionsIds) {
        values.extra = [];
        values.extraDescriptionsIds.forEach((item: string) => {
          if (!values.extraDescriptionsToDelete.includes(item)) {
            const key = item.includes('new-') ? item.split('new-')[1] : item;
            const title = values.extraDescriptions[`title-${key}`];
            const description = values.extraDescriptions[`description-${key}`];
            const image = values.extraDescriptions[`image-${key}`];
            const title_eng = values.extraDescriptions[`title_eng-${key}`];
            const description_eng = values.extraDescriptions[`description_eng-${key}`];
            const image_eng = values.extraDescriptions[`image_eng-${key}`];
            values.extra.push({
              id: item,
              title,
              title_eng,
              description,
              description_eng,
              image,
              image_eng,
            });
          }
        });
      }
      if (values.extraDescriptionsToDelete) {
        values.extraToDelete = values.extraDescriptionsToDelete.filter(
          (key: string) => !key.includes('new'),
        );
      }
      delete values.extraDescriptionsToDelete;
      delete values.extraDescriptions;
      delete values.extraDescriptionsIds;

      if (err) {
        message.error('Правильно заполните поля во всех вкладках');
      }

      if (!err) {
        const { workoutId } = this.props.match.params;
        this.props.editWorkoutsPlan({
          ...values,
          workoutId,
          callback: this.closeModal,
        });
      }
    });
  }

  render() {

    const { loaders } = this.props.workouts;
    const { workoutId } = this.props.match.params;
    const tableData = this.props.workouts.plans ? this.props.workouts.plans : [];
    const WorkoutsTableColumns: ColumnProps<any>[] = [
      ...TableColumns,
      {
        title: 'Опубликован',
        dataIndex: 'isPublished',
        align: 'center',
        render: (_: string, data: { [key: string]: any }) => {
          return (
            <Checkbox
              checked={data.isPublish}
              disabled={loaders.editWorkoutsPlan}
              onChange={e => this.isPublishOnChangeHandler(data, e)}
            />
          );
        },
      },
      {
        title: (
          <Button
            className="action-button"
            icon="plus-circle"
            htmlType="button"
            title="Добавить план тренировки"
            onClick={this.openModal}
          />
        ),
        dataIndex: '',
        key: 'x',
        render: (_: string, data: { [key: string]: any }) => {
          const openEditModal = () => {
            this.setState({ modalData: data });
            this.setState({ modalVisible: true });
          };

          const deleteWorkoutsPlan = () =>
            this.props.deleteWorkoutsPlan({
              workoutId,
              workoutPlanId: data.id,
            });

          return (
            <ButtonGroup>
              <Button
                className="action-button"
                icon="read"
                htmlType="button"
                title="Просмотреть план тренировки"
                onClick={goTo.bind(null, 'workoutsWeeks', {
                  workoutId,
                  workoutsPlanId: `${data.id}`,
                })}
              />
              <Button
                className="action-button"
                icon="edit"
                htmlType="button"
                title="Редактировать план тренировки"
                onClick={openEditModal}
              />
              <Popconfirm
                title="Вы уверены, что хотите удалить план тренировок?"
                onConfirm={deleteWorkoutsPlan}
                placement="rightBottom"
              >
                <Button
                  className="action-button"
                  icon="delete"
                  htmlType="button"
                  title="Удалить план тренировки"
                  loading={loaders[`delete/${data.id}`]}
                />
              </Popconfirm>
            </ButtonGroup>
          );
        },
      },
    ];

    const modalTitle = this.state.modalData.id
      ? 'Редактирование плана'
      : 'Добавление плана';

    return (
      <ContentWrapper>
        <ExercisiesPageWrapper>
          <Title>
            <span onClick={goToPath.bind(null, routeMap.workouts.path)}>
              Категории тренировок
            </span>{' '}
            - {this.workouts.name}
          </Title>
          <Table
            rowKey="id"
            columns={WorkoutsTableColumns}
            loading={loaders.getWorkoutsPlan}
            dataSource={tableData}
          />
          <Modal
            title={modalTitle}
            destroyOnClose={true}
            maskClosable={false}
            visible={this.state.modalVisible}
            confirmLoading={loaders.editWorkoutsPlan}
            onCancel={this.closeModal}
            onOk={() => this.onSubmit()}
            width="600px"
          >
            <EditForm
              form={this.props.form}
              plans={tableData}
              data={this.state.modalData}
            />
          </Modal>
        </ExercisiesPageWrapper>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  workouts: {
    list: state.workouts.list,
    plans: state.workouts.plans,
    loaders: state.workouts.loaders,
  },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getWorkouts: () => dispatch(getWorkouts()),
  getWorkoutsPlan: (workoutId: number) => dispatch(getWorkoutsPlan(workoutId)),
  editWorkoutsPlan: (values: { [key: string]: any }) =>
    dispatch(editWorkoutsPlan(values)),
  deleteWorkoutsPlan: ({
    workoutId,
    workoutPlanId,
  }: {
    workoutId: number;
    workoutPlanId: number;
  }) => dispatch(deleteWorkoutsPlan({ workoutId, workoutPlanId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutsPlan);
