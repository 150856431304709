import { AuthActionTypes, AuthState, AuthToken } from './types';
import { Reducer } from 'redux';

const InitialState: AuthState = {
  data: undefined,
  token: undefined,
  tokenRefreshing: false,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<AuthState> = (state = InitialState, action) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_REQUEST: {
      return { ...state, loading: true, errors: undefined };
    }
    case AuthActionTypes.LOGIN_SUCCESS: {
      const { token, refresh_token } = action.payload as AuthToken;
      return {
        ...state,
        loading: false,
        data: token,
        token: { token, refresh_token },
      };
    }
    case AuthActionTypes.LOGIN_ERROR: {
      return { ...state, loading: false, errors: action.payload };
    }
    case AuthActionTypes.LOGOUT: {
      return { ...InitialState };
    }
    case AuthActionTypes.TOKEN_REFRESH_REQUEST: {
      return { ...state, tokenRefreshing: true };
    }
    case AuthActionTypes.TOKEN_REFRESH_SUCCESS:
    case AuthActionTypes.TOKEN_REFRESH_ERROR: {
      return { ...state, tokenRefreshing: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as authReducer };
